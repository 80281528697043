const injectColomProperties = (data) => {
  /**
   * to support old theme
   * we inject some news properties
   */
  if (data?.cover?.backgroundColor) {
    let tempBgColor = data?.cover?.backgroundColor.split(",");
    if (tempBgColor) {
      data.cover.opacity = tempBgColor[3]?.replace(/\D/g, "");
    } else {
      data.cover.opacity = 1;
    }
  }
  if (data.linear === undefined) {
    data.linear = "solid";
  }
  if (data.flex === undefined) {
    data.flex = 1;
  }
  if (data.width_ext === undefined) {
    data.width_ext = "%";
  }
  if (data.widthType === undefined) {
    data.widthType = "flex";
  }
  return data;
};

const enableFlexMode = (data, isResponsive) => {
  /**
   * enable flex mode if column has column inside
   */
  if (isResponsive && data?.content) {
    for (let a = 0; a < data?.content.length; a++) {
      if (data.content[a].type === "column") {
        return true;
      }
    }
  }
  return false;
};

const enablePositionMode = (data) => {
  /**
   * enable position mode when nested coloms >=4
   */
  let totalNested = data?.id.split("-");
  if (totalNested.length >= 4) {
    return true;
  }
  return false;
};

const injectFlex = (column, visualColom) => {
  /**
   * when no object widthType
   * set flex :1 to column
   */
  if (column?.widthType === undefined) {
    return {
      flex: 1,
    };
  } else {
    let key = column.widthType ? column.widthType : "flex";
    let value =
      column.widthType === "flex"
        ? column.flex
          ? `${visualColom(column, "flex", column.flex)}`
          : 1
        : column.width
        ? `${visualColom(column, "width", column.width)}px`
        : "100%";
    return {
      [key]: value,
    };
  }
};
export {
  injectColomProperties,
  enableFlexMode,
  enablePositionMode,
  injectFlex,
};
