import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LARGEDEVICE } from "../variable";
import { checkDevice } from "../helpers/windowHelper";
const ContactCSComp = () => {
  const device = checkDevice();
  let historyPush = useNavigate();

  const createNew = () => {
    historyPush("/editor-web");
  };

  return (
    <>
      <div style={{ position: "fixed", bottom: "50px", right: "10px" }}>
        <a
          href={`https://api.whatsapp.com/send?phone=6282139397342&text=Hallo%20kak%20saya%20perlu%20bantuan`}
        >
          <div
            style={{
              background: "#00A884",
              color: "#fff",
              padding: "12px 15px",
              borderRadius: "100px",
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: "25px" }} />
          </div>
        </a>
      </div>
      {device !== LARGEDEVICE && (
        <div
          style={{ position: "fixed", bottom: "50px", right: "70px" }}
          onClick={createNew}
        >
          <div
            style={{
              background: "#f3b773",
              color: "#fff",
              padding: "12px 15px",
              borderRadius: "100px",
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            <FontAwesomeIcon icon={faPlus} style={{ fontSize: "25px" }} />
          </div>
        </div>
      )}
    </>
  );
};

export default ContactCSComp;
