import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ColorPicker, useColor } from "react-color-palette";
import { TRANSCOLOR } from "../../variable";
const ColorComp = ({ label, colorCB, primaryColor, showNoColor, noColor }) => {
  let hasColor = true;
  if (primaryColor === TRANSCOLOR) {
    hasColor = false;
  }
  const delay = 1;
  let timer1;
  const [isTransparantClicked, setIsTransparantClicked] = useState(false);
  const [color, setColor] = useColor("hex", primaryColor ? primaryColor : "");
  const [defaultColor, setDefaultColor] = useColor(primaryColor);
  const [enableNoColor, setEnableNoColor] = useState(
    showNoColor ? showNoColor : false
  );
  const [enableColor, setEnableColor] = useState(false);
  const [isSupportDropper, setIsSupportDropper] = useState(
    !window.EyeDropper ? false : true
  );
  const [isReady, setIsReady] = useState(false);
  const showColor = (val) => {
    setEnableColor(val);
  };

  function hexToRgb(hex) {
    // Remove the leading '#' if it exists
    hex = hex.replace("#", "");

    // If the hex is 3 characters, expand it to 6 characters
    if (hex.length === 3) {
      hex = hex
        .split("")
        .map(function (char) {
          return char + char;
        })
        .join("");
    }

    // Parse the hex color into RGB values
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Return the RGB color in "rgb(r, g, b)" format
    return {
      r,
      g,
      b,
    };
  }

  function rgbToHsv(r, g, b) {
    // Normalize RGB values to the range 0-1
    r /= 255;
    g /= 255;
    b /= 255;
    // Find the minimum and maximum values of r, g, and b
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const delta = max - min;
    // Calculate Hue
    let h = 0;
    if (delta !== 0) {
      if (max === r) {
        h = ((g - b) / delta) % 6;
      } else if (max === g) {
        h = (b - r) / delta + 2;
      } else {
        h = (r - g) / delta + 4;
      }
    }
    // Calculate Saturation
    const s = max === 0 ? 0 : delta / max;
    // Calculate Value
    const v = max;
    // Convert Hue from [0, 6] scale to degrees [0, 360]
    h = Math.round(h * 60);
    if (h < 0) h += 360;
    // Convert Saturation and Value to percentage
    const sPercentage = Math.round(s * 100);
    const vPercentage = Math.round(v * 100);
    return {
      h: h,
      s: sPercentage,
      v: vPercentage,
    };
  }

  const changeColor = (value) => {
    setIsTransparantClicked(false);
    setColor(value);
    setDefaultColor(value.hex);
  };

  useEffect(() => {
    let desc = setTimeout(() => {
      if (!isTransparantClicked) {
        if (isReady) {
          colorCB(color);
        }
      }
    }, delay * 1000);
    return () => {
      clearTimeout(desc);
    };
  }, [defaultColor]);

  const changeNoColor = () => {
    setIsTransparantClicked(true);
    noColor(true);
  };

  const applyColor = (colorNow) => {
    let rgb = hexToRgb(colorNow);
    let hsv = rgbToHsv(rgb?.r, rgb?.g, rgb?.b);
    setColor({
      hex: colorNow,
      rgb: {
        r: rgb?.r,
        g: rgb?.g,
        b: rgb?.b,
      },
      hsv: {
        h: hsv?.h,
        s: hsv?.s,
        v: hsv?.v,
        a: 1,
      },
    });
  };

  useEffect(() => {
    if (primaryColor) {
      applyColor(primaryColor);
    }
  }, [primaryColor]);

  const openDropper = () => {
    if (!window.EyeDropper) {
      return;
    } else {
      setIsReady(true);
      const eyeDropper = new window.EyeDropper();
      eyeDropper
        .open()
        .then((result) => {
          if (result?.sRGBHex) {
            let rgb = hexToRgb(result?.sRGBHex);
            let value = {
              hex: result?.sRGBHex,
              rgb: {
                r: rgb?.r,
                g: rgb?.g,
                b: rgb?.b,
              },
            };

            changeColor(value);
          }
        })
        .catch((e) => {
          // resultElement.textContent = e;
        });
    }
  };
  return (
    <div>
      <div style={{ position: "relative" }}>
        <Form>
          <Form.Group>
            <Form.Label>
              <span style={{ fontSize: "13px" }}>{label}</span>
            </Form.Label>
            <Row>
              <Col>
                {hasColor ? (
                  <>
                    {defaultColor && (
                      <div
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: primaryColor,
                          float: "left",
                          marginRight: "15px",
                          borderRadius: 100,
                          marginTop: 5,
                          border: "solid 1px #fff",
                        }}
                      ></div>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <Button
                  variant="primary"
                  style={{
                    float: "left",
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                  onClick={() => {
                    setIsReady(true);
                    setTimeout(() => {
                      showColor(true);
                    }, 100);
                  }}
                >
                  {" "}
                  <i
                    className="fas fa-pen pt-2 pr-1"
                    style={{ fontSize: 10, color: "#fff" }}
                  ></i>
                  Ubah
                </Button>
                {enableColor && (
                  <div style={{ zIndex: "999999!" }}>
                    <div style={{ position: "absolute", zIndex: 999 }}>
                      <ColorPicker
                        width={180}
                        height={170}
                        color={color}
                        onChange={changeColor}
                        hideInput={["rgb", "hsv"]}
                        light
                      />
                      <div
                        style={{
                          backgroundColor: "#fff",
                          width: "25px",
                          height: "25px",
                          right: -25,
                          top: 0,
                          position: "absolute",
                          borderRadius: "100px",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => showColor(false)}
                      >
                        <i
                          className="fas fa-times pt-2"
                          style={{ fontSize: 10, color: "#333" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                )}
                {enableNoColor && (
                  <Button
                    variant="primary"
                    style={{
                      paddingTop: "2px",
                      paddingBottom: "2px",
                      paddingLeft: "10px",
                      paddingRight: "7px",
                      marginLeft: "10px",
                      borderRadius: 0,
                      background: "none",
                      border: "red 1px dashed",
                    }}
                    onClick={() => changeNoColor()}
                  >
                    {" "}
                    <i
                      className="fas fa-tint-slash pt-2 pr-1"
                      style={{ fontSize: 14, color: "#fff" }}
                    ></i>
                  </Button>
                )}
                {isSupportDropper && (
                  <div onClick={() => openDropper()} style={{ float: "left" }}>
                    <i
                      className="fas fa-eye-dropper pt-2"
                      style={{
                        fontSize: 14,
                        color: "#fff",
                        marginLeft: "18px",
                        marginTop: "2px",
                        cursor: "pointer",
                        marginRight: "7px",
                      }}
                    ></i>
                  </div>
                )}
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default ColorComp;
