import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import ResponsiveComp from "./ResponsiveComp";
import { Link, useNavigate } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { LARGEDEVICE, SMARTPHONE } from "../../variable";
import { useSelector } from "react-redux";
const NavbarSettingComp = ({
  isResponsive,
  domain,
  changeWidthDevice,
  landingpage,
  submit,
  undoCB,
  redoCB,
  isSaving,
  copyjson,
  theme,
  userAppId,
  device,
  widthDevice,
  changeCBResponsive,
  makePublic,
  isAbleSaveTheme,
}) => {
  const [responsiveMode, setResponsiveMode] = useState(isResponsive);
  const { user } = useSelector((state) => state);

  let historyPush = useNavigate();
  const changeMode = () => {
    let rMode = !responsiveMode;
    setResponsiveMode(rMode);
    changeCBResponsive(rMode);
  };

  const backHomePage = () => {
    historyPush("/");
  };

  useEffect(() => {
    setResponsiveMode(isResponsive);
  }, [isResponsive]);

  return (
    <div
      id="navbarsetting"
      style={{
        position: "fixed",
        width: "100%",
        left: 0,
        top: 0,
        height: "60px",
        background: `linear-gradient(to right, #23ADD3 , #23ADD3)`,
        zIndex: "9999",
        boxShadow: "1px 1px 2px #ccc",
        color: "#fff",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {device === LARGEDEVICE && (
          <>
            <div style={{ flex: 1 }}>
              <div style={{ float: "left" }} className="landingpage-title">
                <Link to="/">
                  <img
                    src="https://d1jcw7e5n1q2pt.cloudfront.net/1701568097543-invblogo.png"
                    width="90px"
                    style={{ marginTop: "10px", marginLeft: "10px" }}
                  />
                </Link>
              </div>
              <div style={{ float: "left" }} className="landingpage-title">
                <h4
                  style={{
                    marginTop: "15px",
                    paddingLeft: "10px",
                    color: "#fff",
                  }}
                >
                  {landingpage && landingpage.title ? landingpage.title : ""}
                </h4>
              </div>
            </div>
            <div style={{ width: "150px", marginTop: "15px" }}>
              {responsiveMode === true && (
                <ResponsiveComp
                  changeWidthDevice={changeWidthDevice}
                  widthDevice={widthDevice}
                ></ResponsiveComp>
              )}
            </div>
          </>
        )}

        <div style={{ flex: 1 }}>
          <div
            style={{ float: "right", paddingRight: "4%", paddingTop: "10px" }}
          >
            <div style={{ display: "flex", float: "right" }}>
              {device === LARGEDEVICE && (
                <>
                  <div style={{ flex: 2 }}>
                    <div style={{ marginTop: "10px" }}>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label={responsiveMode ? "Responsive" : "Mobile"}
                        value={responsiveMode}
                        checked={responsiveMode}
                        onChange={($e) => {
                          changeMode();
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              {device === SMARTPHONE && (
                <div style={{ flex: 1 }}>
                  <Button
                    style={{ background: "none", color: "#333" }}
                    onClick={() => backHomePage()}
                  >
                    <i
                      className="fa fa-arrow-left"
                      style={{ fontSize: 18, color: "#fff" }}
                    ></i>{" "}
                  </Button>
                </div>
              )}
              {/* {theme && theme.app_id === userAppId && <OverlayTrigger
                            key="bottom"
                            placement="bottom"
                            overlay={
                                <Tooltip id={`tooltip-bottom`}>
                                    Hapus Tema
                                </Tooltip>
                            }
                        >
                            <Button style={{ background: 'none', color: '#fff' }} onClick={() => copyjson("delete")}><i className='fa fa-trash ' style={{ fontSize: 18, color: '#fff' }}></i>  </Button>
                        </OverlayTrigger>} */}
              {isAbleSaveTheme && (
                <>
                  {theme && theme?._id !== "" && theme.app_id === userAppId ? (
                    <>
                      <div style={{ flex: 1 }}>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>Update Tema</Tooltip>
                          }
                        >
                          <Button
                            className="btn noborder"
                            style={{ background: "none", color: "#fff" }}
                            onClick={() => copyjson("save")}
                          >
                            <i
                              className="fa fa-palette "
                              style={{ fontSize: 18, color: "#fff" }}
                            ></i>{" "}
                          </Button>
                        </OverlayTrigger>
                      </div>
                      {theme.status === "private" &&
                        user?.app_id === "63cd0c7254920f655d6052fc" && (
                          <div style={{ flex: 1 }}>
                            {device === LARGEDEVICE && (
                              <div style={{ flex: 1 }}>
                                <Button
                                  className="btn noborder"
                                  style={{ background: "none", color: "#333" }}
                                  onClick={() => makePublic()}
                                >
                                  <i
                                    className="fa fa-globe"
                                    style={{ fontSize: 18, color: "#fff" }}
                                  ></i>{" "}
                                </Button>
                              </div>
                            )}
                          </div>
                        )}
                    </>
                  ) : (
                    <>
                      <div style={{ flex: 1 }}>
                        <OverlayTrigger
                          key="bottom"
                          placement="bottom"
                          overlay={
                            <Tooltip id={`tooltip-bottom`}>
                              Simpas sebagai tema
                            </Tooltip>
                          }
                        >
                          <Button
                            className="btn noborder"
                            style={{ background: "none", color: "#fff" }}
                            onClick={() => copyjson("create")}
                          >
                            <i
                              className="fa fa-palette "
                              style={{ fontSize: 18, color: "#fff" }}
                            ></i>{" "}
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </>
                  )}
                </>
              )}

              <div style={{ flex: 1 }}>
                <Button
                  className="btn noborder"
                  id="undo"
                  style={{
                    background: "none",
                    color: "#333",
                    border: "none !important",
                  }}
                  onClick={() => undoCB()}
                >
                  <i
                    className="fa fa-undo"
                    style={{ fontSize: 18, color: "#fff" }}
                  ></i>{" "}
                </Button>
              </div>
              <div style={{ flex: 1 }}>
                <Button
                  className="btn noborder"
                  id="redo"
                  style={{
                    background: "none",
                    color: "#333",
                    border: "none !important",
                  }}
                  onClick={() => redoCB()}
                >
                  <i
                    className="fa fa-redo "
                    style={{ fontSize: 18, color: "#fff" }}
                  ></i>{" "}
                </Button>
              </div>
              {landingpage.slug && (
                <div style={{ flex: 1 }}>
                  <Button
                    className="btn noborder"
                    style={{
                      background: "none",
                      color: "#333",
                      border: "none !important",
                    }}
                  >
                    <a
                      href={`https://${domain}/undangan/${landingpage.slug}`}
                      target="_blank"
                    >
                      {" "}
                      <i
                        className="fas fa-eye "
                        style={{ fontSize: 18, color: "#fff" }}
                      ></i>{" "}
                    </a>
                  </Button>
                </div>
              )}
              <div style={{ flex: 1 }}>
                <Button onClick={() => submit()}>
                  {isSaving ? "Menyimpan..." : "Simpan"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarSettingComp;
