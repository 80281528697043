import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import columns from "./datatable/columns";
import {
  Modal,
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavbarComp from "../../components/NavbarComp";
import {
  changeStatus,
  getUsers,
  referalService,
  subscription,
  updateUserPasswordService,
  updateUserService,
} from "../../services/userServices";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import moment from "moment";
import { LARGEDEVICE } from "../../variable";
import { checkDevice } from "../../helpers/windowHelper";
const UserPage = () => {
  let navigate = useNavigate();
  const loggedInUser = useSelector((state) => state?.user);
  console.log("loggedInUser", loggedInUser?.role);
  const [device, setDevice] = useState("");
  useEffect(() => {
    if (
      !loggedInUser?.app_id ||
      loggedInUser?.role !== "63cbe0b21b2e213c687d0b2d"
    ) {
      navigate("/");
    }
  }, [loggedInUser]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const delay = 1;
  let timer1;
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [referal, setReferal] = useState("");
  const [showAffileate, setShowAffiliate] = useState(false);
  const activeVBalue = ["monthly", "threemonths", "sixmonth", "yearly"];
  const [filter, setFilter] = useState([
    "email",
    "name",
    "whatsapp",
    "affiliate",
  ]);
  const [activeType, setActiveType] = useState("monthly");
  const [selectedFilter, setSelectedFilter] = useState("email");
  const [selectedUndangan, setSelectedUndangan] = useState("");
  const [selectedUndanganToRemove, setSelectedUndanganToRemove] = useState("");
  const dispatch = useDispatch();
  const [profile, setProfile] = useState();
  //   const configuration = useSelector((state) => state.configuration);
  //   const { setting } = configuration;
  //   const userLogin = useSelector((state) => state.userLogin);

  const [showModalShare, setShowModalShare] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalActivation, setShowModalActivation] = useState(false);
  const [showModalUser, setShowModalUser] = useState(false);
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showTheme, setShowTheme] = useState(false);
  const [landingpage, setLandingpage] = useState({});
  const [domain, setDomain] = useState();
  const [message, setMessage] = useState("");
  const [user, setUser] = useState({
    email: "",
    whatsapp: "",
  });
  let historyPush = useNavigate();
  const [breadcrumbData, setBreadCrumbData] = useState({
    root: "Home",
    root_link: "/",
    menu: "Undangan",
    menu_link: "/",
    current: "",
  });

  const [query, setQuery] = useState({
    filterBy: "name",
    type: "",
    status: "",
    tempo: "",
    key: "",
    page: 1,
    pageSize: 25,
  });
  const [filterBy, setFilterBy] = useState([
    {
      type: "filterBy",
      value: "name",
      text: "Nama Produk",
    },
  ]);

  const detailCB = async (data, action) => {
    switch (action) {
      case "delete":
        // alert("del")
        toggleModalDelete(true);
        setSelectedUndanganToRemove(data);

        break;
      case "status":
        await changeStatus(data);
        await fetchInvitations();
        break;
    }
  };
  const fetchInvitations = async () => {
    let start = "";
    let end = "";
    if (startDate) {
      start = moment(startDate).format("MM-DD-yyyy");
    }
    if (endDate) {
      end = moment(endDate).format("MM-DD-yyyy");
    }
    var q = `&filterBy=${query.filterBy}&keyword=${query.key}&status=${query.status}&type=${query.type}&startdate=${start}&enddate=${end}`;
    getUsers(query.pageSize, query.page, q)
      .then((res) => {
        setLandingpage(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    // fetchSetting()
    fetchInvitations();
  }, [query, startDate, endDate]);

  const submitFilterCB = (data) => {
    setQuery((prev) => {
      return {
        ...prev,
        ...data,
      };
    });
  };
  const showModalDialog = () => {
    setShowTheme(false);
    setShowModal(true);
  };
  const createNew = () => {
    historyPush.push("/plugins/visual/add");
  };
  const showThemeDialog = () => {
    setShowTheme(true);
  };
  const chooseTheme = (theme) => {
    historyPush.push(`/plugins/visual/add?theme=${theme}`);
    setShowModal(false);
  };

  const handleResize = () => {
    let toolbarHeight = 200;
    setViewportHeight(window.innerHeight - toolbarHeight);
  };

  useEffect(() => {
    // Set initial viewport height
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getProfile = () => {
    if (localStorage.getItem("userInfo")) {
      let data = JSON.parse(localStorage.getItem("userInfo"));

      setProfile(data);
    }
  };

  useEffect(() => {
    getProfile();
    setDevice(checkDevice());
  }, []);

  const toggleModalDelete = (value) => {
    setShowModalDelete(value);
  };

  const saveMessage = (value) => {
    setMessage(value);
  };
  const saveSetting = () => {
    setShowModal(false);
    localStorage.setItem("message", message);
  };

  useEffect(() => {
    if (localStorage.getItem("message")) {
      setMessage(localStorage.getItem("message"));
    }
  }, []);

  const actionCB = (key, row) => {
    if (key == "message") {
      let filteredValue = message.replace(/ /g, "%20");
      window.open(
        `https://api.whatsapp.com/send/?phone=${row.whatsapp}&text=${filteredValue}`
      );
    }
    if (key == "user") {
      setUser(row);
      setTimeout(() => {
        setShowModalUser(true);
      }, 100);
    }

    if (key == "activation") {
      setUser(row);
      setShowModalActivation(true);
    }

    if (key == "password") {
      setUser(row);
      setShowModalPassword(true);
    }
    if (key == "referal") {
      setUser(row);
      setReferal(row.affiliate);
      setTimeout(() => {
        setShowAffiliate(true);
      }, 100);
    }
  };

  const changeUser = (key, value) => {
    setUser((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const updateUser = async () => {
    let res = await updateUserService(
      user._id,
      {
        email: user.email,
        whatsapp: user.whatsapp,
      },
      true
    );
    if (res) {
      alert("berhasil");
    } else {
      alert("gagal");
    }
    setUser({});
  };

  const updatePassword = async () => {
    let res = await updateUserPasswordService(user._id, password);
    if (res) {
      alert("berhasil");
    } else {
      alert("gagal");
    }
    setUser({});
  };

  const changePassword = (value) => {
    setPassword(value);
  };

  const changeActiveData = (key, value) => {
    setActiveType(value);
  };

  const submitActive = async () => {
    console.log("user", user);
    let res = await subscription(user.app_id._id, {
      type: activeType,
    });
    if (res) {
      setShowModalActivation(false);
      setTimeout(() => {
        setUser({});
        fetchInvitations();
      }, 100);
    }
  };

  const submitReferal = async () => {
    let res = await referalService({
      id: user._id,
      referal: referal,
    });
    if (res) {
      setShowAffiliate(false);
      setTimeout(() => {
        setUser({});
        fetchInvitations();
      }, 100);
    }
  };

  const changeReferal = (value) => {
    setReferal(value);
  };
  const searchCB = (value) => {
    // setLoader(true);
    // setQuery((prev) => {
    //   return {
    //     ...prev,
    //     key: value,
    //   };
    // });
  };

  return (
    <div>
      <NavbarComp enableSearch={false} searchCB={searchCB}></NavbarComp>
      <Container className="mt-5 pt-4">
        <Row>
          <Col md={12}>
            <Modal
              show={showAffileate}
              onHide={() => {
                setUser();
                setShowAffiliate(false);
              }}
              style={{ width: "1000px !important" }}
            >
              <Modal.Header>
                <Modal.Title>Referal for {user?.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>referal</Form.Label>
                  <Form.Control
                    type="text"
                    value={referal}
                    onChange={($e) => changeReferal($e.target.value)}
                  />
                </Form.Group>
                <Button
                  variant="secondary"
                  onClick={() => {
                    submitReferal();
                  }}
                  className="mt-4"
                  style={{ background: "#FF3190", color: "#fff" }}
                >
                  Submit
                </Button>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setUser({});
                    setShowAffiliate(false);
                  }}
                >
                  Tutup
                </Button>
                {/* <Button variant="primary" >
                        Import
                    </Button> */}
              </Modal.Footer>
            </Modal>

            <Modal
              show={showModalActivation}
              onHide={() => {
                setUser();
                setShowModalActivation(false);
              }}
              style={{ width: "1000px !important" }}
            >
              <Modal.Header>
                <Modal.Title>Activation for {user?.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Control
                  as="select"
                  onChange={($e) => changeActiveData("type", $e.target.value)}
                >
                  {activeVBalue.map((active) => {
                    return (
                      <option value={active} selected={active == activeType}>
                        {active}
                      </option>
                    );
                  })}
                </Form.Control>
                <Button
                  variant="secondary"
                  onClick={() => {
                    submitActive();
                  }}
                  className="mt-4"
                  style={{ background: "#FF3190", color: "#fff" }}
                >
                  Submit
                </Button>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setUser();
                    setShowModalActivation(false);
                  }}
                >
                  Tutup
                </Button>
                {/* <Button variant="primary" >
                        Import
                    </Button> */}
              </Modal.Footer>
            </Modal>

            <Modal
              show={showModalPassword}
              onHide={() => {
                setUser();
                setShowModalPassword(false);
              }}
              style={{ width: "1000px !important" }}
            >
              <Modal.Header>
                <Modal.Title>Change Password</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="text"
                    value={password}
                    onChange={($e) => changePassword($e.target.value)}
                  />
                </Form.Group>

                <Button onClick={() => updatePassword()}>Update</Button>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setUser();
                    setShowModalPassword(false);
                  }}
                >
                  Tutup
                </Button>
                {/* <Button variant="primary" >
                        Import
                    </Button> */}
              </Modal.Footer>
            </Modal>

            <Modal
              show={showModalUser}
              onHide={() => {
                setUser({});
                setShowModalUser(false);
              }}
              style={{ width: "1000px !important" }}
            >
              <Modal.Header>
                <Modal.Title>Message</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    value={user?.email}
                    onChange={($e) => changeUser("email", $e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Whatsapp</Form.Label>
                  <Form.Control
                    type="text"
                    value={user?.whatsapp}
                    onChange={($e) => changeUser("whatsapp", $e.target.value)}
                  />
                </Form.Group>
                <Button onClick={() => updateUser()}>Update</Button>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowModalUser(false);
                    setUser({});
                  }}
                >
                  Tutup
                </Button>
                {/* <Button variant="primary" >
                        Import
                    </Button> */}
              </Modal.Footer>
            </Modal>

            <Modal
              show={showModal}
              onHide={() => setShowModal(false)}
              style={{ width: "1000px !important" }}
            >
              <Modal.Header>
                <Modal.Title>Change User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={message}
                    onChange={($e) => saveMessage($e.target.value)}
                  />
                </Form.Group>

                <Button onClick={() => saveSetting()}>Save</Button>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Tutup
                </Button>
                {/* <Button variant="primary" >
                        Import
                    </Button> */}
              </Modal.Footer>
            </Modal>
            {/*            
            <FilterComp
              filterBy={filterBy}
              submitFilter={submitFilterCB}
              eventClick={showModalDialog}
            ></FilterComp> */}

            <div className="">
              <div className=" pt-1" style={{ background: "#fff" }}>
                <div style={{ border: "solid 1px #ccc", marginBottom: "20px" }}>
                  <InputGroup style={{ border: "solidx 1px #000" }}>
                    <DropdownButton
                      variant="outline-secondary"
                      title={query.filterBy}
                    >
                      {filter.map((f) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              setQuery((prev) => {
                                return {
                                  ...prev,
                                  filterBy: f,
                                };
                              });
                            }}
                          >
                            {f}
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                    <Form.Control
                      onChange={($e) => {
                        let desc = setTimeout(() => {
                          setQuery((prev) => {
                            return {
                              ...prev,
                              key: $e.target.value,
                            };
                          });
                        }, delay * 1000);
                        return () => {
                          clearTimeout(desc);
                        };
                      }}
                    />
                  </InputGroup>
                </div>
                <label>Start Date</label>
                <DatePicker onChange={setStartDate} value={startDate} />
                <label>End Date</label>
                <DatePicker onChange={setEndDate} value={endDate} />
                <div
                  style={{
                    height:
                      device === LARGEDEVICE ? "auto" : viewportHeight + "px",
                    marginTop: device === LARGEDEVICE ? "70px" : "0px",
                  }}
                >
                  <DataTable
                    title="Daftar Pengguna"
                    columns={columns(landingpage, detailCB, query, actionCB)}
                    data={landingpage.users}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={landingpage.total}
                    paginationPerPage={query.pageSize}
                    paginationComponentOptions={{
                      noRowsPerPage: false,
                    }}
                    paginationRowsPerPageOptions={[25, 50, 100, 125, 150]}
                    onChangePage={(page) =>
                      setQuery((prev) => {
                        return {
                          ...prev,
                          page,
                        };
                      })
                    }
                    onChangeRowsPerPage={(pageSize) =>
                      setQuery((prev) => {
                        return {
                          ...prev,
                          page: 1,
                          pageSize,
                        };
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserPage;
