import React from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import LandingpageColom from "./LandingpageColom";
import ColMenu from "../ColMenu";
import RowMenu from "../RowMenu";
import { getColId, saveColId } from "../../../helpers/storageHelper";
import {
  MEDIALARGE,
  MEDIAMEDIUM,
  MEDIASMALL,
  TRANSCOLOR,
  TRANSCOLORRGBA,
} from "../../../variable";
import mediaQuery from "../../../helpers/mediaQueryHelper";
import CustomColMenu from "../CustomColMenu";
import BuilderColom from "./BuilderColom";

const LandingpageRow = ({
  viewState,
  row,
  clickSetting,
  clickSettingCol,
  colomCBParent,
  colomRemoveCBParent,
  removeRowParent,
  provided,
  snapshot,
  widthDev,
  pushRow,
  index,
  newContent,
  changeColCB,
  changeColomOrderCB,
  windowWidth,
  openElement,
}) => {
  const settingRow = () => {
    clickSetting(row);
  };

  const settingCol = (action, id) => {
    row.id = id;
    row.iscustom = false;
    clickSettingCol(action, id, false, row);
  };

  const settingColInCustom = (action, id) => {
    row.iscustom = true;
    row.id = id;
    clickSettingCol(action, id, true);
  };
  const duplicateRow = () => {
    pushRow(row);
  };
  const removeRow = () => {
    removeRowParent(row);
  };

  const colomCB = (colom) => {
    colomCBParent(colom);
  };

  const colomRemoveCB = (colom) => {
    colom.index_row = row.index;
    colomRemoveCBParent(colom);
  };

  const changeStyle = (e) => {
    if (newContent.status === true) {
      e.target.style.border = "dotted 1px #333";
    }
  };
  const changeBackStyle = (e) => {
    if (newContent.status === true) {
      e.target.style.border = "dotted 0px #333";
    }
  };

  const showColMenu = async (id) => {
    let idSlideCol = `slide-col-${id}`;
    let colid = await getColId();
    if (colid !== idSlideCol) {
      hideColMenu();
    }
    setTimeout(() => {
      if (document.getElementById(idSlideCol)) {
        document.getElementById(idSlideCol).style.visibility = "visible";
        if (document.getElementById(id)) {
          document.getElementById(id).style.border = "dashed 1px #23ADD3";
        }
        saveColId(idSlideCol);
      }
    }, 100);
  };

  const hideColMenu = async () => {
    if (document.getElementsByClassName("slide-col")) {
      for (
        let a = 0;
        a < document.getElementsByClassName("slide-col").length;
        a++
      ) {
        document.getElementsByClassName("slide-col")[a].style.visibility =
          "hidden";
        for (let b = 0; b < 6; b++) {
          if (document.getElementById(`${a}-${b}`)) {
            document.getElementById(`${a}-${b}`).style.border =
              "dashed 0px #fff";
          }
        }
      }
    }
  };

  const CustomCol = ({ row, indexRowColRow, colomIndexs, a }) => {
    return (
      <div
        style={{
          [row.widthType === "flex" ? "flex" : "width"]:
            row.widthType === "flex"
              ? row.flex != undefined
                ? visualColom(row, "flex", row.flex)
                : 1
              : `${visualColom(row, "width", row.width)}px`,
        }}
      >
        <BuilderColom
          row={row}
          colomIndexs={colomIndexs}
          c={row}
          widthDev={widthDev}
          windowWidth={windowWidth}
        >
          <Draggable
            key={colomIndexs}
            draggableId={"colom-" + indexRowColRow}
            index={indexRowColRow}
          >
            {(providedCustomMenu, snapshot) => (
              <div
                id={`${row.index}-${colomIndexs}`}
                ref={providedCustomMenu.innerRef}
                {...providedCustomMenu.draggableProps}
                stye={{
                  ...providedCustomMenu.draggableProps.style,
                  boxShadow: snapshot.isDragging ? "0 0 .4 rem #666" : "none",
                }}
              >
                <DragDropContext
                  onDragEnd={(param) => {
                    const srcI = param.source.index;
                    const desI = param.destination?.index;
                    if (desI !== undefined) {
                      changeColCB(
                        {
                          row: index,
                          col: colomIndexs,
                          rowColRow: a,
                          rowColRowCol: indexRowColRow,
                        },
                        srcI,
                        desI
                      );
                    }
                  }}
                >
                  <Droppable droppableId="customcontent" type="customcontent">
                    {(provided, _) => (
                      <>
                        <div
                          id={`${index}-${colomIndexs}-${a}-${indexRowColRow}`}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <div style={{ position: "relative" }}>
                            <CustomColMenu
                              id={`${index}-${colomIndexs}-${a}-${indexRowColRow}`}
                              settingCol={settingColInCustom}
                              indexRowColRow={indexRowColRow}
                              provided={providedCustomMenu}
                            ></CustomColMenu>

                            {row?.content.length < 1 && (
                              <>
                                <div
                                  id={`${index}-${colomIndexs}-${a}-${indexRowColRow}-0`}
                                  className="text-center"
                                  style={{
                                    border: "dashed 3px #ccc",
                                    width: "90%",
                                    height: "auto",
                                    padding: "30px 0px",
                                    color: "#ccc",
                                    fontWeight: "bold",
                                  }}
                                  onClick={() => openElement()}
                                >
                                  Drop disini
                                </div>
                              </>
                            )}

                            {row?.content.map((contentData, indexCol) => {
                              contentData.customIndexCol = index;
                              contentData.customIndexContent = indexCol;
                              return (
                                <>
                                  <Draggable
                                    key={indexCol}
                                    draggableId={"customcontent" + indexCol}
                                    index={indexCol}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        style={{ touchAction: "none" }}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        id={`${index}-${colomIndexs}`}
                                      >
                                        <LandingpageColom
                                          idview={`${index}-${colomIndexs}-${a}-${indexRowColRow}-${indexCol}`}
                                          viewState={viewState}
                                          rowIndex={`${index}`}
                                          colom={contentData}
                                          colomCB={colomCB}
                                          colomRemoveCB={colomRemoveCB}
                                          widthDev={widthDev}
                                          showSlide={showSlideMenu}
                                          showColMenu={showColMenu}
                                        ></LandingpageColom>
                                      </div>
                                    )}
                                  </Draggable>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            )}
          </Draggable>
        </BuilderColom>
      </div>
    );
  };
  const CustomRow = ({ colomIndexs, a, con, colom }) => {
    return (
      <div>
        <DragDropContext
          onDragEnd={(param) => {
            const srcI = param.source.index;
            const desI = param.destination?.index;

            changeColomOrderCB(
              {
                row: index,
                colom: colomIndexs,
                rowColRow: a,
              },
              srcI,
              desI
            );
          }}
        >
          <Droppable droppableId="colom" type="colom" direction="horizontal">
            {(providedCustomMenu, _) => (
              <div
                ref={providedCustomMenu.innerRef}
                {...providedCustomMenu.droppableProps}
              >
                <div
                  id={`${index}-${colomIndexs}-${a}`}
                  style={{
                    border: "dashed 1px blue",
                    overflow: "auto",
                    display: "flex",
                    flexDirection: mediaQuery(
                      row.col[0],
                      "flexDirection",
                      row.col[0]?.flexDirection,
                      widthDev
                    ),
                  }}
                >
                  {con.content &&
                    con.content.map((row, indexRowColRow) => {
                      return (
                        <>
                          <CustomCol
                            row={row}
                            indexRowColRow={indexRowColRow}
                            colomIndexs={colomIndexs}
                            a={a}
                          ></CustomCol>
                        </>
                      );
                    })}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };

  const RowChildren = ({ row }) => {
    return (
      <div
        id={`${row.index}`}
        style={{
          height: mediaQuery(row, "height", row.height, widthDev),
          marginTop: mediaQuery(row, "marginTop", row.marginTop, widthDev),
          marginRight: mediaQuery(
            row,
            "marginRight",
            row.marginRight,
            widthDev
          ),
          marginBottom: mediaQuery(
            row,
            "marginBottom",
            row.marginBottom,
            widthDev
          ),
          marginLeft: mediaQuery(row, "height", row.marginLeft, widthDev),
          paddingTop: mediaQuery(row, "paddingTop", row.paddingTop, widthDev),
          paddingRight: mediaQuery(
            row,
            "paddingRight",
            row.paddingRight,
            widthDev
          ),
          paddingBottom: mediaQuery(
            row,
            "paddingBottom",
            row.paddingBottom,
            widthDev
          ),
          paddingLeft: mediaQuery(
            row,
            "paddingLeft",
            row.paddingLeft,
            widthDev
          ),
          background:
            row.linear === "solid"
              ? row?.cover?.backgroundColor !== TRANSCOLOR
                ? row?.cover?.backgroundColor
                : "unset"
              : `linear-gradient(to bottom, ${
                  row?.cover?.backgroundColor !== TRANSCOLOR
                    ? row?.cover?.backgroundColor
                    : TRANSCOLORRGBA
                } ,${
                  row?.cover2?.backgroundColor !== TRANSCOLOR
                    ? row?.cover2?.backgroundColor
                    : TRANSCOLORRGBA
                })`,
        }}
      >
        <div
          id={`${row.index}`}
          className={
            row.container === "container"
              ? "container"
              : row.container === "container-fluid"
              ? "container-fluid"
              : ""
          }
        >
          <div
            id={`${row.index}`}
            onMouseOver={(e) => changeStyle(e)}
            onMouseOut={(e) => changeBackStyle(e)}
          >
            <div
              id={`${row.index}`}
              style={{
                display: "flex",
                flexDirection: mediaQuery(
                  row,
                  "flexDirection",
                  row.flexDirection,
                  widthDev
                ),
              }}
            >
              {row?.col.map((c, colomIndexs) => {
                return (
                  <>
                    <BuilderColom
                      row={row}
                      colomIndexs={colomIndexs}
                      c={c}
                      widthDev={widthDev}
                      windowWidth={windowWidth}
                    >
                      <Draggable
                        key={colomIndexs}
                        draggableId={"row" + colomIndexs}
                        index={colomIndexs}
                      >
                        {(provided, snapshot) => (
                          <div
                            id={`${row.index}-${index}`}
                            ref={provided.innerRef}
                            draggable={true}
                            {...provided.draggableProps}
                            className={`flex-`}
                            style={{ touchAction: "none" }}
                            onMouseEnter={() => {
                              showColMenu(`${row.index}-${colomIndexs}`);
                              showSlideMenu(row.index);
                            }}
                          >
                            <Draggable
                              key={colomIndexs}
                              draggableId={"colom-" + colomIndexs}
                              index={colomIndexs}
                            >
                              {(providedCol, snapshot) => (
                                <div
                                  id={`${row.index}-${colomIndexs}`}
                                  ref={providedCol.innerRef}
                                  {...providedCol.draggableProps}
                                  stye={{
                                    ...providedCol.draggableProps.style,
                                    boxShadow: snapshot.isDragging
                                      ? "0 0 .4 rem #666"
                                      : "none",
                                  }}
                                >
                                  {c.content.length < 1 && (
                                    <div
                                      id={`${row.index}-${colomIndexs}`}
                                      className="text-center"
                                      style={{
                                        border: "dashed 3px #ccc",
                                        width: "90%",
                                        height: "auto",
                                        padding: "30px 0px",
                                        color: "#ccc",
                                        fontWeight: "bold",
                                      }}
                                      onClick={() => openElement()}
                                    >
                                      Drop disini
                                    </div>
                                  )}
                                  <ColMenu
                                    id={`${row.index}-${colomIndexs}`}
                                    provided={providedCol}
                                    settingCol={settingCol}
                                    selectedColomIndex={colomIndexs}
                                  ></ColMenu>
                                  <div>
                                    <DragDropContext
                                      onDragEnd={(param) => {
                                        const srcI = param.source.index;
                                        const desI = param.destination?.index;

                                        if (desI !== undefined) {
                                          changeColCB(
                                            {
                                              row: row.index,
                                              col: colomIndexs,
                                            },
                                            srcI,
                                            desI
                                          );
                                        }
                                      }}
                                    >
                                      <Droppable
                                        droppableId="dropablecontent"
                                        direction="vertical"
                                        type="content"
                                        style={{ height: "auto" }}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            id={`${row.index}-${colomIndexs}`}
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                          >
                                            {c?.content.map((con, a) => {
                                              return (
                                                <>
                                                  <Draggable
                                                    key={a}
                                                    draggableId={"content" + a}
                                                    index={a}
                                                  >
                                                    {(provided, snapshot) => (
                                                      <div
                                                        ref={provided.innerRef}
                                                        style={{
                                                          touchAction: "none",
                                                        }}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        id={`${index}-${colomIndexs}`}
                                                      >
                                                        {con?.type ===
                                                        viewState.viewCol ? (
                                                          <>
                                                            <CustomRow
                                                              colomIndexs={
                                                                colomIndexs
                                                              }
                                                              a={a}
                                                              con={con}
                                                              colom={c}
                                                            ></CustomRow>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <LandingpageColom
                                                              idview={`${row.index}-${colomIndexs}-${a}`}
                                                              viewState={
                                                                viewState
                                                              }
                                                              rowIndex={`${row.index}`}
                                                              colom={con}
                                                              colomCB={colomCB}
                                                              colomRemoveCB={
                                                                colomRemoveCB
                                                              }
                                                              widthDev={
                                                                widthDev
                                                              }
                                                              showSlide={
                                                                showSlideMenu
                                                              }
                                                              showColMenu={
                                                                showColMenu
                                                              }
                                                            ></LandingpageColom>
                                                          </>
                                                        )}
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                </>
                                              );
                                            })}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          </div>
                        )}
                      </Draggable>
                    </BuilderColom>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showSlideMenu = async (index) => {
    let slideId = `slide-${index}`;
    if (document.getElementById(slideId)) {
      await hideSlideMenu();
      // await hideColMenu()
      document.getElementById(slideId).style.visibility = "visible";
      if (document.getElementById(index)) {
        document.getElementById(index).style.border = "dashed 1px red";
      }
    }
  };

  const hideSlideMenu = async () => {
    if (document.getElementsByClassName("slide-row")) {
      for (
        let a = 0;
        a < document.getElementsByClassName("slide-row").length;
        a++
      ) {
        document.getElementsByClassName("slide-row")[a].style.visibility =
          "hidden";
        if (document.getElementById(a)) {
          document.getElementById(a).style.border = "dashed 0px red";
        }
      }
    }
  };

  const RowSection = ({ row, style }) => {
    if (row.backgroundType === undefined) {
      row.backgroundType = "color";
    }
    switch (row.backgroundType) {
      case "color":
        return (
          <div
            id={`${row.index}`}
            style={{
              ...style,
              borderRadius: row.borderRadius,
              position: "relative",
              height: row.height,
              background:
                row.linear === "solid"
                  ? row?.backgroundColor !== TRANSCOLOR
                    ? row?.backgroundColor
                    : "unset"
                  : `linear-gradient(to bottom, ${
                      row?.backgroundColor !== TRANSCOLOR
                        ? row?.backgroundColor
                        : TRANSCOLORRGBA
                    } ,${
                      row?.backgroundColor2 !== TRANSCOLOR
                        ? row?.backgroundColor2
                        : TRANSCOLORRGBA
                    })`,
              overflow: row.overflow,
              width: row.position === "fixed" ? row.width : "100%",
            }}
            className={
              widthDev === "100%"
                ? "flexrow"
                : widthDev === MEDIAMEDIUM
                ? "flexrow-768"
                : "flexrow-360"
            }
            onMouseLeave={() => {
              // hideSlideMenu(row.index)
            }}
          >
            <div style={{ overflow: "hidden" }}>
              <div
                style={{
                  width: "100%",
                  height: "30px",
                  position: "absolute",
                }}
                onMouseEnter={() => {
                  showSlideMenu(row.index);
                }}
              ></div>
            </div>
            <RowMenu
              row={row}
              provided={provided}
              settingRow={settingRow}
              duplicateRow={duplicateRow}
              removeRow={removeRow}
              showDragger={row.position === "fixed" ? true : true}
              widthDev={widthDev}
            ></RowMenu>
            <RowChildren row={row}></RowChildren>
          </div>
        );
      case "image":
        return (
          <div
            id={`${row.index}`}
            style={{
              ...style,
              overflow: row.overflow,
              position: "relative",
              height: row.height,
              background: `url(${row.backgroundImage})`,
              backgroundSize: row.backgroundSize,
              backgroundPosition:
                windowWidth > 450 ? "center center" : "center center",
              width: row.position === "fixed" ? row.width : "100%",
            }}
            className={
              widthDev === "100%"
                ? "flexrow"
                : widthDev === MEDIAMEDIUM
                ? "flexrow-768"
                : "flexrow-360"
            }
            onMouseLeave={() => {
              // hideSlideMenu(row.index)
            }}
          >
            <div style={{ overflow: "hidden" }}>
              <div
                style={{
                  width: "100%",
                  height: "30px",
                  position: "absolute",
                }}
                onMouseEnter={() => {
                  showSlideMenu(row.index);
                }}
              ></div>
            </div>
            <RowMenu
              row={row}
              provided={provided}
              settingRow={settingRow}
              duplicateRow={duplicateRow}
              removeRow={removeRow}
              showDragger={row.position === "fixed" ? true : true}
              widthDev={widthDev}
            ></RowMenu>
            <RowChildren row={row}></RowChildren>
          </div>
        );
      case "slide":
        return (
          <div
            id={`${row.index}`}
            style={{
              ...style,
              overflow: row.overflow,
              borderRadius: row.borderRadius,
              position: "relative",
              height: row.height,
              background: row?.image_slide
                ? `url(${row.image_slide[0].img})`
                : "",
              backgroundSize: "cover",
              backgroundPosition:
                windowWidth > 450 ? "center center" : "center center",
              width: row.position === "fixed" ? row.width : "100%",
            }}
            className={
              widthDev === "100%"
                ? "flexrow"
                : widthDev === MEDIAMEDIUM
                ? "flexrow-768"
                : "flexrow-360"
            }
            onMouseLeave={() => {
              // hideSlideMenu(row.index)
            }}
          >
            <div style={{ overflow: "hidden" }}>
              <div
                style={{
                  width: "100%",
                  height: "30px",
                  position: "absolute",
                }}
                onMouseEnter={() => {
                  showSlideMenu(row.index);
                }}
              ></div>
            </div>
            <RowMenu
              row={row}
              provided={provided}
              settingRow={settingRow}
              duplicateRow={duplicateRow}
              removeRow={removeRow}
              showDragger={row.position === "fixed" ? true : true}
              widthDev={widthDev}
            ></RowMenu>
            <RowChildren row={row}></RowChildren>
          </div>
        );
    }
  };

  const visualColom = (row, key, value) => {
    switch (widthDev) {
      case MEDIALARGE:
        return value;
      case MEDIAMEDIUM:
        if (row.mediaQueri768 && row.mediaQueri768[key]) {
          return row.mediaQueri768[key];
        } else {
          return value;
        }
      case MEDIASMALL:
        if (row.mediaQueri360 && row.mediaQueri360[key]) {
          return row.mediaQueri360[key];
        } else {
          if (row.mediaQueri768 && row.mediaQueri768[key]) {
            return row.mediaQueri768[key];
          } else {
            return value;
          }
        }
    }
  };

  return (
    <>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        stye={{
          ...provided.draggableProps.style,
          boxShadow: snapshot.isDragging ? "0 0 .4 rem #666" : "none",
        }}
      >
        <DragDropContext
          onDragEnd={(param) => {
            const srcI = param.source.index;
            const desI = param.destination?.index;
            changeColomOrderCB(
              {
                row: index,
              },
              srcI,
              desI
            );
          }}
        >
          <Droppable droppableId="colom" type="colom" direction="horizontal">
            {(provided, _) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <RowSection
                  row={row}
                  style={
                    row.position === "fixed" && {
                      width:
                        row.position === "fixed" ? `${row.width}%` : "100%",
                      margin: "0 auto",
                    }
                  }
                ></RowSection>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};

export default LandingpageRow;
