import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { SMARTPHONE } from "../../variable";
import LoaderComp from "../LoaderComp";
import { upload } from "../../services/uploadServices";
import InputUploadComp from "../form/Input/InputUploadComp";
import uploadHelper from "../../helpers/uploadHelper";
import convertToWebP from "../../helpers/webPHelper";
const SettingComp = ({
  slug,
  landingpage,
  changeLandingpageTitle,
  submit,
  domain,
  device,
  thumbnailFormData,
}) => {
  const delay = 1;
  let timer1;

  const description = `Assalamualaikum warahmatullahi wabarakatuh.

Yth.  #to
    
Tanpa mengurangi rasa hormat, perkenankan kami mengundang Bapak/Ibu/Saudara/i, teman sekaligus sahabat, untuk menghadiri acara pernikahan kami :
    
(Nama Pengantin Pria) dan (Nama Pengantin Wanita)
    
Berikut link undangan kami, untuk info selengkapnya dari acara silahkan kunjungi :
    
https://${domain}/undangan/#link
    
Terukir kesan yang dalam dihati kami apabila Bapak/Ibu/Saudara/i berkenan hadir untuk mengiringi, dan memberikan doa restu kepada kami dalam menapaki lembaran baru perjalanan hidup.
    
Wassalamu'alaikum Wr. Wb.`;
  const [currentDescKey, setCurrentDescKey] = useState(
    landingpage.meta_desc?.trim() == "" ? "/#link" : `/${landingpage.title}`
  );
  const [isUploading, setIsUploading] = useState(false);
  const [data, setData] = useState({
    title: landingpage.title,
    meta_title: landingpage.meta_title,
    meta_desc:
      landingpage.meta_desc?.trim() == "" ? description : landingpage.meta_desc,
    meta_image: landingpage.meta_image,
    layout:
      landingpage.layout != !undefined ? landingpage.layout : "responsive",
    isQuery: landingpage.isQuery != !undefined ? landingpage.isQuery : false,
  });

  useEffect(() => {
    if (thumbnailFormData) {
      upload(thumbnailFormData)
        .then((res) => {
          setIsUploading(false);
          changeData("meta_image", res.data.files[0]);
        })
        .catch((err) => {
          setIsUploading(false);
          alert("gagal upload thumbnail");
          console.log("err", err);
        });
    }
  }, [thumbnailFormData]);

  const changeData = (key, value) => {
    if (key == "title") {
      value = value.replace(/ /g, "-");
      value = value.replace("--", "-"); //ignore -- not works on iphone makes error
      value = value.replace("&", "dan"); //ignore -- not works on iphone makes error
      value = value.replace("undangan", ""); //ignore -- undangan itu udah kepake sebagai slug
    }
    if (key == "meta_desc") {
      value = value.replace(/&/g, "dan");
    }

    setData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const copyLink = () => {
    navigator.clipboard.writeText(`https://${domain}/undangan/${data.title}`);
  };

  useEffect(
    () => {
      let desc = setTimeout(() => {
        changeLandingpageTitle(data);
      }, delay * 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(desc);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    [data]
  );

  const uploadSelectedFile = async (event, webPImage) => {
    setIsUploading(true);
    let result = await uploadHelper(event, 1);
    if (result) {
      setData((prev) => {
        return {
          ...prev,
          meta_image: result[0].widthImage,
          meta_image_webp: webPImage,
        };
      });
    }
    setTimeout(() => {
      setIsUploading(false);
    }, 200);
  };

  useEffect(() => {
    clearTimeout(timer1);
    timer1 = setTimeout(() => {
      if (data.title.trim() != "") {
        if (data.meta_desc && data.meta_desc.includes(currentDescKey)) {
          let newDesc = data.meta_desc.replace(
            currentDescKey,
            `/${data.title}`
          );
          setData((prev) => {
            return {
              ...prev,
              meta_desc: newDesc,
            };
          });
          if (data.title.trim() == "") {
            setCurrentDescKey("/#link");
          } else {
            setCurrentDescKey(`/${data.title}`);
          }
        }
      }
    }, delay * 1000);
  }, [data.title]);

  const webProcess = async ($e) => {
    try {
      let webP = await convertToWebP($e.target.files);
      await uploadSelectedFile($e, webP[0]);
    } catch (err) {
      alert("gagal");
    }
  };

  return (
    <div style={{ padding: "10px" }}>
      {!landingpage._id && (
        <Form.Group controlId="name">
          <Form.Label>
            <span style={{ color: "red" }}>*</span>URL
          </Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            value={data.title}
            onChange={($e) => {
              changeData("title", $e.target.value);
            }}
            className="form-control-dark"
          ></Form.Control>
          <div>
            <small>contoh fulan dan fulana</small>
            <br></br>
          </div>
        </Form.Group>
      )}

      <small>
        URL: https://{domain}/undangan/{data.title}
      </small>
      <i
        className="fa fa-copy"
        style={{ marginLeft: "10px", marginRight: "10px", cursor: "pointer" }}
        onClick={copyLink}
      ></i>

      <Form.Group controlId="name">
        <Form.Label>
          <span style={{ color: "red" }}></span>Judul
        </Form.Label>
        <Form.Control
          type="text"
          placeholder=""
          value={data.meta_title}
          onChange={($e) => {
            changeData("meta_title", $e.target.value);
          }}
          className="form-control-dark"
        ></Form.Control>
      </Form.Group>
      <Form.Group controlId="name">
        <Form.Label>
          <span style={{ color: "red" }}></span>Deskripsi
        </Form.Label>
        <Form.Control
          as="textarea"
          rows="6"
          placeholder=""
          value={data.meta_desc}
          onChange={($e) => {
            changeData("meta_desc", $e.target.value);
          }}
          className="form-control-dark"
        ></Form.Control>
      </Form.Group>
      <InputUploadComp
        name="input2[]"
        label="Thumbnail"
        onChange={($e) => {
          webProcess($e);
        }}
      />
      {data.meta_image && (
        <img src={data.meta_image} className="img-fluid thumbnail" />
      )}
      <div>
        <i>support jpg/png dibawah 1mb</i>
      </div>
      {isUploading && <LoaderComp></LoaderComp>}

      <Button variant="primary mt-4 mb-4" onClick={() => submit()}>
        {slug ? "Ubah" : "Tambahkan"}
      </Button>
      {device == SMARTPHONE && <div style={{ marginBottom: "100px" }}></div>}
    </div>
  );
};

export default SettingComp;
