import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
const InputTextComp = ({
  label,
  type,
  placeholder,
  value,
  onChangeCB,
  isRequired,
  autofocus,
  onClickCB,
  onBlurCB,
  name,
  className,
}) => {
  let inputData = {
    name: name,
  };
  const requiredHave = isRequired !== undefined ? isRequired : false;
  const [inputFocus, setInputFocus] = useState(autofocus);
  const debounceTimeout = useRef(null);
  const [realValue, setRealValue] = useState(value);
  const startSearch = useRef(false);
  const search = (searchTerm) => {
    onChangeCB(searchTerm);
  };

  // Debounced search function with 500ms delay
  const debouncedSearch = (searchTerm) => {
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(() => {
      search(searchTerm);
      setInputFocus(true); // Set input focus after debounce
    }, 500);
  };

  const changeRowName = (event) => {
    setRealValue(event);
    startSearch.current = true;
    const searchTerm = event;
    debouncedSearch(searchTerm);
  };

  const clickInputFunc = () => {
    if (onClickCB !== undefined) {
      onClickCB(inputData);
    }
  };

  const blurInputFunc = () => {
    if (onBlurCB !== undefined) {
      onBlurCB(inputData);
    }
  };

  return (
    <>
      <Form.Group>
        <Form.Label>
          {requiredHave && <span style={{ color: "red" }}>*</span>}
          <span style={{ fontSize: "13px" }}>{label}</span>
        </Form.Label>
        <Form.Control
          onClick={clickInputFunc}
          required={requiredHave}
          type={type}
          autoFocus={inputFocus}
          placeholder={placeholder}
          value={realValue}
          onBlur={blurInputFunc}
          onChange={($e) => {
            changeRowName($e.target.value);
          }}
          className={`form-control-dark ${className}`}
        ></Form.Control>
      </Form.Group>
    </>
  );
};

export default InputTextComp;
