import { useState, useEffect } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // This runs only on the client-side, since window is not available on the server-side
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as necessary
    };
    // Check on initial load
    checkMobile();
    // Add resize event listener to update when window resizes
    window.addEventListener("resize", checkMobile);
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
