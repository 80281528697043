import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Alert,
} from "react-bootstrap";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import LandingpageRow from "../../components/landingpage/structure/LandingpageRow";
import {
  createLandingpage,
  updateLandingpage,
  getLandingpageDetail,
  getPluginServices,
} from "../../services/invitationServices";
import {
  createTheme,
  getThemeDetail,
  getThemes,
  makePublicServices,
  updateTheme,
} from "../../services/themeServices";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TextView from "../../components/landingpage/TextView";
import ImageView from "../../components/landingpage/ImageView";
import ButtonView from "../../components/landingpage/ButtonView";
import RowView from "../../components/landingpage/RowView";
import ColomView from "../../components/landingpage/ColomView";
import VideoView from "../../components/landingpage/VideoView";
import ImageSliderView from "../../components/landingpage/ImageSliderView";
import FormView from "../../components/landingpage/FormView";
import HTMLView from "../../components/landingpage/HTMLView";
import IconView from "../../components/landingpage/IconView";
import ListView from "../../components/landingpage/ListView";
import CountDownView from "../../components/landingpage/CountDownView";
import MusicView from "../../components/landingpage/MusicView";
import ReactFontLoader from "react-font-loader";
import MapView from "../../components/landingpage/MapView";
import ReactAudioPlayer from "react-audio-player";
import "animate.css";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import {
  mapState,
  listState,
  imageState,
  musicState,
  iconState,
  textState,
  imagesState,
  videoState,
  countdownState,
  buttonState,
  formState,
  codeState,
  rowState,
  colomState,
  bankState,
  spaceState,
  lineState,
  navbarState,
  carouselState,
  timelineState,
  giftState,
  deviderState,
  imagesGalleryState,
} from "../../components/landingpage/state";
import SettingComp from "../../components/landingpage/SettingComp";
import NavbarSettingComp from "../../components/landingpage/NavbarSettingComp";
import RowModalComp from "../../components/landingpage/RowModalComp";
import BlankView from "../../components/landingpage/BlankView";
import BankView from "../../components/landingpage/BankView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBoxOpen,
  faClose,
  faGear,
  faMusic,
  faPaintBrush,
  faPlus,
  faTable,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import ThemeView from "../../components/landingpage/ThemeView";
import { getCategory } from "../../services/categoryServices";
import SpaceView from "../../components/landingpage/SpaceView";
import LineView from "../../components/landingpage/LineView";
import GiftView from "../../components/landingpage/GiftView";
import CarouselView from "../../components/landingpage/CarouselView";
import { deleteTheme } from "../../services/themeServices";
import DeviderView from "../../components/landingpage/DeviderView";
import ImageGalleryView from "../../components/landingpage/ImageGalleryView";
import uploadHelper from "../../helpers/uploadHelper";
import PluginView from "../../components/landingpage/PluginView";
import { checkDevice } from "../../helpers/windowHelper";
import { LARGEDEVICE, SMARTPHONE } from "../../variable";
import MediaView from "../../components/landingpage/MediaView";
import SnippetView from "../../components/landingpage/SnippetView";
import ComponentView from "../../components/landingpage/ComponentView";
import {
  clearEditorStorage,
  getLog,
  saveLog,
} from "../../helpers/storageHelper";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import ElementView from "../../components/landingpage/ElementView";
import Colors from "../../Colors";
import LoaderComp from "../../components/LoaderComp";
import {
  MEDIALARGE,
  MEDIAMEDIUM,
  MEDIASMALL,
  SIZEMEDIUM,
} from "../../variable";
import TimelineView from "../../components/landingpage/TimelineView";
import convertCSS from "../../helpers/cssHelper";
import InputComp from "../../components/form/InputComp";
import { useNavigate, useParams } from "react-router-dom";
import convertToWebP from "../../helpers/webPHelper";
import { faCss3 } from "@fortawesome/free-brands-svg-icons";
import CSSEditorComp from "../../components/landingpage/CSSEditorComp";
const WebsiteEditorPage = () => {
  let { idslug } = useParams();
  let navigate = useNavigate();
  const [isAbleSaveTheme, setIsAbleSaveTheme] = useState(true);
  const [isResponsive, setIsResponsive] = useState(true);
  const [showCanvas, setShowCanvas] = useState(true);
  const [thumbnailFD, setThumbnailFD] = useState();
  const [errorMessageTheme, setErrorMessageTheme] = useState("");
  const page = 1;
  const pageSize = 10;
  const [dontsaveLog, setDontSaveLog] = useState(false);
  const maxLog = 10;
  const [currentLog, setCurrentLog] = useState(null);
  const delay = 1;
  const [plugins, setPlugins] = useState(["kirim_instan"]);
  const [device, setDevice] = useState("");
  const [newPage, setNewPage] = useState(true);
  const [viewPluginLoading, setViewPluginLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [disableTheme, setDisableTheme] = useState(true);
  const [widthKey, setWidthKey] = useState("flex");
  const [theme, setTheme] = useState({
    name: "",
    is_active: true,
    category: "",
    json_theme: "",
    image: "",
    imagewebp: "",
    is_newversion: "",
    discount: "",
    price: "",
    _id: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showLeftSidemenu, setShowLeftSidebar] = useState(false);
  const [editorMode, setEditorMode] = useState(false);
  const [leftSidebarWidth, setLeftSidebarWidth] = useState("");
  const [listThemes, setListThemes] = useState([]);
  const [listImage, setListImage] = useState([]);
  const [listCategory, setListCategory] = useState([]);
  const [firstCategory, setFirstCategory] = useState("");
  const [shortCodeComp, setShortCodeComp] = useState("");
  const [imageCodeComp, setImageCodeComp] = useState("");
  const menuMobileHeight = "50px";
  const idElementsRef = useRef(null);

  const viewState = {
    viewCode: "Code",
    viewText: "Teks",
    viewIcon: "Icon",
    viewList: "List",
    viewMap: "Map",
    viewRow: "Row",
    viewCol: "column",
    viewImage: "Image",
    viewImageSlider: "ImageSlider",
    viewImageGallery: "ImageGallery",
    viewVideo: "Video",
    viewCountdown: "Countdown",
    viewForm: "Form",
    viewButton: "Button",
    viewMusic: "music",
    viewSetting: "Setting",
    viewBank: "Bank",
    viewSpace: "Space",
    viewLine: "Line",
    viewNavbar: "Navbar",
    viewCarousel: "Carousel",
    viewTimeline: "Timeline",
    viewGift: "Gift",
    viewDevider: "Devider",
  };

  const uploadSelectedFile = async (event) => {
    setIsUploading(true);
    let imgWebp = await convertToWebP(event.target.files);
    let result = await uploadHelper(event);
    if (result) {
      setTheme((prev) => {
        return {
          ...prev,
          image: result[0].widthImage,
          imagewebp: imgWebp[0],
        };
      });
    }

    setIsUploading(false);
  };

  const listMenu = [
    {
      name: "Komponen",
      icon: faTable,
      showMobile: true,
      index: 0,
    },
    {
      name: "Tema",
      icon: faPaintBrush,
      showMobile: true,
      index: 1,
    },
    // {
    //   name: "Element",
    //   icon: faImage,
    //   showMobile: false,
    //   index: 2,
    // },
    {
      name: "Musik",
      icon: faMusic,
      showMobile: true,
      index: 2,
    },
    {
      name: "Upload",
      icon: faUpload,
      showMobile: true,
      index: 3,
    },
    {
      name: "Plugin",
      icon: faBoxOpen,
      showMobile: true,
      index: 4,
    },
    {
      name: "Pengaturan",
      icon: faGear,
      showMobile: true,
      index: 5,
    },
  ];

  const fetchThemes = async () => {
    let res = await getThemes(page, pageSize);
    setListThemes(res.data.themeList);
  };

  const fetchCategory = async () => {
    let res = await getCategory();
    let allCat = {
      name: "semua",
      _id: "",
    };
    let tempData = [];
    tempData = [allCat, ...res.data.categoryList];
    setListCategory(tempData);
    setFirstCategory(res.data.categoryList[0]._id);
  };

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [viewActive, setViewActive] = useState("");
  const [viewActiveIndex, setViewActiveIndex] = useState("");
  const [colType, setColType] = useState(viewState.viewText);
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" && window.innerWidth
  );

  const [dragItem, setDragItem] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const chooseTheme = async (theme) => {
    let res = await getThemeDetail(theme);
    return res.data;
  };
  const [isNewRow, setIsNewRow] = useState(true);
  const [generalConfiguration, setGeneralConfiguration] = useState({
    hasMusic: false,
  });
  const listDragMenu = [
    {
      menu: viewState.viewText,
      iconClass: "fas fa-align-right pt-1 icon-menu",
      description: "Teks",
    },
    {
      menu: viewState.viewImage,
      iconClass: "fas fa-image pt-1 icon-menu",
      description: "Gambar",
    },
    {
      menu: viewState.viewImageSlider,
      iconClass: "fas fa-images pt-1 icon-menu",
      description: "Masonry",
    },
    {
      menu: viewState.viewImageGallery,
      iconClass: "fas fa-images pt-1 icon-menu",
      description: "Gallery",
    },
    {
      menu: viewState.viewVideo,
      iconClass: "fas fa-film pt-1 icon-menu",
      description: "Video",
    },
    {
      menu: viewState.viewButton,
      iconClass: "fas fa-mouse pt-1 icon-menu",
      description: "Tombol",
    },
    {
      menu: viewState.viewForm,
      iconClass: "fas fa-edit pt-1 icon-menu",
      description: "Form",
    },
    {
      menu: viewState.viewCountdown,
      iconClass: "fas fa-calendar-check pt-1 icon-menu",
      description: "Hitung Mundur",
    },
    {
      menu: viewState.viewIcon,
      iconClass: "fas fa-exclamation-circle pt-1 icon-menu",
      description: "Ikon",
    },
    {
      menu: viewState.viewSpace,
      iconClass: "fa fa-grip-lines pt-1 icon-menu",
      description: "Jarak",
    },
    {
      menu: viewState.viewMap,
      iconClass: "fas fa-map-marker pt-1 icon-menu",
      description: "Peta",
    },
    {
      menu: viewState.viewBank,
      iconClass: "fas fa-wallet pt-1 icon-menu",
      description: "Bank",
    },
    {
      menu: viewState.viewGift,
      iconClass: "fas fa-gift pt-1 icon-menu",
      description: "Kado",
    },
    {
      menu: viewState.viewTimeline,
      iconClass: "fas fa-timeline pt-1 icon-menu",
      description: "Timeline",
    },
    {
      menu: viewState.viewCol,
      iconClass: "fas fa-columns pt-1 icon-menu",
      description: "Kolom",
    },
  ];

  const [fontFamilyList, setFontFamilyList] = useState([
    {
      font: "Roboto",
      weights: [400, "400i"],
    },
  ]);

  const { user } = useSelector((state) => state);
  const domain = user.customerSetting.domain
    ? `${user.customerSetting.domain}`
    : process.env.REACT_APP_DOMAIN;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [newContent, setNewContent] = useState({
    status: false,
    type: "",
  });

  const changeWidthDevice = (width) => {
    setWidthDevice(width);
  };

  const [contentMusic, setContentMusic] = useState(musicState);
  const [showWayToAdd, setShowWayToAdd] = useState(false);
  const [showContentMenu, setShowContentMenu] = useState(false);
  const [widthDevice, setWidthDevice] = useState(
    window.innerWidth > SIZEMEDIUM
      ? MEDIALARGE
      : windowWidth < SIZEMEDIUM
      ? MEDIASMALL
      : MEDIAMEDIUM
  );

  const [currentRow, setCurrentRow] = useState({});
  const [currentColom, setCurrentColom] = useState(colomState);
  const [currentCol, setCurrentCol] = useState({});

  const handleShowWayToAdd = () => {
    setShowLeftSidebar(false);
    setEditorMode(false);
    setIsNewRow(true);
    setShowWayToAdd(true);
  };
  const [numberOfCol, setNumberOfCol] = useState(1);
  const [container, setContainer] = useState("container");

  const handleCloseWayToAdd = () => {
    setShowWayToAdd(false);
    setNumberOfCol(1);
  };

  const colomCBParent = (coldata) => {
    setShowContentMenu(false);
    var templpJson = { ...lpJson };
    let idElement = coldata.id;
    idElement = idElement.split("-");
    setTimeout(() => {
      let currentCol = getContent(
        templpJson.row[idElement[0]].col[idElement[1]],
        2,
        idElement
      );
      setCurrentCol(currentCol);
      setColType(currentCol.type);
      setTimeout(() => {
        viewChanger();
      }, 100);
    }, 41);
  };

  const colomRemoveCBParent = (coldata) => {
    setTimeout(() => {
      let idElement = coldata.id.split("-");
      var templpJson = { ...lpJson };
      removeContent(
        templpJson.row[idElement[0]].col[idElement[1]],
        2,
        idElement
      );
      setLPJson(templpJson);
    }, 100);
  };

  const removeRowParent = (coldata) => {
    var templpJson = { ...lpJson };
    if (coldata.index > -1) {
      templpJson.row.splice(coldata.index, 1);
    }
    setLPJson(templpJson);
  };

  const [lpJson, setLPJson] = useState({
    fontFamily: fontFamilyList,
    metaTitle: "",
    metaDesc: "",
    layout: "",
    mobile_only: true,
    music: contentMusic,
    is_responsive: true,
    row: [],
  });

  const fontCB = (fontFamily) => {
    var fonFamilyTemp = [...fontFamilyList];
    var fontFound = fonFamilyTemp.find((font) => {
      return font.font === fontFamily;
    });
    if (!fontFound) {
      fonFamilyTemp.push({
        font: fontFamily.replace(/ /g, "+"),
        weights: [400, "400i"],
      });
      setFontFamilyList(fonFamilyTemp);
    }
    return fonFamilyTemp;
  };

  const GeneralCB = (data) => {
    setGeneralConfiguration((prev) => {
      return {
        ...prev,
        hasMusic: true,
      };
    });
    setContentMusic({
      type: viewState.viewMusic,
      autoplay: true,
      music: data.music,
    });
  };

  const ViewCB = (coldata) => {
    setEditorMode(true);
    if (coldata.id === undefined) {
      return;
    }
    let currentLPJson = { ...lpJson };
    let idElement = coldata.id;
    idElement = idElement.split("-");
    setTimeout(() => {
      colomLoop(
        currentLPJson.row[idElement[0]].col[idElement[1]],
        2,
        idElement,
        coldata
      );
      setLPJson(currentLPJson);
    }, 1);
  };

  const [landingpage, setLandingpage] = useState({
    _id: "",
    title: "",
    meta_title: "",
    meta_desc: "",
    meta_image: "",
    layout: "",
    landingpage: "",
    app_id: "",
    images: [],
    content: [],
    json: {},
    mobile_only: false,
    is_webp: true,
  });
  const [slug, setSlug] = useState(idslug ? idslug : "");

  const pushRow = (row) => {
    row = JSON.parse(JSON.stringify(row));
    var tempjson = { ...lpJson };
    if (row.position === "fixed") {
      row.position = "static";
      row.fixedTo = "";
    }
    tempjson.row.splice(row.index + 1, 0, row);
    setLPJson(tempjson);
  };

  const changeType = async (type) => {
    setLandingpage((prev) => {
      return {
        ...prev,
        landingpage_type: type,
      };
    });
  };

  const changeNumOfCol = async (number_colom) => {
    setNumberOfCol(number_colom);
  };

  const changeColType = (value) => {
    setColType(value);
  };

  const viewChanger = () => {
    setShowLeftSidebar(true);
    setEditorMode(true);
    setShowContentMenu(true);
  };

  const checkingProperties = (landingpageData) => {
    for (let a = 0; a < landingpageData.row.length; a++) {
      for (let b = 0; b < landingpageData.row[a].col.length; b++) {
        if (landingpageData.row[a].col[b].overflow === undefined) {
          landingpageData.row[a].col[b].overflow = "hidden";
        }
        if (landingpageData.row[a].col[b].linear === undefined) {
          landingpageData.row[a].col[b].linear = "solid";
        }
        if (landingpageData.row[a].col[b].foreground === undefined) {
          if (landingpageData.row[a].col[b].cover !== "") {
            landingpageData.row[a].col[b].foreground = "yes";
          } else {
            landingpageData.row[a].col[b].foreground = "no";
          }
        }
      }
      if (landingpageData.row[a].overflow === undefined) {
        landingpageData.row[a].overflow = "hidden";
      }
      if (landingpageData.row[a].linear === undefined) {
        landingpageData.row[a].linear = "solid";
      }
      if (landingpageData.row[a].foreground === undefined) {
        if (landingpageData.row[a].cover !== "") {
          landingpageData.row[a].foreground = "yes";
        } else {
          landingpageData.row[a].foreground = "no";
        }
      }
    }
    return landingpageData;
  };

  const getlandingpage = async (id_landingpage) => {
    setShowCanvas(false);
    setLandingpage((prev) => {
      return {
        ...prev,
        _id: id_landingpage,
      };
    });
    await getLandingpageDetail(id_landingpage)
      .then((response) => {
        let awebp = false;
        if (
          response?.data?.is_webp ||
          response?.data?._id === "670e19fa79ee8417e8381f53" ||
          response?.data?._id === "670e1cad8feecb08bd8408e6" ||
          response?.data?._id === "670ef4692ab9f708bf3bad19" ||
          response?.data?._id === "670e12d42901c908b9e917b6" ||
          response?.data?._id === "670c5e6c40bbeb2f65d4bf2a" ||
          response?.data?._id === "670baeab8cf9ed08b96bd9d2" ||
          response?.data?._id === "670c7d43eea1a408a9ed1365" ||
          response?.data?._id === "670c9cf0aa0d7208b543f05d" ||
          response?.data?._id === "6709cce4c9d75b1b5cdfb28b"
        ) {
          awebp = true;
        }
        console.log("responseresponse", response?.data);
        setLandingpage({
          _id: id_landingpage,
          title: response.data.title,
          meta_title: response.data.meta_title,
          meta_desc: response.data.meta_desc,
          meta_image: response.data.meta_image,
          teks_cover: response.data.teks_cover,
          image_cover: response.data.image_cover,
          font_family: response.data.font_family,
          app_id: response.data.app_id,
          json: response.data.json,
          is_active: response.data.is_active,
          iscache: response.data.iscache,
          product: response.data.product,
          slug: response.data.slug,
          is_responsive:
            response.data.is_responsive != undefined
              ? response.data.is_responsive
              : true,
          css_code: "",
          custom_css_code: response?.data?.custom_css_code
            ? response?.data?.custom_css_code
            : `
                `,
          is_newversion: response?.data?.is_newversion
            ? response?.data?.is_newversion
            : false,
          is_webp: awebp,
        });
        let isResponsiveTemp =
          response.data.is_responsive != undefined
            ? response.data.is_responsive
            : true;
        changeCBResponsive(isResponsiveTemp);

        if (response.data?.plugins) {
          setPlugins(response.data.plugins);
        }
        if (response.data.layout === "mobile") {
          changeWidthDevice("360px");
        }

        var landingpageData = JSON.parse(response.data.json);
        landingpageData = checkingProperties(landingpageData);
        setLPJson(landingpageData);

        if (
          landingpageData[viewState.viewMusic] &&
          landingpageData[viewState.viewMusic].music !== ""
        ) {
          setContentMusic({
            type: viewState.viewMusic,
            autoplay: true,
            [viewState.viewMusic]: landingpageData[viewState.viewMusic].music,
          });
          setGeneralConfiguration((prev) => {
            return {
              ...prev,
              hasMusic: true,
            };
          });
        }
        if (typeof landingpageData.fontFamily === "object") {
          let fonFamilyTemp = [];
          if (
            landingpageData.fontFamily &&
            landingpageData.fontFamily.length > 0
          ) {
            for (let a = 0; a < landingpageData.fontFamily.length; a++) {
              if (
                landingpageData.fontFamily[a].font &&
                landingpageData.fontFamily[a].weights
              ) {
                if (
                  !fonFamilyTemp.includes(landingpageData.fontFamily[a].font)
                ) {
                  fonFamilyTemp.push({
                    font: landingpageData.fontFamily[a].font.replace(/ /g, "+"),
                    weights: [400, 500, 600, 700, 800, 900],
                  });
                }
              }
            }
          }

          setFontFamilyList(fonFamilyTemp);
        }
        setTimeout(() => {
          setShowCanvas(true);
        }, 1000);
      })
      .catch((err) => {});
  };

  const removeContentCB = (a) => {
    var currentContent = landingpage.content.filter((p, index) => {
      return a !== index;
    });
    setLandingpage((prev) => {
      return {
        ...prev,
        content: currentContent,
      };
    });
  };

  const changeLandingpageTitle = async (title) => {
    let lp = { ...landingpage };
    lp = { ...lp, ...title };
    setLandingpage(lp);
  };

  const addContent = async () => {
    setShowWayToAdd(false);
    rowState.container = container;
    let currentRow = { ...rowState };
    currentRow.col = [];
    for (let a = 0; a < numberOfCol; a++) {
      let newColState = { ...colomState };
      currentRow.col.push(newColState);
    }
    let fixedRowTemp;
    let hasFixedRow = false;
    let fixedRowIndex = lpJson.row.findIndex((r) => r.position === "fixed");
    if (fixedRowIndex !== -1) {
      fixedRowTemp = lpJson.row[fixedRowIndex];
      hasFixedRow = true;
      if (hasFixedRow > -1) {
        var lpjsontemp = { ...lpJson };
        lpjsontemp.row.splice(fixedRowIndex, 1); // 2nd parameter means remove one item only
        if (fixedRowTemp.fixedTo === "top") {
          lpjsontemp.row.push(currentRow);
          lpjsontemp.row.unshift(fixedRowTemp);
          setLPJson(lpjsontemp);
          return;
        }
        if (fixedRowTemp.fixedTo === "bottom") {
          lpjsontemp.row.push(currentRow);
          lpjsontemp.row.push(fixedRowTemp);
          setLPJson(lpjsontemp);
          return;
        }
      }
    } else {
      setTimeout(() => {
        setLPJson((prev) => {
          return {
            ...prev,
            row: [...prev.row, currentRow],
          };
        });
        setNumberOfCol(1);
        setContainer("container");
        if (newPage) {
          setViewActive("Tema");
          setViewActiveIndex(1);
          setShowLeftSidebar(true);
          setEditorMode(false);
          setNewPage(false);
        } else {
          changeRow(currentRow);
        }
      }, 100);
    }
  };

  const convertTheme = async (slug) => {
    setShowCanvas(false);
    let selectedTheme = await chooseTheme(slug);
    setLandingpage((prev) => {
      return {
        ...prev,
        teks_cover: selectedTheme.name,
        image_cover: selectedTheme.image,
        font_family: "",
        app_id: user.app_id,
        json: selectedTheme.json_theme,
        is_newversion: true,
        css_code: "",
        is_webp: selectedTheme.is_webp ? selectedTheme.is_webp : false,
      };
    });
    var landingpageData = JSON.parse(selectedTheme.json_theme);
    landingpageData = checkingProperties(landingpageData);
    setLPJson(landingpageData);
    if (typeof landingpageData.fontFamily === "object") {
      let fonFamilyTemp = [];
      if (landingpageData.fontFamily && landingpageData.fontFamily.length > 0) {
        for (let a = 0; a < landingpageData.fontFamily.length; a++) {
          if (!fonFamilyTemp.includes(landingpageData.fontFamily[a].font)) {
            fonFamilyTemp.push({
              font: landingpageData.fontFamily[a].font.replace(/ /g, "+"),
              weights: [400, "400i"],
            });
          }
        }
      }
      setFontFamilyList(fonFamilyTemp);
    }
    setSlug("");
    setTimeout(() => {
      setShowCanvas(true);
    }, 1000);
  };

  useEffect(() => {
    if (slug) {
      convertTheme(slug);
    }
  }, [slug]);

  useEffect(() => {
    if (idslug) {
      getlandingpage(idslug);
    }
  }, [idslug]);

  const checkingKeyBoard = async (e) => {
    // console.log("e.keyCode",e.keyCode)
    //undo key is 90
    if (e.keyCode === "90" && e.ctrlKey) {
      // await undoFunc()

      document.getElementById("undo").click();
    }
    //redo key is 89
    if (e.keyCode === "89" && e.ctrlKey) {
      document.getElementById("redo").click();
    }
    console.log("ruuunnn");
  };

  useEffect(() => {
    clearEditorStorage();
    fetchThemes();
    fetchCategory();
    saveLog([]);
    if (!idslug) {
      setTimeout(() => {
        ColCB();
      }, 100);
    }
    setDevice(checkDevice());
    document.addEventListener("keydown", checkingKeyBoard);
  }, []);

  useEffect(() => {
    setWidthKey(device === SMARTPHONE ? "width" : "flex");
  }, [device]);

  useEffect(
    () => {
      let timer1 = setTimeout(async () => {
        let newLog = await getLog();
        if (newLog) {
          newLog = JSON.parse(newLog);
        } else {
          newLog = [];
        }
        if (newLog.length > 0) {
          if (lpJson !== newLog[newLog.length - 1]) {
            if (newLog.length > maxLog) {
              newLog.shift();
            }
            if (lpJson !== null) {
              newLog.push(lpJson);
            }
          }
        } else {
          if (lpJson !== null) {
            newLog.push(lpJson);
          }
        }
        if (!dontsaveLog) {
          // await localStorage.setItem("log", JSON.stringify(newLog))
          if (newLog) {
            saveLog(JSON.stringify(newLog));
            ///ERROR
            // if(newLog.length>1){
            //     setCurrentLog(newLog.length - 1)
            // }
          }
        }
      }, delay * 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer1);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    [lpJson]
  );

  const submit = async () => {
    setIsSaving(true);
    let tempJson = { ...lpJson };
    let cssEncrypt = tempJson.row;
    let convertion = convertCSS(cssEncrypt, tempJson.is_responsive);
    let filterFontFamily = convertion.fontFamily;
    if (landingpage.title === "") {
      setShowLeftSidebar(true);
      setViewActive("Pengaturan");
      setViewActiveIndex(6);
      setEditorMode(false);
      setShowContentMenu(false);
      setIsSaving(false);
    } else {
      if (convertion.errorMessage.trim() !== "") {
        alert(convertion.errorMessage);
        setTimeout(() => {
          setIsSaving(false);
        }, 500);
      } else {
        landingpage.css_code = convertion.css;
        lpJson.music = contentMusic;
        lpJson.fontFamily = filterFontFamily;
        landingpage.json = JSON.stringify(lpJson);
        landingpage.is_responsive = isResponsive;
        console.log("landingpage submit", landingpage);
        if (landingpage._id) {
          // landingpage.isOptimized = isOptimized
          updateLandingpage(landingpage._id, landingpage)
            .then((res) => {
              setLandingpage((prev) => {
                return {
                  ...prev,
                  slug: res.data.slug,
                };
              });
              setTimeout(() => {
                setIsSaving(false);
              }, 500);
            })
            .catch((err) => {
              alert("Nama undangan telah digunakan , silahkan ubah");
              setIsSaving(false);
            });
        } else {
          landingpage.is_newversion = true;
          // landingpage.is_webp = true;
          let res = await createLandingpage(landingpage).then((result) => {
            navigate("/");
          });
        }
      }
    }
  };

  const changeRow = (datarow) => {
    setShowLeftSidebar(true);
    setEditorMode(true);
    setColType(viewState.viewRow);
    setCurrentRow("");
    setTimeout(() => {
      setCurrentRow(datarow);
    }, 10);
  };

  const getContent = (dataColom, index, idElement) => {
    if (index === idElement.length - 1) {
      let result = dataColom.content[idElement[index]];
      return result;
    } else {
      if (dataColom.content[idElement[index]]) {
        let nextIndex = index + 1;
        return getContent(
          dataColom.content[idElement[index]],
          nextIndex,
          idElement
        );
      }
    }
  };

  const removeContent = (dataColom, index, idElement) => {
    if (index === idElement.length - 1) {
      if (idElement[index] > -1) {
        return dataColom.content.splice(idElement[index], 1);
      }
    } else {
      let nextIndex = index + 1;
      return removeContent(
        dataColom.content[idElement[index]],
        nextIndex,
        idElement
      );
    }
  };

  const colomLoop = (objColom, a, idElement, tempdata) => {
    let total = idElement.length;
    if (a === total - 1) {
      objColom.content[idElement[a]] = tempdata;
      return objColom;
    } else {
      if (objColom.content[idElement[a]]) {
        let indexke = a + 1;
        return colomLoop(
          objColom.content[idElement[a]],
          indexke,
          idElement,
          tempdata
        );
      }
    }
  };

  const colomRemoveLoop = (currentPosition, index, idElements) => {
    if (index === idElements.length - 1) {
      /**
       * check if colom has another component
       */
      if (currentPosition.content.length > 1) {
        //if no other component remove only colom
        currentPosition.content.splice(idElements[index], 1);
        return false;
      } else {
        // reset colom
        currentPosition.content = [];
        return false;
      }
    } else {
      let nextIndex = index + 1;
      return colomRemoveLoop(
        currentPosition.content[index],
        nextIndex,
        idElements
      );
    }
  };

  const colomRemove = (dataColom, index, idElements) => {
    if (index === idElements.length - 1) {
      const delIndex = idElements[idElements.length - 1];
      if (delIndex > -1) {
        if (dataColom.content) {
          if (dataColom.content.length === 1) {
            //if the last colom => remove current row
            let temp = { ...lpJson };
            let status = colomRemoveLoop(
              temp.row[idElements[0]].col[idElements[1]],
              index,
              idElements
            );
            if (status === false) {
              setLPJson(temp);
              return false;
            } else {
              return true;
            }
          } else {
            dataColom.content.splice(delIndex, 1);
          }
        } else {
          dataColom.splice(delIndex, 1);
        }
      }
      return dataColom;
    } else {
      let newIndex = index + 1;
      return colomRemove(
        dataColom.content[idElements[index]],
        newIndex,
        idElements
      );
    }
  };

  const changeCol = async (action, idElement, iscustom, colomdata) => {
    let selectedLpJson = { ...lpJson };
    idElement = await idElement.split("-");

    switch (action) {
      case "edit":
        setShowLeftSidebar(true);
        setEditorMode(true);
        setColType(viewState.viewCol);
        setCurrentColom("");
        setCurrentRow(idElement[0]);
        setTimeout(() => {
          let col = selectedLpJson?.row[idElement[0]].col[idElement[1]];
          for (let nestedCol = 2; nestedCol < idElement.length; nestedCol++) {
            col = col.content[idElement[nestedCol]];
          }
          let newId = idElement.toString();
          newId = newId.replaceAll(",", "-");
          col.id = newId;
          setCurrentColom(col);
        }, 10);
        break;
      case "remove":
        let result = true;
        if (idElement.length > 2) {
          result = colomRemove(
            selectedLpJson?.row[idElement[0]]?.col[idElement[1]],
            2,
            idElement
          );
        } else {
          if (selectedLpJson?.row[idElement[0]].col.length === 1) {
            selectedLpJson?.row.splice(idElement[0], 1);
          } else {
            selectedLpJson?.row[idElement[0]].col.splice(idElement[1], 1);
          }
        }
        if (result !== false) {
          setLPJson(selectedLpJson);
        }
        break;
    }
  };

  const CBRowData = (data) => {
    let currentLPData = { ...lpJson };
    if (data.position === "fixed" && data.newPosition) {
      data.newPosition = false;
      if (srcIndex !== data.index) {
        var srcIndex = data.index;
        var lastIndex = currentLPData.row.length - 1;
        var firstIndex = 0;
        var rowTemp;
        var hasFixedTop = false;
        if (data.fixedTo === "top") {
          //if already at top
          if (firstIndex === data.index) {
            currentLPData.row[firstIndex].position = "fixed";
            currentLPData.row[firstIndex].fixedTo = "top";
          } else {
            if (
              currentLPData.row[srcIndex].position === "fixed" &&
              currentLPData.row[srcIndex].fixedTo === "bottom"
            ) {
              currentLPData.row.splice(srcIndex, 1); // 2nd parameter means remove one item only
            }
            if (currentLPData.row[0].position === "fixed") {
              currentLPData.row[0].position = "static";
              currentLPData.row[0].fixedTo = "";
              rowTemp = currentLPData.row[0];
              currentLPData.row.splice(0, 1);
              hasFixedTop = true;
            }
            currentLPData.row.splice(srcIndex, 1); // 2nd parameter means remove one item only
            if (hasFixedTop) {
              currentLPData.row.unshift(rowTemp);
            }

            data.index = 0;
            data.position = "fixed";
            data.fixedTo = "top";
            currentLPData.row.unshift(data);
          }
          setLPJson(currentLPData);
          return;
        }

        if (data.fixedTo === "bottom") {
          //if already at bottom
          if (lastIndex === data.index) {
            currentLPData.row[lastIndex].position = "fixed";
            currentLPData.row[lastIndex].fixedTo = "bottom";
          } else {
            //if not at bottom
            if (data.index === 0 && currentLPData.row[0].fixedTo === "top") {
            }
            // currentLPData.row[lastIndex - 1] = dataifz
            if (
              currentLPData.row[currentLPData.row.length - 1].position ===
              "fixed"
            ) {
              currentLPData.row[currentLPData.row.length - 1].position =
                "static";
              currentLPData.row[currentLPData.row.length - 1].fixedTo = "";
              currentLPData.row[currentLPData.row.length - 2] =
                currentLPData.row[currentLPData.row.length];
            }
            rowTemp = currentLPData.row[srcIndex];
            currentLPData.row.splice(srcIndex, 1);
            currentLPData.row.push(rowTemp);
            currentLPData.row[currentLPData.row.length - 1].index =
              currentLPData.row.length - 1;
            currentLPData.row[currentLPData.row.length - 1].position = "fixed";
            currentLPData.row[currentLPData.row.length - 1].fixedTo = "bottom";
          }
          setLPJson(currentLPData);
          return;
        }
      }
    } else {
      let currentRowData = { ...lpJson.row[currentRow.index] };
      currentRowData = data;
      currentLPData.row[currentRow.index] = currentRowData;
    }
    console.log("currentLPData", currentLPData);
    setLPJson(currentLPData);
  };

  const CBColomData = (data) => {
    let idElement = data.id;
    idElement = idElement.split("-");
    let currentLPData = { ...lpJson };
    let selectedElement = "";
    let totalElements = idElement.length;
    let nested = totalElements > 2 ? true : false;
    if (nested) {
      selectedElement = currentLPData?.row[idElement[0]]?.col[idElement[1]];
      for (let colNumber = 2; colNumber < totalElements; colNumber++) {
        selectedElement = selectedElement?.content[idElement[colNumber]];
      }
    } else {
      selectedElement = currentLPData?.row[idElement[0]]?.col[idElement[1]];
    }
    let contentTemp = selectedElement?.content;
    if (!contentTemp) {
      contentTemp = [];
    }

    let tempdata = {
      flexDirection: data ? data.flexDirection : selectedElement.flexDirection,
      foreground: data ? data.foreground : selectedElement.foreground,
      backgroundColor: data
        ? data.backgroundColor
        : selectedElement.backgroundColor,
      backgroundColor2: data
        ? data.backgroundColor2
        : selectedElement.backgroundColor2,
      linear: data ? data.linear : selectedElement.linear,
      marginBottom: data ? data.marginBottom : selectedElement.marginBottom,
      marginLeft: data ? data.marginLeft : selectedElement.marginLeft,
      marginRight: data ? data.marginRight : selectedElement.marginRight,
      marginTop: data ? data.marginTop : selectedElement.marginTop,
      paddingBottom: data ? data.paddingBottom : selectedElement.paddingBottom,
      paddingLeft: data ? data.paddingLeft : selectedElement.paddingLeft,
      paddingRight: data ? data.paddingRight : selectedElement.paddingRight,
      paddingTop: data ? data.paddingTop : selectedElement.paddingTop,
      isTransparent: data ? data.isTransparent : selectedElement.isTransparent,
      borderRadius: data ? data.borderRadius : selectedElement.borderRadius,
      borderTopLeftRadius: data
        ? data.borderTopLeftRadius
        : selectedElement.borderTopLeftRadius,
      borderTopRightRadius: data
        ? data.borderTopRightRadius
        : selectedElement.borderTopRightRadius,
      borderBottomLeftRadius: data
        ? data.borderBottomLeftRadius
        : selectedElement.borderBottomLeftRadius,
      borderBottomRightRadius: data
        ? data.borderBottomRightRadius
        : selectedElement.borderBottomRightRadius,
      border: data ? data.border : selectedElement.border,
      borderType: data ? data.borderType : selectedElement.borderType,
      borderColor: data ? data.borderColor : selectedElement.borderColor,
      backgroundType: data
        ? data.backgroundType
        : selectedElement.backgroundType,
      backgroundImage: data
        ? data.backgroundImage
        : selectedElement.backgroundImage,
      backgroundSize: data
        ? data.backgroundSize
        : selectedElement.backgroundSize,
      opacity: data ? data.opacity : selectedElement.opacity,
      image_slide: data ? data.image_slide : selectedElement.image_slide,
      cover: data ? data.cover : selectedElement.cover,
      cover2: data ? data.cover2 : selectedElement.cover2,
      widthType: data ? data.widthType : selectedElement.widthType,
      position: data ? data.position : selectedElement.position,
      width: data ? data.width : selectedElement.width,
      flex: data ? data.flex : selectedElement.flex,
      mediaQueri768: data ? data.mediaQueri768 : selectedElement.mediaQueri768,
      mediaQueri360: data ? data.mediaQueri360 : selectedElement.mediaQueri360,
      content: contentTemp,
    };
    setCurrentColom(data ? data : selectedElement);
    if (nested) {
      colomLoop(
        currentLPData.row[idElement[0]].col[idElement[1]],
        2,
        idElement,
        tempdata
      );
      setLPJson(currentLPData);
    } else {
      currentLPData.row[idElement[0]].col[idElement[1]] = tempdata;
      setLPJson(currentLPData);
    }
  };

  const setView = () => {
    return <TextView data={currentCol}></TextView>;
  };

  const ColCB = () => {
    if (isNewRow === true) {
      //new row
      let colstate = { ...colomState };
      setCurrentColom(colstate);
      setTimeout(() => {
        addContent();
      }, 1);
    } else {
      let idElements = idElementsRef.current.split("-");
      var templpJson = { ...lpJson };
      var tempRow = { ...templpJson?.row[parseInt(idElements[0])] };
      var tempCol = { ...tempRow?.col[parseInt(idElements[1])] };
      var tempContent = [...tempCol?.content];
      var contentAdded = { ...imageState };
      let row = [];
      for (let a = 0; a < numberOfCol; a++) {
        let colstate = { ...colomState };
        colstate.content = [];
        row.push(colstate);
      }
      contentAdded.type = viewState.viewCol;
      contentAdded.content = row;
      let colPosition =
        parseInt(templpJson?.row[parseInt(idElements[0])]?.col.length) - 1;
      contentAdded.index_col = colPosition;
      tempContent.splice(colPosition, 0, contentAdded);
      templpJson.row[parseInt(idElements[0])].col[
        parseInt(idElements[1])
      ].content = tempContent;
      setLPJson(templpJson);
      setShowWayToAdd(false);
      idElementsRef.current = null;
    }
  };

  useEffect(() => {
    setView();
  }, [colType]);

  const removeMusic = () => {
    setContentMusic({
      type: viewState.viewMusic,
      autoplay: false,
      [viewState.viewMusic]: "",
    });
    setGeneralConfiguration((prev) => {
      return {
        ...prev,
        hasMusic: false,
      };
    });
  };

  const changeColCB = (structure, srcI, desI) => {
    if (showLeftSidemenu) {
      setShowLeftSidebar(false);
    }
    let row = structure.row;
    let col = structure.col;
    var currentLp = { ...lpJson };
    if (structure.rowColRow !== undefined) {
      currentLp.row[row].col[col].content[structure.rowColRow].content[
        structure.rowColRowCol
      ].content.splice(
        desI,
        0,
        currentLp.row[row].col[col].content[structure.rowColRow].content[
          structure.rowColRowCol
        ].content.splice(srcI, 1)[0]
      );
    } else {
      currentLp.row[row].col[col].content.splice(
        desI,
        0,
        currentLp.row[row].col[col].content.splice(srcI, 1)[0]
      );
    }
    setLPJson(currentLp);
  };

  const changeColomOrderCB = (structure, srcI, desI) => {
    var currentLp = { ...lpJson };
    if (structure.rowColRow !== undefined) {
      currentLp.row[structure.row].col[structure.colom].content[
        structure.rowColRow
      ].content.splice(
        desI,
        0,
        currentLp.row[structure.row].col[structure.colom].content[
          structure.rowColRow
        ].content.splice(srcI, 1)[0]
      );
    } else {
      currentLp.row[structure.row].col.splice(
        desI,
        0,
        currentLp.row[structure.row].col.splice(srcI, 1)[0]
      );
    }
    setLPJson(currentLp);
  };

  const openMusicView = (value) => {
    changeColType(value);
  };

  const injectComponent = (compData, contentAdded, index, idElement) => {
    if (index === idElement.length || index === idElement.length - 1) {
      if (compData.content) {
        let lastIndex = parseInt(idElement[idElement.length - 1]);
        let tempContent = [...compData.content];
        tempContent.splice(lastIndex + 1, 0, contentAdded);
        compData.content = tempContent;
        return compData;
      }
    } else {
      let nextIndex = index + 1;
      return injectComponent(
        compData.content[parseInt(idElement[index])],
        contentAdded,
        nextIndex,
        idElement
      );
    }
  };

  const addItem = (idElement, isMobile) => {
    if (isMobile) {
      idElement = idElement.split("-");
    }
    var templpJson = { ...lpJson };
    var tempRow = { ...templpJson.row[parseInt(idElement[0])] };
    if (!tempRow.col) {
      return;
    }
    var contentAdded = textState;
    var type = dragItem;
    switch (type) {
      case viewState.viewText:
        contentAdded = textState;
        break;
      case viewState.viewImage:
        if (imageCodeComp) {
          let newImageState = { ...imageState };
          newImageState.image = imageCodeComp;
          newImageState.image378 = imageCodeComp;
          newImageState.image678 = imageCodeComp;
          contentAdded = newImageState;
        } else {
          contentAdded = imageState;
        }
        break;
      case viewState.viewImageSlider:
        contentAdded = imagesState;
        break;
      case viewState.viewImageGallery:
        contentAdded = imagesGalleryState;
        break;
      case viewState.viewCarousel:
        contentAdded = carouselState;
        break;

      case viewState.viewVideo:
        contentAdded = videoState;
        break;
      case viewState.viewButton:
        contentAdded = buttonState;
        break;
      case viewState.viewBank:
        contentAdded = bankState;
        break;
      case viewState.viewGift:
        contentAdded = giftState;
        break;
      case viewState.viewDevider:
        contentAdded = deviderState;
        break;
      case viewState.viewSpace:
        contentAdded = spaceState;
        break;
      case viewState.viewLine:
        contentAdded = lineState;
        break;
      case viewState.viewNavbar:
        contentAdded = navbarState;
        break;
      case viewState.viewCarousel:
        contentAdded = carouselState;
        break;
      case viewState.viewTimeline:
        let newTimeline = { ...timelineState };
        newTimeline.content = [
          {
            title: "Judul disini",
            desc: "Deskripsi disini",
            year: "Tahun disini",
            editMode: true,
          },
        ];
        contentAdded = newTimeline;
        break;
      case viewState.viewList:
        contentAdded = listState;
        break;
      case viewState.viewForm:
        contentAdded = formState;
        break;
      case viewState.viewCountdown:
        contentAdded = countdownState;
        break;
      case viewState.viewIcon:
        contentAdded = iconState;
        break;
      case viewState.viewCode:
        if (shortCodeComp) {
          let newCodeState = { ...codeState };
          newCodeState.html = shortCodeComp;
          contentAdded = newCodeState;
        } else {
          contentAdded = codeState;
        }
        break;
      case viewState.viewMap:
        contentAdded = mapState;
        break;
      case viewState.viewCol:
        setShowWayToAdd(true);
        setIsNewRow(false);
        break;
    }
    if (type !== viewState.viewCol) {
      injectComponent(
        templpJson.row[parseInt(idElement[0])].col[parseInt(idElement[1])],
        contentAdded,
        2,
        idElement
      );
      setLPJson(templpJson);
    }
    if (device === SMARTPHONE) {
      setTimeout(() => {
        document.getElementById("sidemenu-inner").style.visibility = "visible";
        document.getElementById("sidemenu-arrow").style.visibility = "visible";
        setShowLeftSidebar(false);
      }, 100);
    }
  };

  const drag = (ev, item, value) => {
    setDragItem(item);
    if (value) {
      if (item === viewState.viewImage) {
        setImageCodeComp(value);
      }
      if (item === viewState.viewCode) {
        setShortCodeComp(value);
      }
    } else {
      setShortCodeComp("");
      setImageCodeComp("");
    }
  };

  const allowDrop = (ev) => {
    ev.preventDefault();
  };

  const dropNow = (ev) => {
    ev.preventDefault();
    var element = ev.target;
    let idElement = element.getAttribute("id");
    if (idElement !== null) {
      //push new data
      idElementsRef.current = idElement;
      idElement = idElement.split("-");
      addItem(idElement);
    }
  };

  let moving = null;
  const pickup = (event) => {
    moving = event.target;
    moving.style.position = "fixed";
  };

  useEffect(() => {
    if (showLeftSidemenu) {
      setLeftSidebarWidth("332px");
    } else {
      setLeftSidebarWidth("80px");
    }
  }, [showLeftSidemenu]);

  const applyThemeCB = (theme) => {
    setSlug(theme._id);
    setTheme(theme);
    setDisableTheme(false);
    changeCBResponsive(theme?.is_responsive);
  };

  const copyjsonCB = async (status) => {
    setShowLeftSidebar(false);
    let css_code = convertCSS(lpJson.row);
    let templpJson = lpJson;
    templpJson.music = contentMusic;
    templpJson.fontFamily = css_code.fontFamily;
    if (templpJson) {
      if (status === "create") {
        setTheme((prev) => {
          return {
            ...prev,
            _id: "",
            category: firstCategory,
            json_theme: JSON.stringify(templpJson),
            app_id: user.app_id,
            css_code: css_code.css,
            is_newversion: true,
            is_webp: landingpage.is_webp,
          };
        });

        setShowModal(true);
      }
      if (status === "save") {
        setTimeout(() => {
          setShowModal(true);
        }, 100);
      }
    }
    if (status === "delete") {
      setShowModal(false);
      setTimeout(() => {
        setShowModalDelete(true);
      }, 100);
    }
  };

  const changeData = (key, value) => {
    setTheme((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    if (theme.image && theme.name) {
      setDisableTheme(false);
    } else {
      setDisableTheme(true);
    }
  }, [theme]);

  const clearTheme = () => {
    setTheme({
      name: "",
      is_active: true,
      json_theme: "",
      image: "",
      category: "",
      is_webp: "",
      is_newversion: "",
      _id: "",
    });
  };

  const saveTheme = async () => {
    theme.is_responsive = isResponsive;
    theme.is_webp = landingpage.is_webp;
    if (
      theme?.app_id?.toString() === "63cd0c7254920f655d6052fc" &&
      user?.app_id?.toString() !== "63cd0c7254920f655d6052fc"
    ) {
      //member can create private theme based on public themes
      theme.app_id = user?.app_id;
      console.log("theme created", theme);
      let res = await createTheme(theme);
      if (res) {
        clearTheme();
        setShowModal(false);
      } else {
        setErrorMessageTheme("Gagal Membuat Tema");
      }
    } else {
      let res;
      if (theme._id !== "") {
        //disini
        let css_code = convertCSS(lpJson.row, lpJson?.is_responsive);
        let templpJson = lpJson;
        templpJson.music = contentMusic;
        templpJson.fontFamily = css_code.fontFamily;
        theme.css_code = css_code.css;
        theme.json_theme = JSON.stringify(templpJson);
        res = await updateTheme(theme._id, theme);
        if (res) {
          window.location.reload();
        }
      } else {
        console.log("themedsf", theme);

        res = await createTheme(theme);
        console.log("theme created", theme);
      }
      if (res) {
        clearTheme();
        setShowModal(false);
      } else {
        setErrorMessageTheme("Gagal membuat tema");
      }
    }
  };

  const removeTheme = async () => {
    let res = await deleteTheme(theme._id);
    if (res) {
      alert("tema berhasil dihapus");
    } else {
      alert("tema gagal dihapus");
    }
    window.location.reload();
    setShowModalDelete(false);
  };

  const undoFunc = async () => {
    let logs = await getLog();
    logs = JSON.parse(logs);

    setDontSaveLog(true);
    if (currentLog === null) {
      setCurrentLog(logs.length - 1);
      setLPJson(logs[logs.length - 1]);
    } else {
      if (currentLog > 0) {
        setCurrentLog(currentLog - 1);
        setLPJson(logs[currentLog - 1]);
      }
    }
    setTimeout(() => {
      setDontSaveLog(false);
    }, 1000);
  };

  const redoFunc = async () => {
    let logs = await getLog();
    if (logs) {
      logs = JSON.parse(logs);
      setDontSaveLog(true);
      if (parseInt(currentLog) >= parseInt(logs.length) - 1) {
        return;
      } else {
        if (currentLog >= 0 && currentLog < maxLog) {
          setCurrentLog(currentLog + 1);
          setLPJson(logs[currentLog + 1]);
        }
      }
      setTimeout(() => {
        setDontSaveLog(false);
      }, 1000);
    }
  };

  const openPluginCB = (plugin) => {
    switch (plugin) {
      case "kirim_instan":
        if (landingpage.slug !== undefined) {
          window.open(`https://${domain}/send/${landingpage.slug}`);
        } else {
          alert("simpan undangan terlebih dulu");
        }
        break;
      case "daftar_tamu":
        if (landingpage.slug !== undefined) {
          window.open(`https://${domain}/guest/${landingpage.slug}`);
        } else {
          alert("simpan undangan terlebih dulu");
        }
        break;
      case "buku_tamu":
        alert("maintanance");
        break;
      case "css_editor":
        setViewActive("css_editor");
        break;
    }
  };

  useEffect(() => {
    if (viewActive === "Plugin") {
      if (landingpage._id) {
        const fetchData = async () => {
          const res = await getPluginServices(landingpage._id);
          if (res) {
            let tempPlugins = res.data.plugins;
            setPlugins(tempPlugins);
            setViewPluginLoading(false);
          }
        };
        // call the function
        fetchData();
      }
    }

    if (viewActive !== "Plugin") {
      setViewPluginLoading(true);
    }
  }, [viewActive]);

  const mobileMoveAction = () => {
    if (device === SMARTPHONE) {
      // document.getElementById("sidemenu").style.width = "80px"
      document.getElementById("sidemenu-inner").style.visibility = "hidden";
      document.getElementById("sidemenu-inner").style.width = "0px";
      document.getElementById("sidemenu-arrow").style.visibility = "hidden";
      document.getElementById("canvas").style.width = MEDIALARGE;
    }

    // setShowLeftSidebar(false)
  };

  const openElement = () => {
    setViewActive("Komponen");
    setViewActiveIndex(0);
    setShowLeftSidebar(true);
    setEditorMode(false);
  };

  const changeCBResponsive = (value) => {
    setIsResponsive(value);
    changeWidthDevice(value ? "100%" : "360px");
  };

  const makePublic = async () => {
    let res = await makePublicServices(theme._id);
    if (res) {
      alert("berhasil");
    }
  };

  const changeCSSCB = (value) => {
    let lp = { ...landingpage };
    lp.custom_css_code = value;
    setLandingpage(lp);
  };

  return (
    <>
      {showModalDelete && (
        <Modal
          show={showModalDelete}
          onHide={() => setShowModalDelete(false)}
          style={{ marginTop: "100px" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Hapus Tema</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center" style={{ padding: "60px 50px" }}>
              <h3>Yakin Hapus Tema</h3>
              <Button
                className="ml-3 mr-3 mt-4"
                onClick={() => setShowModalDelete(false)}
              >
                Batal
              </Button>
              <Button className="ml-3 mr-3 mt-4" onClick={() => removeTheme()}>
                Hapus
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ width: "1000px !important" }}
        className="mt-5"
      >
        <Modal.Header>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ flex: 1 }}>Simpan sebagai tema pribadi</div>
            <div
              style={{ width: "50px", marginRight: "-2%" }}
              onClick={() => {
                setShowModal(false);
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{ fontSize: "27px", cursor: "pointer" }}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {errorMessageTheme?.trim().length > 0 && (
            <Alert variant="danger">{errorMessageTheme}</Alert>
          )}

          <div style={{ maxHeight: "350px", overflow: "scroll" }}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              {theme.image && (
                <img
                  src={theme.image}
                  height="100px"
                  style={{ marginBottom: "10px" }}
                />
              )}
              <Form.Control
                type="file"
                onChange={($e) => {
                  uploadSelectedFile($e);
                }}
              />
              {isUploading && (
                <div style={{ width: "200px" }}>
                  <LoaderComp></LoaderComp>
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
              <Form.Label>Nama Tema</Form.Label>
              <Form.Control
                type="text"
                value={theme.name}
                onChange={($e) => changeData("name", $e.target.value)}
                placeholder="Nama tema"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Kategori</Form.Label>
              <Form.Control
                as="select"
                className="form-control"
                onChange={($e) => changeData("category", $e.target.value)}
              >
                {listCategory &&
                  listCategory.map((cat) => {
                    return (
                      cat._id && (
                        <option
                          value={cat._id}
                          selected={theme.category === cat._id}
                        >
                          {cat.name}
                        </option>
                      )
                    );
                  })}
              </Form.Control>
            </Form.Group>
            <InputComp
              type="number"
              placeholder="Masukkan Rating"
              label="Rating"
              value={theme?.rating !== undefined ? theme.rating : 5}
              onChange={(value) => {
                changeData("rating", value);
              }}
              className="form-control-white"
            />
            <InputComp
              type="number"
              placeholder="Masukkan harga"
              label="Harga"
              value={theme?.price !== undefined ? theme.price : ""}
              onChange={(value) => {
                changeData("price", value);
              }}
              className="form-control-white"
            />
            <InputComp
              type="number"
              placeholder="Masukkan discount"
              label="Discount"
              value={theme?.discount !== undefined ? theme.discount : ""}
              onChange={(value) => {
                changeData("discount", value);
              }}
              className="form-control-white"
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          {theme._id !== "" && (
            <Button
              onClick={() => copyjsonCB("delete")}
              style={{ background: "red" }}
            >
              Hapus Tema
            </Button>
          )}
          <Button disabled={disableTheme} onClick={() => saveTheme()}>
            {theme._id !== ""
              ? theme?.app_id?.toString() === "63cd0c7254920f655d6052fc" &&
                user?.app_id?.toString() !== "63cd0c7254920f655d6052fc"
                ? "Tambah"
                : "Simpan"
              : "Tambah "}
          </Button>
        </Modal.Footer>
      </Modal>
      <NavbarSettingComp
        isResponsive={isResponsive}
        changeWidthDevice={changeWidthDevice}
        domain={domain}
        landingpage={landingpage}
        submit={submit}
        openMusicView={openMusicView}
        changeColType={changeColType}
        isSaving={isSaving}
        copyjson={copyjsonCB}
        theme={theme}
        userAppId={user.app_id}
        undoCB={undoFunc}
        redoCB={redoFunc}
        device={device}
        widthDevice={widthDevice}
        changeCBResponsive={changeCBResponsive}
        makePublic={makePublic}
        isAbleSaveTheme={isAbleSaveTheme}
      ></NavbarSettingComp>
      <div style={{ display: "flex" }}>
        <div
          id="sidemenu"
          style={{ width: device === LARGEDEVICE ? leftSidebarWidth : "0px" }}
        >
          <div
            style={{
              height: device === LARGEDEVICE ? MEDIALARGE : windowHeight - 110,
              background: Colors.dark2,
              color: "#fff",
              position: "fixed",
              top: "60px",
              zIndex: "999999",
              overflow: "hidden",
            }}
          >
            {showLeftSidemenu && (
              <div
                id="sidemenu-arrow"
                style={{
                  width: "30px",
                  height: "30px",
                  background: "#343536",
                  position: "fixed",
                  left: device === LARGEDEVICE ? "331px" : "280px",
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
                onClick={() => setShowLeftSidebar(false)}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
            <div style={{ display: "flex" }}>
              {device === LARGEDEVICE && (
                <>
                  <div
                    style={{
                      width: "70px",
                      height: "100%",
                      position: "fixed",
                      background: "#18191B",
                    }}
                  >
                    <ul
                      style={{
                        listStyle: "none",
                        padding: "0",
                        margin: "0",
                        textAlign: "center",
                      }}
                    >
                      {listMenu &&
                        listMenu.map((menu, index) => {
                          return (
                            <li
                              style={{
                                overflow: "hidden",
                                background: Colors.dark2,
                                cursor: "pointer",
                                color: "#fff",
                                width: "100%",
                                listStyle: "none",
                                margin: "0",
                              }}
                              onClick={() => {
                                setViewActive(menu.name);
                                setViewActiveIndex(menu.index);
                                setShowLeftSidebar(true);
                                setEditorMode(false);
                              }}
                            >
                              <div
                                style={{
                                  padding: "10px",
                                  height: "55px",
                                  background:
                                    viewActive === menu.name
                                      ? Colors.dark2
                                      : Colors.dark,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={menu.icon}
                                  color={
                                    viewActive === menu.name ? "#fff" : "#fff"
                                  }
                                  style={{ fontSize: "14px", marginTop: "0px" }}
                                />
                                <div
                                  style={{
                                    color:
                                      viewActive === menu.name
                                        ? "#fff"
                                        : "#fff",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "9px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    {menu.name}
                                  </p>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  height: "10px",
                                  background: Colors.dark,
                                  borderTopRightRadius:
                                    index === viewActiveIndex ? "20px" : "0px",
                                }}
                              ></div>
                              <div
                                style={{
                                  width: "100%",
                                  height: "10px",
                                  background:
                                    index === viewActiveIndex - 1
                                      ? Colors.dark
                                      : Colors.dark,
                                  borderBottomRightRadius:
                                    index === viewActiveIndex - 1
                                      ? "20px"
                                      : "0px",
                                }}
                              ></div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </>
              )}
              {showLeftSidemenu && (
                <div
                  id="sidemenu-inner"
                  style={{
                    flex: 1,
                    paddingLeft: device === LARGEDEVICE ? "70px" : "0px",
                    width: device === LARGEDEVICE ? "331px" : "280px",
                  }}
                >
                  {editorMode === true ? (
                    <div
                      style={{ height: windowHeight - 50, overflowY: "scroll" }}
                    >
                      {colType === viewState.viewText && showContentMenu && (
                        <TextView
                          data={currentCol}
                          ViewCB={ViewCB}
                          fontCB={fontCB}
                          widthDevice={widthDevice}
                        ></TextView>
                      )}
                      {colType === viewState.viewImage && showContentMenu && (
                        <ImageView
                          data={currentCol}
                          ViewCB={ViewCB}
                          widthDevice={widthDevice}
                        ></ImageView>
                      )}
                      {colType === viewState.viewImageSlider &&
                        showContentMenu && (
                          <ImageSliderView
                            data={currentCol}
                            ViewCB={ViewCB}
                            widthDevice={widthDevice}
                          ></ImageSliderView>
                        )}
                      {colType === viewState.viewImageGallery &&
                        showContentMenu && (
                          <ImageGalleryView
                            data={currentCol}
                            ViewCB={ViewCB}
                            widthDevice={widthDevice}
                          ></ImageGalleryView>
                        )}
                      {colType === viewState.viewCarousel &&
                        showContentMenu && (
                          <CarouselView
                            data={currentCol}
                            ViewCB={ViewCB}
                          ></CarouselView>
                        )}
                      {colType === viewState.viewButton && showContentMenu && (
                        <ButtonView
                          data={currentCol}
                          ViewCB={ViewCB}
                          fontCB={fontCB}
                          widthDevice={widthDevice}
                        >
                          {" "}
                        </ButtonView>
                      )}
                      {colType === viewState.viewVideo && showContentMenu && (
                        <VideoView
                          data={currentCol}
                          ViewCB={ViewCB}
                          widthDevice={widthDevice}
                        ></VideoView>
                      )}
                      {colType === viewState.viewForm && showContentMenu && (
                        <FormView
                          data={currentCol}
                          ViewCB={ViewCB}
                          fontCB={fontCB}
                          widthDevice={widthDevice}
                        ></FormView>
                      )}
                      {colType === viewState.viewCode && showContentMenu && (
                        <HTMLView data={currentCol} ViewCB={ViewCB}></HTMLView>
                      )}
                      {colType === viewState.viewIcon && showContentMenu && (
                        <IconView
                          data={currentCol}
                          ViewCB={ViewCB}
                          widthDevice={widthDevice}
                        ></IconView>
                      )}
                      {colType === viewState.viewList && showContentMenu && (
                        <ListView
                          data={currentCol}
                          ViewCB={ViewCB}
                          widthDevice={widthDevice}
                        ></ListView>
                      )}
                      {colType === viewState.viewMap && showContentMenu && (
                        <MapView
                          data={currentCol}
                          ViewCB={ViewCB}
                          widthDevice={widthDevice}
                        ></MapView>
                      )}
                      {colType === viewState.viewSpace && showContentMenu && (
                        <SpaceView
                          data={currentCol}
                          ViewCB={ViewCB}
                          widthDevice={widthDevice}
                        ></SpaceView>
                      )}
                      {colType === viewState.viewLine && showContentMenu && (
                        <LineView
                          data={currentCol}
                          ViewCB={ViewCB}
                          widthDevice={widthDevice}
                        ></LineView>
                      )}
                      {colType === viewState.viewNavbar && showContentMenu && (
                        <viewNavbar
                          data={currentCol}
                          ViewCB={ViewCB}
                        ></viewNavbar>
                      )}
                      {colType === viewState.viewCarousel &&
                        showContentMenu && (
                          <viewCarousel
                            data={currentCol}
                            ViewCB={ViewCB}
                          ></viewCarousel>
                        )}
                      {colType === viewState.viewTimeline &&
                        showContentMenu && (
                          <TimelineView
                            data={currentCol}
                            ViewCB={ViewCB}
                            widthDevice={widthDevice}
                          ></TimelineView>
                        )}
                      {colType === viewState.viewBank && showContentMenu && (
                        <BankView
                          data={currentCol}
                          ViewCB={ViewCB}
                          widthDevice={widthDevice}
                        ></BankView>
                      )}
                      {colType === viewState.viewGift && showContentMenu && (
                        <GiftView
                          data={currentCol}
                          ViewCB={ViewCB}
                          widthDevice={widthDevice}
                        ></GiftView>
                      )}
                      {colType === viewState.viewDevider && showContentMenu && (
                        <DeviderView
                          data={currentCol}
                          ViewCB={ViewCB}
                        ></DeviderView>
                      )}
                      {colType === viewState.viewCountdown &&
                        showContentMenu && (
                          <CountDownView
                            data={currentCol}
                            ViewCB={ViewCB}
                            widthDevice={widthDevice}
                          ></CountDownView>
                        )}
                      {colType === viewState.viewRow && currentRow && (
                        <RowView
                          data={currentRow}
                          changeRowData={CBRowData}
                          widthDevice={widthDevice}
                          colomCBParent={colomCBParent}
                          colomRemoveCBParent={colomRemoveCBParent}
                          isResponsive={isResponsive}
                        ></RowView>
                      )}
                      {colType === viewState.viewCol && currentColom && (
                        <ColomView
                          data={currentColom}
                          changeColomData={CBColomData}
                          widthDevice={widthDevice}
                          isResponsive={isResponsive}
                        ></ColomView>
                      )}
                    </div>
                  ) : (
                    <>
                      {viewActive === "Komponen" && (
                        <>
                          <ComponentView
                            viewActive={viewActive}
                            ViewCB={GeneralCB}
                            list={listDragMenu}
                            listCategory={listCategory}
                            drag={drag}
                            mobileMoveAction={mobileMoveAction}
                            addItemMobile={(id) => addItem(id, true)}
                          ></ComponentView>
                        </>
                      )}
                      {viewActive === "css_editor" && (
                        <div
                          style={{
                            height: windowHeight - 50,
                            overflowY: "scroll",
                          }}
                        >
                          <CSSEditorComp
                            custom_css_code={landingpage?.custom_css_code}
                            changeCSSCB={changeCSSCB}
                          ></CSSEditorComp>
                        </div>
                      )}
                      {viewActive === "Musik" && (
                        <>
                          <MusicView
                            ViewCB={GeneralCB}
                            list={[]}
                            listCategory={listCategory}
                            device={device}
                          ></MusicView>
                        </>
                      )}

                      {viewActive === "Plugin" && (
                        <>
                          <PluginView
                            openPluginCB={openPluginCB}
                            idLandingpage={
                              landingpage._id ? landingpage._id : undefined
                            }
                            plugins={plugins}
                            loading={viewPluginLoading}
                          ></PluginView>
                        </>
                      )}
                      {viewActive === "Gambar" && (
                        <>
                          <ul
                            style={{
                              breakInside: "avoid",
                              padding: 0,
                              margin: 0,
                              listStyle: "none",
                              padding: 0,
                              margin: 0,
                              textAlign: "center",
                              height: "560px",
                              background: "#343536",
                            }}
                          >
                            {listImage &&
                              listImage.map((menu, index) => {
                                var key = index + 1;
                                return (
                                  <li
                                    index={key}
                                    draggable={true}
                                    onDragStart={($e) =>
                                      drag($e, viewState.viewImage, menu.image)
                                    }
                                    ontouchstart={($e) => pickup($e)}
                                    ontouchmove={($e) =>
                                      drag($e, viewState.viewImage, menu.image)
                                    }
                                    style={{
                                      color: "#fff",
                                      cursor: "pointer",
                                      border: "solid 5px #333",
                                      height: "80px",
                                      overflow: "hidden",
                                      margin: "0px",
                                      padding: "0px",
                                      width: "130px",
                                      float: "left",
                                    }}
                                  >
                                    <div className="diableSelection">
                                      <OverlayTrigger
                                        key="left"
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Tooltip id={`tooltip-left`}>
                                            {menu.name}
                                          </Tooltip>
                                        }
                                      >
                                        <div
                                          style={{
                                            marginTop: "10px",
                                            background: "#fff",
                                          }}
                                        >
                                          <img src={menu.image} width="70%" />
                                        </div>
                                      </OverlayTrigger>
                                    </div>
                                    {/* <p style={{ color: '#fff', marginTop: '6px', fontSize: '12px' }}>{menu.name}</p> */}
                                  </li>
                                );
                              })}
                          </ul>
                        </>
                      )}
                      {viewActive === "Upload" && (
                        <>
                          <MediaView
                            viewActive={viewActive}
                            ViewCB={GeneralCB}
                            list={[]}
                            listCategory={listCategory}
                            drag={drag}
                            pickup={pickup}
                            addItemMobile={(id) => addItem(id, true)}
                          ></MediaView>
                        </>
                      )}
                      {viewActive === "Element" && (
                        <>
                          <ElementView
                            viewActive={viewActive}
                            ViewCB={GeneralCB}
                            list={[]}
                            listCategory={listCategory}
                            drag={drag}
                            pickup={pickup}
                            mobileMoveAction={mobileMoveAction}
                            addItemMobile={(id) => addItem(id, true)}
                          ></ElementView>
                        </>
                      )}
                      {viewActive === "Snippet" && (
                        <>
                          <SnippetView
                            viewActive={viewActive}
                            ViewCB={GeneralCB}
                            list={[]}
                            listCategory={listCategory}
                            drag={drag}
                            pickup={pickup}
                            mobileMoveAction={mobileMoveAction}
                            addItemMobile={(id) => addItem(id, true)}
                          ></SnippetView>
                        </>
                      )}
                      {viewActive === "Tema" && (
                        <>
                          <ThemeView
                            ViewCB={GeneralCB}
                            listThemes={listThemes}
                            listCategory={listCategory}
                            applyTheme={applyThemeCB}
                            device={device}
                          ></ThemeView>
                        </>
                      )}

                      {viewActive === "Pengaturan" && (
                        <div
                          style={{
                            height: windowHeight - 50,
                            overflowY: "scroll",
                          }}
                        >
                          <SettingComp
                            slug={slug}
                            landingpage={landingpage}
                            changeType={changeType}
                            changeLandingpageTitle={changeLandingpageTitle}
                            submit={submit}
                            domain={domain}
                            device={device}
                            thumbnailFormData={thumbnailFD}
                          ></SettingComp>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {!showCanvas && (
          <div style={{ margin: "120px auto" }}>
            <LoaderComp></LoaderComp>
          </div>
        )}
        {showCanvas && (
          <>
            <div
              id="canvas"
              style={{ [widthKey]: device === SMARTPHONE ? "100%" : "1" }}
            >
              <DragDropContext
                onDragEnd={(param) => {
                  const srcI = param.source.index;
                  // const desI = param.destination?.index;
                }}
              >
                <Droppable droppableId="menu" type="menu">
                  {(provided, _) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {fontFamilyList.map((font) => {
                        return (
                          <ReactFontLoader
                            url={`https://fonts.googleapis.com/css2?family=${font.font}:wght@400;700&display=swap`}
                          />
                        );
                      })}

                      <RowModalComp
                        numberOfCol={numberOfCol}
                        showWayToAdd={showWayToAdd}
                        handleCloseWayToAdd={handleCloseWayToAdd}
                        changeNumOfCol={changeNumOfCol}
                        ColCB={ColCB}
                      ></RowModalComp>
                      {loading ? (
                        <Loader></Loader>
                      ) : error ? (
                        <Message variant="danger">{error}</Message>
                      ) : (
                        <>
                          {generalConfiguration &&
                            generalConfiguration.hasMusic && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    background: "#fff",
                                    height: "50px",
                                    alignItems: "center",
                                    marginBottom: "-24px",
                                    marginTop: "80px",
                                  }}
                                >
                                  <div style={{ width: "290px" }}>
                                    <ReactAudioPlayer
                                      src={contentMusic.music}
                                      autoPlay
                                      controls
                                    />
                                  </div>
                                  <div
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      color: "#333",
                                      paddingTop: "13px",
                                      paddingLeft: "15px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => removeMusic()}
                                  >
                                    <i
                                      className="fas fa-times pb-1"
                                      style={{ fontSize: "20px" }}
                                    ></i>
                                  </div>
                                </div>
                              </>
                            )}
                          <div
                            onDragOver={(event) => {
                              allowDrop(event);
                            }}
                            onDrop={($e) => {
                              dropNow($e);
                            }}
                          >
                            <DragDropContext
                              onDragEnd={(param) => {
                                //row change position
                                const srcI = param.source.index;
                                const desI = param.destination?.index;

                                if (desI !== undefined) {
                                  var selectedLpJson = { ...lpJson };
                                  selectedLpJson.row.splice(
                                    desI,
                                    0,
                                    selectedLpJson.row.splice(srcI, 1)[0]
                                  );
                                  selectedLpJson.row[desI].position = "static";
                                  selectedLpJson.row[desI].fixedTo = "";
                                  selectedLpJson.row[desI].index = desI;
                                  selectedLpJson.row[srcI].position = "static";
                                  selectedLpJson.row[srcI].fixedTo = "";
                                  selectedLpJson.row[srcI].index = srcI;
                                  setLPJson(selectedLpJson);
                                }
                              }}
                            >
                              <div
                                style={{
                                  width: widthDevice,
                                  margin: "0px auto",
                                  position: "relative",
                                  marginTop: "70px",
                                  paddingBottom:
                                    device === SMARTPHONE ? "110px" : "0px",
                                  overflow:
                                    device === LARGEDEVICE ? "auto" : "scroll",
                                  height:
                                    device === SMARTPHONE
                                      ? windowHeight
                                      : "auto",
                                }}
                                id="style-1"
                              >
                                <Droppable droppableId="row" type="row">
                                  {(provided, _) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {lpJson?.row.map((c, index) => {
                                        c.index = index;
                                        return (
                                          <Draggable
                                            key={index}
                                            draggableId={"draggable-" + index}
                                            index={index}
                                          >
                                            {(provided, snapshot) => (
                                              <LandingpageRow
                                                viewState={viewState}
                                                row={c}
                                                index={index}
                                                removeContent={removeContentCB}
                                                clickSetting={changeRow}
                                                clickSettingCol={changeCol}
                                                colomCBParent={colomCBParent}
                                                colomRemoveCBParent={
                                                  colomRemoveCBParent
                                                }
                                                removeRowParent={
                                                  removeRowParent
                                                }
                                                provided={provided}
                                                snapshot={snapshot}
                                                ref={provided.innerRef}
                                                pushRow={pushRow}
                                                widthDev={widthDevice}
                                                newContent={newContent}
                                                changeColCB={changeColCB}
                                                changeColomOrderCB={
                                                  changeColomOrderCB
                                                }
                                                windowWidth={windowWidth}
                                                openElement={openElement}
                                              ></LandingpageRow>
                                            )}
                                          </Draggable>
                                        );
                                      })}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </div>
                            </DragDropContext>
                          </div>
                          <div style={{ marginTop: "20px" }}></div>
                          {device === LARGEDEVICE && (
                            <div
                              className="text-center"
                              onClick={handleShowWayToAdd}
                            >
                              <BlankView
                                height={
                                  lpJson && lpJson.row.length > 0 ? 70 : 100
                                }
                              ></BlankView>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </>
        )}
      </div>

      {device === SMARTPHONE && (
        <>
          <div
            style={{
              position: "fixed",
              bottom: "60px",
              right: "10px",
              background: "red",
              width: "37px",
              height: "37px",
              background: "#F3B773",
              borderRadius: "30px",
              textAlign: "center",
              fontSize: "22px",
              paddingTop: "1px",
            }}
            onClick={handleShowWayToAdd}
          >
            <FontAwesomeIcon icon={faPlus} color="#fff" />
          </div>
          <div
            style={{
              position: "fixed",
              width: "100vw",
              height: menuMobileHeight,
              color: "#fff",
              bottom: "0px",
              left: "0px",
              zIndex: 1111,
            }}
          >
            <div
              style={{
                display: "flex",
                borderRight: "solid 2px #252627",
                background: "#18191B",
                boxShadow: "2px 2px 2px #333",
                width: "100vw",
                textAlign: "center",
                overflowX: "scroll",
              }}
            >
              {listMenu &&
                listMenu.map((menu) => {
                  return (
                    <>
                      {menu.showMobile && (
                        <div
                          style={{
                            flex: 1,
                            overflow: "hidden",
                            background:
                              viewActive === menu.name ? "#000" : "#18191B",
                            cursor: "pointer",
                            color: "#fff",
                            width: "100%",
                            borderBottom:
                              viewActive === menu.name
                                ? "solid 2px #18191B"
                                : "solid 2px #000",
                            listStyle: "none",
                            padding: "3px",
                            margin: "0",
                            paddingTop: "5px",
                          }}
                          onClick={() => {
                            setViewActive(menu.name);
                            setViewActiveIndex(menu.index);
                            setShowLeftSidebar(true);
                            setEditorMode(false);
                          }}
                        >
                          <div>
                            <FontAwesomeIcon
                              icon={menu.icon}
                              color={viewActive === menu.name ? "#fff" : "#fff"}
                              style={{ fontSize: "14px", marginTop: "2px" }}
                            />
                            <div
                              style={{
                                color:
                                  viewActive === menu.name ? "#fff" : "#fff",
                              }}
                            >
                              <p style={{ fontSize: "9px", marginTop: "0px" }}>
                                {menu.name}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WebsiteEditorPage;
