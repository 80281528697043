import React from "react";
import { Link } from "react-router-dom";
import momentService from "../../../helpers/momentHelper";
import { LARGEDEVICE, SMARTPHONE } from "../../../variable";

const columns = (query, device) => {
  return [
    {
      name: "",
      cell: (row, index) => (query.page - 1) * query.pageSize + index + 1,
      width: device === SMARTPHONE ? "10%" : "60px",
    },
    {
      name: "status",
      cell: (row) => row.status,
      width: "150px",
    },
    {
      name: "midtrans",
      cell: (row) => row.midtrans,
      width: "150px",
    },
    {
      name: "type",
      cell: (row) => row.type,
      width: "150px",
    },
    {
      name: "nominal",
      cell: (row) => row.nominal,
      width: "150px",
    },
    {
      name: "Tanggal",
      cell: (row) => momentService(row.updatedAt),
      width: "150px",
    },
  ];
};

export default columns;
