import React, { useState, useEffect } from "react";
import TabView from "../TabView";
import { upload } from "../../services/uploadServices";
import ScrollMenuY from "../ScrollMenuY";
import LoaderComp from "../LoaderComp";
import {
  createMedia,
  deleteMedia,
  getMedia,
} from "../../services/mediaServices";
import Colors from "../../Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import SweetAlert2 from "react-sweetalert2";
import InputUploadComp from "../form/Input/InputUploadComp";
import convertToWebP from "../../helpers/webPHelper";
const MediaView = ({
  viewActive,
  list,
  drag,
  pickup,
  ViewCB,
  mobileMoveAction,
  addItemMobile,
}) => {
  const [swalProps, setSwalProps] = useState({});
  const [media, setMedia] = useState(list);
  const [selectedMenu, setSelectedMenu] = useState("image");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [menus, setMenus] = useState(["image", "music"]);
  const [showLoader, setShowLoader] = useState(true);
  const [disableScroll, setDisableScroll] = useState(false);
  const [loadNewData, setLoadNewData] = useState(false);
  const [colData, setColData] = useState("");
  const [showFormUpload, setShowFormUpload] = useState(false);
  const delay = 1;
  const [selectedId, setSelectedId] = useState("");
  let timer1;

  useEffect(() => {
    if (colData && colData.music !== "") {
      ViewCB(colData);
    }
  }, [colData]);

  const uploadSelectedFile = async (event) => {
    try {
      setShowLoader(true);
      const data = new FormData();
      data.append("file", event.target.files[0]);
      await upload(data)
        .then((res) => {
          // chooseMusic(res.data.files[0])
          let newFile = {
            name: res.data.files[0],
            link: res.data.files[0],
            keyword: "wedding",
            type: selectedMenu.toLowerCase(),
          };

          if (selectedMenu.toLowerCase() === "image") {
            let webp = convertToWebP(event.target.files);
            let webpObj = {
              link_webp: webp[0] ? webp[0] : "",
            };
            newFile = { ...newFile, ...webpObj };
          }
          createMedia(newFile).then((res) => {
            let temp = [...media, newFile];
            setMedia(temp);
            setShowLoader(false);
          });
        })
        .catch((err) => {});
    } catch {
      alert("File tidak compatible");
      setShowLoader(false);
    }
  };

  const chooseMusic = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        music: value,
      };
    });
  };

  const filterCat = async (newKey) => {
    let keynya = selectedMenu;
    if (newKey) {
      keynya = newKey;
    }
    setDisableScroll(false);
    setShowLoader(true);
    setMedia([]);
    try {
      let res = await getMedia(1, pageSize, keynya, "private");
      setMedia(res.data.mediaList);
      setPage(1);
      setShowLoader(false);
    } catch (err) {
      setMedia([]);
      setShowLoader(false);
    }
  };

  const changeThemeStatus = (k) => {
    setShowFormUpload(false);
    setMedia([]);
    setSelectedMenu(k);
    if (k === menus[1].toLowerCase()) {
      filterCat("music");
    }
    if (k === menus[0].toLowerCase()) {
      filterCat("image");
    }
  };

  const scrollActionYCB = async (pageResult) => {
    clearTimeout(timer1);
    timer1 = setTimeout(() => {
      if (!disableScroll) {
        setShowLoader(true);
        if (pageResult === page + 1) {
          setLoadNewData(pageResult);
        }
      }
    }, delay * 700);
  };

  useEffect(() => {
    if (loadNewData) {
      setPage(page + 1);
    }
  }, [loadNewData]);

  useEffect(() => {
    if (page > 1) {
      fetchNewData();
    }
  }, [page]);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      const { signal } = abortController;
      try {
        let files = await getMedia(page, pageSize, "image", "private", "", {
          signal,
        });
        setMedia(files.data.mediaList);
        setShowLoader(false);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();

    return () => {
      abortController.abort(); // Cleanup function to abort the fetch if the component unmounts
    };
  }, []); // Empty dependency array means this effect runs only once

  const fetchNewData = async () => {
    const abortController = new AbortController();
    const fetchData = async () => {
      const { signal } = abortController;
      try {
        let result = await getMedia(page, pageSize, selectedMenu, "private", {
          signal,
        });
        if (result) {
          if (result.data.mediaList.length > 0) {
            let prevThemes = [...media, ...result.data.mediaList];
            setMedia(prevThemes);
          } else {
            setDisableScroll(true);
          }
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();

    return () => {
      abortController.abort(); // Cleanup function to abort the fetch if the component unmounts
    };
  };

  const filterMusicName = (val) => {
    let key = "https://d1jcw7e5n1q2pt.cloudfront.net/";
    let key2 = "https://file.idinvitebook.com/";
    if (val.includes(key) || val.includes(key2)) {
      let filter = val.replace(key, "");
      filter = val.replace(key2, "");
      let newArray = filter.split("-");
      newArray.shift();
      filter = newArray.join(" ");
      filter = filter.replace(/%20/g, " ");
      filter = filter.replace(/.mp3/g, " ");
      return filter.toLowerCase();
    } else {
      return val.toLowerCase();
    }

    // {myMusic.name.substring(0, 50)}
  };

  const deleteMediaFunc = async () => {
    let res = await deleteMedia(selectedId);
    if (res) {
      clearSwal();
      filterCat(selectedMenu.toLowerCase());
    }
  };

  const clearSwal = () => {
    setSelectedId("");
    setSwalProps((prev) => {
      return {
        ...prev,
        show: false,
      };
    });
  };
  return (
    <>
      <TabView
        menus={menus}
        active={selectedMenu}
        actionTab={changeThemeStatus}
      ></TabView>
      <InputUploadComp
        label=""
        name="input2[]"
        onChange={($e) => {
          uploadSelectedFile($e);
        }}
      />
      {showLoader && <LoaderComp></LoaderComp>}

      <div style={{ paddingLeft: "5px" }}>
        <ScrollMenuY
          id="mediaView"
          scrollActionCB={scrollActionYCB}
          page={page}
          iHeight="200"
        >
          {media.length === 0 &&
            selectedMenu === menus[0].toLowerCase() &&
            !showLoader && (
              <p className="mt-3">
                Maaf belum ada {selectedMenu} yang terupload{" "}
              </p>
            )}
          {media.length === 0 &&
            selectedMenu === menus[1].toLowerCase() &&
            !showLoader && (
              <p className="mt-3">
                Anda belum memiliki {selectedMenu} yang terupload
              </p>
            )}
          {media &&
            selectedMenu.toLowerCase() === "image" &&
            media.map((image) => {
              return (
                <div
                  className="text-center"
                  style={{
                    height: "100px",
                    width: "48%",
                    float: "left",
                    marginBottom: "5px",
                    marginLeft: "1%",
                    marginRight: "1%",
                    overflow: "hidden",
                    cursor: "pointer",
                    position: "relative",
                  }}
                  // onClick={(() => applyTheme(theme))}
                  draggable={true}
                  onDragStart={($e) => {
                    drag($e, "Image", image.link);
                  }}
                  onTouchStart={($e) => {
                    drag($e, "Image", image.link);
                  }}
                  onTouchMove={($e) => {
                    const x = $e.changedTouches[0].clientX;
                    if (parseInt(x) > 350) {
                      mobileMoveAction();
                    }
                  }}
                  onTouchEnd={($e) => {
                    $e.preventDefault();
                    const x = $e.changedTouches[0].clientX;
                    const y = $e.changedTouches[0].clientY;
                    let elem = document.elementFromPoint(x, y);
                    let idElement = elem.getAttribute("id");
                    addItemMobile(idElement);
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 4,
                      right: 0,
                      background: "#fff",
                      width: "30px",
                      height: "25px",
                      borderRadius: "5px",
                      paddingTop: "2px",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      color={Colors.dark}
                      onClick={() => {
                        setSelectedId(image._id);
                        setSwalProps({
                          show: true,
                          title: `Hapus ${filterMusicName(image.link)}`,
                          text: "Yajin Hapus",
                        });
                      }}
                    />
                  </div>
                  <img
                    src={image.link}
                    style={{ width: "100%", overflow: "hidden", float: "left" }}
                  />
                </div>
              );
            })}

          {media &&
            selectedMenu.toLowerCase() === "music" &&
            media.map((myMusic, index) => {
              return (
                <div
                  className="text-center"
                  style={{
                    width: "100%",
                    float: "left",
                    minHeight: "61px",
                    maxHeight: "60px",
                    overflow: "hidden !important",
                  }}
                >
                  <div
                    onClick={() => chooseMusic(myMusic.link)}
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      minHeight: "60px",
                      background: Colors.dark2,
                      border: "solid 1px #18191B",
                      overflow: "hidden",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: "50px" }}>
                      <div
                        style={{
                          background: Colors.dark,
                          borderRadius: "100px",
                          width: "40px",
                          height: "40px",
                          paddingTop: "3px",
                          border: "solid 1px #000",
                        }}
                      >
                        <i
                          className="fas fa-music pt-2 fa-2x"
                          style={{ fontSize: "14px" }}
                        ></i>
                      </div>
                    </div>
                    <div style={{ width: "460px", overflow: "hidden" }}>
                      <p
                        className={`mt-2 ${
                          filterMusicName(myMusic.name).length > 100
                            ? "labelContainer"
                            : ""
                        }`}
                        style={{
                          textAlign: "left",
                          color: "#fff",
                          fontSize: "13px",
                          paddingTop: "2px",
                          padding: "5px 6px",
                        }}
                      >
                        <span>{filterMusicName(myMusic.name)}</span>
                      </p>
                    </div>
                    <div style={{ width: "130px", paddingTop: "10px" }}>
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => {
                          setSelectedId(myMusic._id);
                          setSwalProps({
                            show: true,
                            title: `Hapus ${filterMusicName(myMusic.name)}`,
                            text: "Yajin Hapus",
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
        </ScrollMenuY>
        <SweetAlert2
          {...swalProps}
          onConfirm={deleteMediaFunc}
          didClose={() => {
            clearSwal();
          }}
        />
      </div>
    </>
  );
};

export default MediaView;
