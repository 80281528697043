import React, { useEffect, useState } from "react";
import { Form, Row, Tabs, Tab } from "react-bootstrap";
import RangeSlider from "../form/Rangeslider";
import labelCSS from "../../helpers/labelCss";
import { ColorPicker, useColor } from "react-color-palette";
import TextAlign from "../form/TextAlign";
import ColorComp from "../form/ColorComp";
import GoogleFontsComp from "../form/GoogleFontsComp";
import AnimationComp from "../form/AnimationComp";
import BorderComp from "../form/BorderComp";
import {
  MEDIALARGE,
  MEDIAMEDIUM,
  MEDIAQUERY,
  MEDIASMALL,
  SIZEMEDIUM,
  SIZESMALL,
} from "../../variable";
import mediaQuery, {
  clearBorderRadius,
  isAdvancedRadius,
} from "../../helpers/mediaQueryHelper";
import BorderRadiusComp from "../form/BorderRadiusComp";
import ButtonSpaceComp from "../ButtonSpaceComp";
import InputComp from "../form/InputComp";

const TextView = ({ data, ViewCB, fontCB, widthDevice }) => {
  if (data.position === undefined) {
    data.position = "static";
  }
  if (data.zIndex === undefined) {
    data.zIndex = 1;
  }
  const [disableByResponsive, setDisableByResponsive] = useState(
    widthDevice === MEDIALARGE ? false : true
  );
  const [isBorderRadiusAdvanced, setIsBorderRadiusAdvanced] = useState(
    isAdvancedRadius(data)
  );
  const delay = 1;
  let desc;
  const [key, setKey] = useState("main");
  const listWritingMode = ["unset", "vertical-lr"];
  const [defaultBGColor, setDefaultBGColor] = useState("#000000");
  const [color, setColor] = useColor("rgb", "98, 142, 48");
  const [isReady, setIsReady] = useState(false);
  const [colData, setColData] = useState(data);
  const [widthDevice2, setWidthDevice2] = useState(widthDevice);
  const [masterData, setMasterData] = useState(data);

  const cbValue = (data, isGlobal) => {
    let key = labelCSS(data.key);
    let value = data.value;
    let extension = key === "width" ? "%" : "px";
    if (
      key === "textAlign" ||
      key === "fontFamily" ||
      key === "customClassName"
    ) {
      extension = "";
      if (key === "fontFamily") {
        fontCB(value);
      }
    }
    if (widthDevice === MEDIALARGE || isGlobal) {
      setColData((prev) => {
        return {
          ...prev,
          [key]: value + extension,
        };
      });
    } else if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
      let widthDeviceStr = SIZEMEDIUM;
      if (widthDevice === MEDIASMALL) {
        widthDeviceStr = SIZESMALL;
      }
      let temp = { ...colData };
      if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
        let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`];
        let newObject = {
          [key]: value + extension,
        };
        let newJoin = { ...tempNewObject, ...newObject };
        temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin;
        setColData(temp);
      } else {
        temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
          [key]: value + extension,
        };
        setColData(temp);
      }
    }
  };

  const setTags = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        tags: value,
      };
    });
  };

  const setFontWeight = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        fontWeight: value,
      };
    });
  };

  const changeColData = (key, value) => {
    setColData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const changeColor = (value) => {
    setColor(value);
    setColData((prev) => {
      return {
        ...prev,
        color: value.hex,
      };
    });
  };

  const changeBackgroundColor = (value) => {
    if (value.hex !== defaultBGColor) {
      setColor(value);
      setColData((prev) => {
        return {
          ...prev,
          isTransparent: false,
          backgroundColor: value.hex,
        };
      });
      setDefaultBGColor(value.hex);
    }
  };

  const boderRadiusAdvancedMode = (val) => {
    setIsBorderRadiusAdvanced(val);
    let resetRadius = clearBorderRadius(masterData);
    setColData(resetRadius);
  };

  useEffect(
    () => {
      let desc = setTimeout(() => {
        if (colData) {
          ViewCB(colData);
          setMasterData(colData);
        }
      }, delay * 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(desc);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    [colData]
  );

  const cbAnimation = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        animation: value,
      };
    });
  };

  const calculateSetting = async (widthDevice) => {
    let tempState = { ...data };
    tempState.marginTop = mediaQuery(
      tempState,
      "marginTop",
      tempState.marginTop,
      widthDevice
    );
    tempState.marginLeft = mediaQuery(
      tempState,
      "marginLeft",
      tempState.marginLeft,
      widthDevice
    );
    tempState.marginRight = mediaQuery(
      tempState,
      "marginRight",
      tempState.marginRight,
      widthDevice
    );
    tempState.marginBottom = mediaQuery(
      tempState,
      "marginBottom",
      tempState.marginBottom,
      widthDevice
    );
    tempState.paddingTop = mediaQuery(
      tempState,
      "paddingTop",
      tempState.paddingTop,
      widthDevice
    );
    tempState.paddingRight = mediaQuery(
      tempState,
      "paddingRight",
      tempState.paddingRight,
      widthDevice
    );
    tempState.paddingBottom = mediaQuery(
      tempState,
      "paddingBottom",
      tempState.paddingBottom,
      widthDevice
    );
    tempState.paddingLeft = mediaQuery(
      tempState,
      "paddingLeft",
      tempState.paddingLeft,
      widthDevice
    );
    if (isBorderRadiusAdvanced) {
      tempState.borderRadius = 0;
      tempState.borderTopLeftRadius = mediaQuery(
        tempState,
        "borderTopLeftRadius",
        tempState.borderTopLeftRadius,
        widthDevice
      );
      tempState.borderTopRightRadius = mediaQuery(
        tempState,
        "borderTopRightRadius",
        tempState.borderTopRightRadius,
        widthDevice
      );
      tempState.borderBottomLeftRadius = mediaQuery(
        tempState,
        "borderBottomLeftRadius",
        tempState.borderBottomLeftRadius,
        widthDevice
      );
      tempState.borderBottomRightRadius = mediaQuery(
        tempState,
        "borderBottomRightRadius",
        tempState.borderBottomRightRadius,
        widthDevice
      );
    } else {
      tempState.borderRadius = mediaQuery(
        tempState,
        "borderRadius",
        tempState.borderRadius,
        widthDevice
      );
      tempState.borderTopLeftRadius = 0;
      tempState.borderTopRightRadius = 0;
      tempState.borderBottomLeftRadius = 0;
      tempState.borderBottomRightRadius = 0;
    }
    tempState.backgroundColor = mediaQuery(
      tempState,
      "backgroundColor",
      tempState.backgroundColor,
      widthDevice
    );
    tempState.backgroundSize = mediaQuery(
      tempState,
      "backgroundSize",
      tempState.backgroundSize,
      widthDevice
    );
    tempState.border = mediaQuery(
      tempState,
      "border",
      tempState.border,
      widthDevice
    );
    tempState.borderColor = mediaQuery(
      tempState,
      "borderColor",
      tempState.borderColor,
      widthDevice
    );
    tempState.opacity = mediaQuery(
      tempState,
      "opacity",
      tempState.opacity,
      widthDevice
    );
    tempState.color = mediaQuery(
      tempState,
      "color",
      tempState.color,
      widthDevice
    );
    tempState.fontSize = mediaQuery(
      tempState,
      "fontSize",
      tempState.fontSize,
      widthDevice
    );
    tempState.lineHeight = mediaQuery(
      tempState,
      "lineHeight",
      tempState.lineHeight,
      widthDevice
    );
    tempState.tags = mediaQuery(tempState, "tags", tempState.tags, widthDevice);
    tempState.textAlign = mediaQuery(
      tempState,
      "textAlign",
      tempState.textAlign,
      widthDevice
    );
    tempState.customClassName = mediaQuery(
      tempState,
      "customClassName",
      tempState.customClassName,
      widthDevice
    );
    setMasterData(tempState);
    setTimeout(() => {
      setIsReady(true);
    }, 500);
  };

  useEffect(() => {
    setIsReady(false);
    setTimeout(() => {
      calculateSetting(widthDevice2);
    }, 500);
  }, [widthDevice2]);

  useEffect(() => {
    if (widthDevice !== widthDevice2) {
      setWidthDevice2(widthDevice);
      setDisableByResponsive(widthDevice === MEDIALARGE ? false : true);
    }
  }, [widthDevice]);
  const noColor = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        isTransparent: value,
      };
    });
  };

  const changeValueCB = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        borderType: value,
      };
    });
  };

  const changeBorder = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        border: value.value,
      };
    });
  };

  const changeBorderColor = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        isBorderTransparent: false,
        borderColor: value.hex,
      };
    });
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="main" title="main">
          {isReady && (
            <>
              <InputComp
                type="text"
                label="CSS Class"
                placeholder="Masukkan CSS Class"
                value={masterData.customClassName}
                isRequired={false}
                onChange={(val) => {
                  let key = "customClassName";
                  let newData = {
                    key,
                    value: val,
                  };
                  cbValue(newData, true);
                }}
              ></InputComp>
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>Text
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={colData.content}
                  onChange={($e) => {
                    changeColData("content", $e.target.value);
                  }}
                  className="form-control-dark"
                ></Form.Control>
              </Form.Group>
              <GoogleFontsComp
                value={colData.fontFamily}
                label="Font Family"
                cbValue={(data) => cbValue(data, true)}
              ></GoogleFontsComp>
              <a
                target="_blank"
                href="https://fonts.google.com/"
                style={{ color: "#fff" }}
              >
                {" "}
                <small>
                  Klik disini untuk lihat <u>referensi fonts</u>
                </small>
              </a>
              <RangeSlider
                value={masterData.fontSize}
                label="Font Size"
                cbValue={cbValue}
                maxvalue={100}
              ></RangeSlider>
              <TextAlign
                value={masterData.textAlign}
                label="Text Align"
                cbValue={cbValue}
              ></TextAlign>

              <InputComp
                isRequired={false}
                type="select"
                label="Writing Mode"
                value={
                  colData.writingMode ? colData.writingMode : "vertical-lr"
                }
                options={listWritingMode}
                onChange={(value) => changeColData("writingMode", value)}
              />
              <InputComp
                isRequired={false}
                type="text"
                label="Text Shadow"
                value={colData.textShadow ? colData.textShadow : ""}
                onChange={(value) => changeColData("textShadow", value)}
              />
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red", fontSize: "13px" }}>*</span>Font
                  Weight
                </Form.Label>
                <Form.Control
                  as="select"
                  onChange={($e) => setFontWeight($e.target.value)}
                  className="form-control-dark"
                >
                  <option
                    value="400"
                    selected={masterData.fontWeight === "400"}
                  >
                    400
                  </option>
                  <option
                    value="500"
                    selected={masterData.fontWeight === "500"}
                  >
                    500
                  </option>
                  <option
                    value="600"
                    selected={masterData.fontWeight === "600"}
                  >
                    600
                  </option>
                  <option
                    value="700"
                    selected={masterData.fontWeight === "700"}
                  >
                    700
                  </option>
                  <option
                    value="800"
                    selected={masterData.fontWeight === "800"}
                  >
                    800
                  </option>
                  <option
                    value="900"
                    selected={masterData.fontWeight === "900"}
                  >
                    900
                  </option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="name" className="mt-2">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span> Position
                </Form.Label>
                <Form.Control
                  as="select"
                  onChange={($e) => changeColData("position", $e.target.value)}
                  className="form-control-dark"
                >
                  <option
                    value="static"
                    selected={colData.position === "static"}
                  >
                    Static
                  </option>
                  <option
                    value="relative"
                    selected={colData.position === "relative"}
                  >
                    Relative
                  </option>
                </Form.Control>
              </Form.Group>

              {colData.position === "relative" && (
                <InputComp
                  isRequired={false}
                  type="text"
                  label="Z index"
                  value={colData.zIndex ? colData.zIndex : "1"}
                  onChange={(value) => changeColData("zIndex", value)}
                />
              )}
              <RangeSlider
                value={masterData.lineHeight}
                label="Line Height"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              {/* <ColorPicker width={250} height={228} color={color} onChange={changeColor} hideHSV hideRGB  light /> */}
              <ColorComp
                label="Color"
                colorCB={changeColor}
                primaryColor={colData.color}
              ></ColorComp>
              <ColorComp
                label="Background"
                colorCB={changeBackgroundColor}
                primaryColor={colData.backgroundColor}
                showNoColor={true}
                noColor={noColor}
              ></ColorComp>
              <ColorComp
                label="Border"
                colorCB={changeBorderColor}
                primaryColor={colData.borderColor}
                showNoColor={false}
              ></ColorComp>
              <BorderComp
                defaultValue={masterData.borderType}
                changeValue={changeValueCB}
              ></BorderComp>
            </>
          )}
          <ButtonSpaceComp></ButtonSpaceComp>
        </Tab>
        <Tab eventKey="setting" title="setting">
          {isReady && (
            <>
              <BorderRadiusComp
                value={masterData.borderRadius}
                label="Border Radius"
                cbValue={cbValue}
                maxvalue="100"
                advancedValue={{
                  borderTopLeftRadius: masterData.borderTopLeftRadius,
                  borderTopRightRadius: masterData.borderTopRightRadius,
                  borderBottomLeftRadius: masterData.borderBottomLeftRadius,
                  borderBottomRightRadius: masterData.borderBottomRightRadius,
                }}
                isAdvancedMode={isBorderRadiusAdvanced}
                adVancedModeCB={boderRadiusAdvancedMode}
              ></BorderRadiusComp>
              {/* <RangeSlider value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100"></RangeSlider> */}
              <RangeSlider
                value={masterData.border}
                label="Border"
                cbValue={changeBorder}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginTop}
                label="Margin Top"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginRight}
                label="Margin Right"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginBottom}
                label="Margin Bottom"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginLeft}
                label="Margin Left"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingTop}
                label="Padding Top"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingRight}
                label="Padding Right"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingBottom}
                label="Padding Bottom"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingLeft}
                label="Padding Left"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
            </>
          )}
          <ButtonSpaceComp></ButtonSpaceComp>
        </Tab>
        <Tab eventKey="animation" title="animasi">
          <AnimationComp
            value={colData.animation}
            cbValue={cbAnimation}
          ></AnimationComp>
        </Tab>
      </Tabs>
      <Row></Row>
    </div>
  );
};

export default TextView;
