import {
  MEDIA360,
  MEDIA768,
  MEDIALARGE,
  MEDIAMEDIUM,
  MEDIAQUERY,
  MEDIASMALL,
} from "../variable";

const mediaQuery = (tempState, key, value, widthDevice) => {
  switch (widthDevice) {
    case MEDIALARGE:
      return value;
    case MEDIAMEDIUM:
      if (!key.includes(".")) {
        if (tempState.mediaQueri768 && tempState.mediaQueri768[key]) {
          if (!key.includes(".")) {
            if (key === "borderRadius") {
              let finalValue = borderRadiusFilter(tempState, widthDevice);
              return finalValue;
            } else {
              return tempState.mediaQueri768[key];
            }
          } else {
            return tempState.mediaQueri768[key[0]][key[1]];
          }
        } else {
        }
      } else {
        let newKey = key.split(".");
        if (tempState.mediaQueri768 && tempState.mediaQueri768[newKey[0]]) {
          return tempState.mediaQueri768[newKey[0]][newKey[1]];
        } else {
          return value;
        }
      }

    case MEDIASMALL:
      if (!key.includes(".")) {
        if (tempState.mediaQueri360 && tempState.mediaQueri360[key]) {
          if (!key.includes(".")) {
            if (key === "borderRadius") {
              let finalValue = borderRadiusFilter(tempState, widthDevice);
              return finalValue;
            } else {
              return tempState.mediaQueri360[key];
            }
          } else {
            return tempState.mediaQueri360[key[0]][key[1]];
          }
        } else {
          if (tempState.mediaQueri768 && tempState.mediaQueri768[key]) {
            return tempState.mediaQueri768[key];
          } else {
            return value;
          }
        }
      } else {
        let newKey = key.split(".");
        if (tempState.mediaQueri360 && tempState.mediaQueri360[newKey[0]]) {
          return tempState.mediaQueri360[newKey[0]][newKey[1]];
        } else {
          if (tempState.mediaQueri768 && tempState.mediaQueri768[newKey[0]]) {
            return tempState.mediaQueri768[newKey[0]][newKey[1]];
          } else {
            return value;
          }
        }
      }
  }
};

/**
 * border radius checking start
 */
const checkingRadius = (object, key, widthDevice) => {
  let deviceDefault = object[key];
  switch (widthDevice) {
    case MEDIALARGE:
      if (object[key]) {
        deviceDefault = object[key];
      } else {
        deviceDefault = 0;
      }
      break;
    case MEDIAMEDIUM:
      if (object?.mediaQueri768 && object?.mediaQueri768[key]) {
        deviceDefault = object?.mediaQueri768[key];
      } else {
        deviceDefault = object[key];
      }
      break;
    case MEDIASMALL:
      if (object?.mediaQueri360 && object?.mediaQueri360[key]) {
        deviceDefault = object?.mediaQueri360[key];
      } else if (object?.mediaQueri768 && object?.mediaQueri768[key]) {
        deviceDefault = object?.mediaQueri768[key];
      } else {
        deviceDefault = object[key];
      }
      break;
  }
  return deviceDefault;
};

const checkPx = (value) => {
  if (value) {
    if (value?.toString()?.includes("px")) {
      return value;
    } else {
      return `${value}px`;
    }
  }
};
const borderRadiusFilter = (object, widthDevice) => {
  let allValue = [];
  let borderRadius = checkingRadius(object, "borderRadius", widthDevice) || 0;
  let borderTopLeftRadius =
    checkingRadius(object, "borderTopLeftRadius", widthDevice) || 0;
  let borderTopRightRadius =
    checkingRadius(object, "borderTopRightRadius", widthDevice) || 0;
  let borderBottomLeftRadius =
    checkingRadius(object, "borderBottomLeftRadius", widthDevice) || 0;
  let borderBottomRightRadius =
    checkingRadius(object, "borderBottomRightRadius", widthDevice) || 0;
  let finalValue = borderRadius;
  if (
    removePx(borderTopLeftRadius) !== 0 ||
    removePx(borderTopRightRadius) !== 0 ||
    removePx(borderBottomLeftRadius) !== 0 ||
    removePx(borderBottomRightRadius) !== 0
  ) {
    console.log("nahlo", removePx(borderTopLeftRadius));
    console.log("nahlo", removePx(borderTopRightRadius));
    console.log("nahlo", removePx(borderBottomLeftRadius));
    console.log("nahlo", removePx(borderBottomRightRadius));
    allValue.push(`${checkPx(borderTopLeftRadius)}`);
    allValue.push(`${checkPx(borderTopRightRadius)}`);
    allValue.push(`${checkPx(borderBottomRightRadius)}`);
    allValue.push(`${checkPx(borderBottomLeftRadius)}`);
    finalValue = allValue.join(" ");
  }
  if (finalValue) {
    if (finalValue.toString().includes("undefined")) {
      finalValue = finalValue?.replace(/\bundefined\b/g, "0px");
    }
  }
  return finalValue;
};

const borderRadiusMediaQuery = (tempState, key, object, widthDevice) => {
  let finalValue = borderRadiusFilter(object, widthDevice);
  return mediaQuery(tempState, key, finalValue, widthDevice);
};

const removePx = (value) => {
  let wordToRemove = "px";
  if (value) {
    if (value?.toString()?.includes(wordToRemove)) {
      let newStr = value
        .replace(new RegExp("\\b" + wordToRemove + "\\b", "g"), "")
        .trim();
      return parseInt(newStr);
    } else {
      return parseInt(value);
    }
  } else {
    return 0;
  }
};
const isAdvancedRadius = (data) => {
  if (
    data.borderTopLeftRadius !== undefined ||
    data.borderTopRightRadius !== undefined ||
    data.borderBottomLeftRadius !== undefined ||
    data.borderBottomRightRadius !== undefined
  ) {
    if (
      removePx(data?.borderTopLeftRadius) !== 0 ||
      removePx(data?.borderTopRightRadius) !== 0 ||
      removePx(data?.borderBottomLeftRadius) !== 0 ||
      removePx(data?.borderBottomRightRadius) !== 0
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const borderRadiusColomMediaQuery = (object, widthDevice) => {
  let result = borderRadiusFilter(object, widthDevice);
  return result;
};

const clearBorderRadius = (masterData) => {
  let tempState = { ...masterData };
  tempState.borderRadius = 0;
  tempState.borderTopLeftRadius = 0;
  tempState.borderTopRightRadius = 0;
  tempState.borderBottomLeftRadius = 0;
  tempState.borderBottomRightRadius = 0;
  if (tempState[`${MEDIAQUERY}${MEDIA360}`]) {
    tempState[`${MEDIAQUERY}${MEDIA360}`].borderTopLeftRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIA360}`].borderTopRightRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIA360}`].borderBottomLeftRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIA360}`].borderBottomRightRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIA360}`].borderRadius = 0;
  }
  if (tempState[`${MEDIAQUERY}${MEDIA768}`]) {
    tempState[`${MEDIAQUERY}${MEDIA768}`].borderTopLeftRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIA768}`].borderTopRightRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIA768}`].borderBottomLeftRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIA768}`].borderBottomRightRadius = 0;
    tempState[`${MEDIAQUERY}${MEDIA768}`].borderRadius = 0;
  }
  return tempState;
};

/**
 * border radius checking end
 */

export default mediaQuery;
export {
  borderRadiusMediaQuery,
  borderRadiusColomMediaQuery,
  isAdvancedRadius,
  clearBorderRadius,
};
