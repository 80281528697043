import { Alert, Col, Container, Row } from "react-bootstrap";
import BreadCrumbComp from "../components/BreadCrumbComp";
import NavbarComp from "../components/NavbarComp";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import ContactCSComp from "../components/ContactCSComp";
const CoinPage = () => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [orderId, setOrderId] = useState("123");
  const [grossAmount, setGrossAmount] = useState("");

  const handlePayment = async () => {
    try {
      const response = await axios.post(`${baseURL}/api/v1/customer/payment`, {
        orderId,
        grossAmount,
      });
      if (response?.data?.transactionToken) {
        const token = response?.data?.transactionToken;
        // Ambil token dari response
        window.snap.pay(token, {
          onSuccess: function (result) {
            console.log("Payment Success:", result);
            // Lakukan sesuatu setelah pembayaran sukses
          },
          onPending: function (result) {
            console.log("Payment Pending:", result);
          },
          onError: function (result) {
            console.log("Payment Error:", result);
          },
          onClose: function () {
            console.log("Payment Window Closed");
          },
        });
      }
    } catch (error) {
      console.error("Payment Error:", error);
    }
  };
  const dispatch = useDispatch();
  const [detailProfile, setDetailProfle] = useState();
  const [breadcrumbData, setBreadCrumbData] = useState({
    root: "Home",
    root_link: "/",
    menu: "Profile",
    menu_link: "/profile",
    current: "",
  });

  const listPricing = [
    {
      duration: "Hemat",
      qty: "5 kali pakai Kirim instan",
      qty2: "-",
      price: "Rp 50.000",
      coin: "50 Koin",
    },
    {
      duration: "Mantap",
      qty: "10 kali pakai Kirim instan /",
      qty2: "1 kali pakai buku tamu",
      price: "Rp 100.000",
      coin: "100 Koin",
    },
    {
      duration: "Kece",
      qty: "35 kali pakai Kirim instan /",
      qty2: "3 kali pakai buku tamu",
      price: "Rp 300.000",
      coin: "350 Koin",
    },
  ];

  return (
    <div>
      <NavbarComp></NavbarComp>
      <Container>
        <Row className="mt-5">
          <Col md={12}>
            <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
              <h3 class="display-6">Tambah Koin</h3>
              <p class="lead">Beli koin untuk dapat mengaktifkan plugins</p>
            </div>
            <Container>
              <Row>
                {listPricing.map((pricing) => {
                  return (
                    <Col>
                      <div class="card-deck mb-3 ">
                        <div class="card mb-4 box-shadow">
                          <div class="card-header">
                            <h4 class="my-0 font-weight-normal text-center">
                              {pricing.duration}
                            </h4>
                          </div>
                          <div class="card-body">
                            <h3 class="card-title pricing-card-title text-center">
                              {pricing.price}{" "}
                            </h3>
                            <p className="text-center">
                              Dengan menggunakan paket ini kamu dapat{" "}
                              <b> {pricing.coin} </b>Koin yang bisa digunakan
                              untuk mengaktifkan plugin:
                            </p>
                            <ul class=" mt-3 mb-4">
                              <li>{pricing.qty}</li>
                              <li>{pricing.qty2}</li>
                            </ul>
                            <div className="text-center">
                              <button
                                type="button"
                                class="btn btn-md btn-primary "
                              >
                                Pembayaran
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <ContactCSComp></ContactCSComp>
      {/* <h2>Payment</h2> */}
      {/* <input
        type="text"
        placeholder="Order ID"
        value={orderId}
        onChange={(e) => setOrderId(e.target.value)}
      />
      <input
        type="number"
        placeholder="Gross Amount"
        value={grossAmount}
        onChange={(e) => setGrossAmount(e.target.value)}
      />
      <button onClick={handlePayment}>Pay</button> */}
    </div>
  );
};

export default CoinPage;
