import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
function BreadCrumbComp({ data }) {
  return (
    <div
      style={{
        width: "100%",
        padding: "4px",
        background: "#fff",
        marginBottom: "10px",
        marginTop: "25px",
        paddingLeft: "15px",
        fontSize: "14px",
        paddingTop: "10px",
      }}
    >
      <Breadcrumb
        style={{
          backgroundColor: "#fff",
          height: "30px",
        }}
      >
        <Breadcrumb.Item href={data.root_link}>{data.root}</Breadcrumb.Item>
        <Breadcrumb.Item href={data.menu_link}>{data.menu}</Breadcrumb.Item>
        <Breadcrumb.Item active>{data.current}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
}

export default BreadCrumbComp;
