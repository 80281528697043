import axiosInstance from "../helpers/axiosHelper";
const path = "/api/v1/customer/profile";
export const getProfileServices = (role) => {
  if (role === "admin") {
    return axiosInstance.get(
      `/api/v1/${role}/profile?id=63cbdc410c4fee3502af8aaa`
    );
  } else {
    return axiosInstance.get(path);
  }
};

export const updateProfileServices = (data, role) => {
  if (role === "admin") {
    return axiosInstance.put(
      `/api/v1/${role}/profile?id=63cbdc410c4fee3502af8aaa`,
      data
    );
  } else {
    return axiosInstance.put(path, data);
  }
};
