import React, { useState, useEffect } from "react";
import RangeSlider from "../form/Rangeslider";
import labelCSS from "../../helpers/labelCss";
import { Form, Tabs, Tab } from "react-bootstrap";
import TextAlign from "../form/TextAlign";
import AnimationComp from "../form/AnimationComp";
import ImageShape from "../form/ImageShapeComp";
import InputPositionComp from "../form/InputPositionComp";
import uploadHelper from "../../helpers/uploadHelper";
import LoaderComp from "../LoaderComp";
import InputComp from "../form/InputComp";
import RotateComp from "../form/RotateComp";
import mediaQuery, {
  clearBorderRadius,
  isAdvancedRadius,
} from "../../helpers/mediaQueryHelper";
import {
  MEDIALARGE,
  MEDIAMEDIUM,
  MEDIAQUERY,
  MEDIASMALL,
  SIZEMEDIUM,
  SIZESMALL,
} from "../../variable";
import BorderRadiusComp from "../form/BorderRadiusComp";
import ButtonSpaceComp from "../ButtonSpaceComp";
import convertToWebP from "../../helpers/webPHelper";
import BorderComp from "../form/BorderComp";
import ColorComp from "../form/ColorComp";
const ImageView = ({ data, ViewCB, widthDevice }) => {
  const listResponsiveKey = [
    "marginTop",
    "marginLeft",
    "marginRight",
    "marginBottom",
    "paddingTop",
    "paddingRight",
    "paddingBottom",
    "paddingLeft",
    "borderStyle",
    "borderWidth",
    "backgroundColor",
    "backgroundSize",
    "border",
    "borderColor",
    "positionAbsolute",
    "textAlign",
    "width",
    "zIndex",
    "rotate",
    "position",
    "customClassName",
  ];
  const [isBorderRadiusAdvanced, setIsBorderRadiusAdvanced] = useState(
    isAdvancedRadius(data)
  );
  const [key, setKey] = useState("main");
  const [isAbsolute, setIsAbsolute] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [colData, setColData] = useState(data);
  const [isReady, setIsReady] = useState(false);
  const [masterData, setMasterData] = useState(data);
  const [widthDevice2, setWidthDevice2] = useState(widthDevice);
  const cbAnimation = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        animation: value,
      };
    });
  };

  const cbValue = (s_data, isGlobal) => {
    let key = labelCSS(s_data.key);
    let value = s_data.value;
    let extension = key === "width" ? "%" : "px";
    if (
      s_data.key === "textAlign" ||
      s_data.key === "shape" ||
      s_data.key === "zIndex" ||
      s_data.key === "customClassName" ||
      s_data.key === "rotate" ||
      s_data.key === "positionAbsolute" ||
      s_data.key === "borderStyle"
    ) {
      extension = "";
    }
    if (widthDevice === MEDIALARGE || isGlobal) {
      setColData((prev) => {
        return {
          ...prev,
          [key]: value + extension,
        };
      });
    } else {
      if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
        let widthDeviceStr = SIZEMEDIUM;
        if (widthDevice === MEDIASMALL) {
          widthDeviceStr = SIZESMALL;
        }
        let temp = { ...colData };
        console.log("kena", s_data);
        if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
          let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`];
          let newObject = {
            [key]: value + extension,
          };
          let newJoin = { ...tempNewObject, ...newObject };
          temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin;
          setColData(temp);
        } else {
          temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
            [key]: value + extension,
          };
          setColData(temp);
        }
      }
    }
  };

  useEffect(() => {
    if (colData && data) {
      ViewCB(colData);
      setMasterData(colData);
      if (colData.position === "absolute") {
        setIsAbsolute(true);
      } else {
        setIsAbsolute(false);
      }
    }
  }, [colData]);

  const changePosition = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        position: value,
      };
    });
  };

  const uploadSelectedFile = async (event) => {
    event.preventDefault();
    setIsUploading(true);
    let webpfile = await convertToWebP(event.target.files);
    let result = await uploadHelper(event);
    if (result) {
      setColData((prev) => {
        return {
          ...prev,
          imagewebp: webpfile[0] ? webpfile[0] : "",
          image: result[0].widthImage,
          image378: result[0].widthImage378,
          image678: result[0].widthImage678,
          width: "100%",
        };
      });
    }

    setTimeout(() => {
      setIsUploading(false);
    }, 200);
  };

  const positionCB = (value) => {
    if (widthDevice === MEDIALARGE) {
      setColData((prev) => {
        return {
          ...prev,
          positionAbsolute: value,
        };
      });
    }

    if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
      let widthDeviceStr = SIZEMEDIUM;
      if (widthDevice === MEDIASMALL) {
        widthDeviceStr = SIZESMALL;
      }
      let temp = { ...colData };
      if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
        let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`];
        let newObject = {
          positionAbsolute: value,
        };
        let newJoin = { ...tempNewObject, ...newObject };
        temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin;
        setColData(temp);
      } else {
        temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
          positionAbsolute: value,
        };
        setColData(temp);
      }
    }
  };

  const changeLink = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        link: value,
      };
    });
  };

  const calculateSetting = () => {
    setIsReady(false);
    let tempState = { ...masterData };
    for (let a = 0; a < listResponsiveKey.length; a++) {
      tempState[listResponsiveKey[a]] = mediaQuery(
        tempState,
        listResponsiveKey[a],
        tempState[listResponsiveKey[a]],
        widthDevice
      );
    }

    if (isBorderRadiusAdvanced) {
      tempState.borderRadius = 0;
      tempState.borderTopLeftRadius = mediaQuery(
        tempState,
        "borderTopLeftRadius",
        tempState.borderTopLeftRadius,
        widthDevice
      );
      tempState.borderTopRightRadius = mediaQuery(
        tempState,
        "borderTopRightRadius",
        tempState.borderTopRightRadius,
        widthDevice
      );
      tempState.borderBottomLeftRadius = mediaQuery(
        tempState,
        "borderBottomLeftRadius",
        tempState.borderBottomLeftRadius,
        widthDevice
      );
      tempState.borderBottomRightRadius = mediaQuery(
        tempState,
        "borderBottomRightRadius",
        tempState.borderBottomRightRadius,
        widthDevice
      );
    } else {
      tempState.borderRadius = mediaQuery(
        tempState,
        "borderRadius",
        tempState.borderRadius,
        widthDevice
      );
      tempState.borderTopLeftRadius = 0;
      tempState.borderTopRightRadius = 0;
      tempState.borderBottomLeftRadius = 0;
      tempState.borderBottomRightRadius = 0;
    }

    setMasterData(tempState);
    setTimeout(() => {
      setIsReady(true);
    }, 500);
  };

  useEffect(() => {
    setIsReady(false);
    setTimeout(() => {
      calculateSetting(widthDevice2);
    }, 500);
  }, [widthDevice2]);

  useEffect(() => {
    if (widthDevice !== widthDevice2) {
      setWidthDevice2(widthDevice);
    }
  }, [widthDevice]);

  const boderRadiusAdvancedMode = (val) => {
    setIsBorderRadiusAdvanced(val);
    let resetRadius = clearBorderRadius(masterData);
    console.log("resetRadius", resetRadius);
    setColData(resetRadius);
  };

  const changeBorderColor = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        isBorderTransparent: false,
        borderColor: value.hex,
      };
    });
  };

  const changeBorder = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        borderWidth: value.value,
      };
    });
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="main" title="main">
          {isReady && (
            <>
              <InputComp
                type="text"
                label="CSS Class"
                placeholder="Masukkan CSS Class"
                value={masterData.customClassName}
                isRequired={false}
                onChange={(val) => {
                  let key = "customClassName";
                  let newData = {
                    key,
                    value: val,
                  };
                  cbValue(newData, true);
                }}
              ></InputComp>
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span> Gambar Produk
                </Form.Label>
                <Form.Control
                  className="form-control-dark"
                  id="custom-file"
                  label="Custom file input"
                  name="input2[]"
                  type="file"
                  data-show-upload="false"
                  data-show-caption="true"
                  custom
                  style={{ background: "red !important" }}
                  onChange={($e) => {
                    uploadSelectedFile($e);
                  }}
                />
                <div>
                  <i>mendukung format jpg/png max 5mb</i>
                </div>
                <div>
                  {isUploading ? (
                    <LoaderComp></LoaderComp>
                  ) : (
                    <>
                      {colData.image && (
                        <img
                          src={colData.image}
                          width="100%"
                          style={{
                            marginBottom: "10px",
                            // transform: `rotate(45deg)`,
                            transform: `rotate(${mediaQuery(
                              colData,
                              "rotate",
                              colData.rotate,
                              widthDevice
                            )}deg)`,
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </Form.Group>
              <RotateComp
                value={masterData.rotate}
                label="Rotate"
                cbValue={(data) => cbValue(data, true)}
              ></RotateComp>
              <TextAlign
                value={masterData.textAlign}
                label="Text Align"
                cbValue={cbValue}
              ></TextAlign>
              <ImageShape
                value={colData.shape}
                label="Shape"
                cbValue={cbValue}
              ></ImageShape>
              <Form.Group controlId="name" className="mt-2">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span> Position
                </Form.Label>
                <Form.Control
                  as="select"
                  onChange={($e) => changePosition($e.target.value)}
                  className="form-control-dark"
                >
                  <option
                    value="static"
                    selected={masterData.position === "static"}
                  >
                    Static
                  </option>
                  <option
                    value="relative"
                    selected={masterData.position === "relative"}
                  >
                    Relative
                  </option>
                  <option
                    value="absolute"
                    selected={masterData.position === "absolute"}
                  >
                    Absolute
                  </option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="name">
                <Form.Label>
                  <span style={{ color: "red" }}>*</span>Action
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={masterData.link}
                  onChange={($e) => changeLink($e.target.value)}
                  className="form-control-dark"
                ></Form.Control>
              </Form.Group>
              {(masterData.position === "absolute" ||
                masterData.position === "relative") && (
                <>
                  <InputComp
                    type="number"
                    label="z index"
                    placeholder="Masukkan z-index"
                    value={masterData.zIndex}
                    isRequired={false}
                    onChange={(val) => {
                      let key = "zIndex";
                      let newData = {
                        key,
                        value: val,
                      };
                      cbValue(newData, true);
                    }}
                  ></InputComp>
                  {masterData?.position === "absolute" && (
                    <InputPositionComp
                      position={masterData.positionAbsolute}
                      positionCB={positionCB}
                    ></InputPositionComp>
                  )}
                </>
              )}
              <BorderComp
                defaultValue={masterData.borderStyle}
                changeValue={(value) => {
                  cbValue({
                    key: "borderStyle",
                    value: value,
                  });
                }}
              ></BorderComp>
              <ColorComp
                label="Border"
                colorCB={changeBorderColor}
                primaryColor={
                  colData.borderColor ? colData.borderColor : "#fff"
                }
                showNoColor={false}
              ></ColorComp>
            </>
          )}
          <ButtonSpaceComp></ButtonSpaceComp>
        </Tab>
        <Tab eventKey="setting" title="setting">
          {isReady && (
            <>
              <BorderRadiusComp
                value={masterData.borderRadius}
                label="Border Radius"
                cbValue={cbValue}
                maxvalue="100"
                advancedValue={{
                  borderTopLeftRadius: masterData.borderTopLeftRadius,
                  borderTopRightRadius: masterData.borderTopRightRadius,
                  borderBottomLeftRadius: masterData.borderBottomLeftRadius,
                  borderBottomRightRadius: masterData.borderBottomRightRadius,
                }}
                isAdvancedMode={isBorderRadiusAdvanced}
                adVancedModeCB={boderRadiusAdvancedMode}
              ></BorderRadiusComp>
              {/* <RangeSlider value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100"></RangeSlider> */}
              <RangeSlider
                value={masterData.borderWidth}
                label="Border Width"
                cbValue={changeBorder}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.width}
                label="Width"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginTop}
                label="Margin Top"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginRight}
                label="Margin Right"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginBottom}
                label="Margin Bottom"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.marginLeft}
                label="Margin Left"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingTop}
                label="Padding Top"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingRight}
                label="Padding Right"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingBottom}
                label="Padding Bottom"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingLeft}
                label="Padding Left"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
            </>
          )}
          <ButtonSpaceComp></ButtonSpaceComp>
        </Tab>
        <Tab eventKey="animation" title="animasi">
          <AnimationComp
            value={colData.animation ? colData.animation : ""}
            cbValue={cbAnimation}
            isAbsolute={isAbsolute}
          ></AnimationComp>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ImageView;
