import { useEffect, useState } from "react";
import StyleEditor from "react-style-editor";
const CSSEditorComp = ({ custom_css_code, changeCSSCB }) => {
  console.log("custom_css_code", custom_css_code);
  const [cssCode, setCssCode] = useState(
    custom_css_code !== undefined && custom_css_code?.toString()?.trim() != ""
      ? custom_css_code
      : `
            .sample {
            background:#fff
            }
                `
  );

  useEffect(() => {
    changeCSSCB(cssCode);
  }, [cssCode]);

  return (
    <div style={{ padding: "10px", color: "#fff" }}>
      CSS editor
      <StyleEditor
        defaultValue={cssCode}
        outputFormats="pretty"
        style={{
          minHeight: "300px",
          color: "#fff",
          background: "#18191B",
          border: "solid 1px #18191B",
          marginTop: "10px",
          marginLeft: "-5px",
          marginRight: "-5px",
          padding: "10px 5px",
        }}
        onChange={(value) => {
          setCssCode(value);
        }}
      />
    </div>
  );
};

export default CSSEditorComp;
