import axiosInstance from "../helpers/axiosHelper";
const path = "/api/v1/customer/transaction";

export const getTransactions = (pageSize, page) => {
  let url = `${path}?page=${page}&pageSize=${pageSize}`;
  return axiosInstance.get(url);
};

export const getTransactionDetail = (id) => {
  return axiosInstance.get(`${path}/${id}`);
};
export const updateTransaction = (data) => {
  return axiosInstance.put(`${path}`, data);
};
