import "./App.css";
import { useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import routerProtected from "./routerProtected";
import ReactGA from "react-ga4";
import routerProtectedOwner from "./routerProtectedOwner";
function App() {
  ReactGA.initialize("G-FXPT0WRENM");
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "accessing application",
  });
  const { user } = useSelector((state) => state);
  return (
    <>
      {user?.app_id ? (
        user?.role === "63cbe0b21b2e213c687d0b2d" ? (
          <RouterProvider router={routerProtectedOwner} />
        ) : (
          <RouterProvider router={routerProtected} />
        )
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  );
}

export default App;
