import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Range, getTrackBackground } from "react-range";

const BorderRadiusComp = ({
  value,
  label,
  cbValue,
  maxvalue,
  advancedValue,
  isAdvancedMode,
  adVancedModeCB,
}) => {
  const [advancedMode, setAdvancedMode] = useState(isAdvancedMode);
  const [advancedOptional, setAdvancedOptional] = useState([
    "borderTopLeftRadius",
    "borderTopRightRadius",
    "borderBottomLeftRadius",
    "borderBottomRightRadius",
  ]);
  const [selectedAdvancedLabel, setSelectedAdvancedLabel] = useState("");

  if (typeof value === "string") {
    value = value.replace(/\D/g, "");
  } else {
    value = Math.round(value);
  }
  for (let a = 0; a < advancedOptional.length; a++) {
    if (typeof advancedValue[advancedOptional[a]] === "string") {
      advancedValue[advancedOptional[a]] = advancedValue[
        advancedOptional[a]
      ].replace(/\D/g, "");
    } else {
      advancedValue[advancedOptional[a]] = Math.round(
        advancedValue[advancedOptional[a]]
      );
    }
  }

  const STEP = 0.1;
  const MIN = 0;
  if (maxvalue !== undefined || maxvalue !== null || maxvalue === "") {
    var MAX = parseInt(maxvalue);
  } else {
    var MAX = label === "width" ? 100 : 100;
  }

  const [formValue, setFormValue] = useState({
    values: value > MAX ? [MAX] : [value],
  });

  const [advancedFormValue, setAdvancedFormValue] = useState({
    values: {
      borderTopLeftRadius:
        advancedValue.borderTopLeftRadius > MAX
          ? [MAX]
          : [advancedValue.borderTopLeftRadius],
      borderTopRightRadius:
        advancedValue.borderTopRightRadius > MAX
          ? [MAX]
          : [advancedValue.borderTopRightRadius],
      borderBottomLeftRadius:
        advancedValue.borderBottomLeftRadius > MAX
          ? [MAX]
          : [advancedValue.borderBottomLeftRadius],
      borderBottomRightRadius:
        advancedValue.borderBottomRightRadius > MAX
          ? [MAX]
          : [advancedValue.borderBottomRightRadius],
    },
  });

  const changeValue = (value, opsi) => {
    value = Math.round(value);
    if (advancedMode) {
      setSelectedAdvancedLabel(opsi);
      let tempValue = { ...advancedFormValue };
      tempValue.values[opsi] = [value];
      setAdvancedFormValue(tempValue);
    } else {
      setFormValue({
        values: [value],
      });
    }
  };

  useEffect(() => {
    cbValue({
      key: label.replace(/ /g, ""),
      value: formValue.values[0],
    });
  }, [formValue]);

  useEffect(() => {
    if (advancedFormValue?.values[selectedAdvancedLabel]) {
      cbValue({
        key: selectedAdvancedLabel.replace(/ /g, ""),
        value: advancedFormValue.values[selectedAdvancedLabel][0],
      });
    }
  }, [advancedFormValue]);

  const changeRangeValue = (va, opsi) => {
    let value = Math.round(va.values[0]);
    if (advancedMode) {
      setSelectedAdvancedLabel(opsi);
      let tempValue = { ...advancedFormValue };
      tempValue.values[opsi][0] = value;
      setAdvancedFormValue(tempValue);
    } else {
      setFormValue({
        values: [value],
      });
    }
  };

  useEffect(() => {
    if (isAdvancedMode !== advancedMode) {
      adVancedModeCB(advancedMode);
    }
  }, [advancedMode]);

  useEffect(() => {
    if (isAdvancedMode === false) {
      for (let a = 0; a < advancedOptional.length; a++) {
        if (typeof advancedValue[advancedOptional[a]] === "string") {
          advancedValue[advancedOptional[a]] = 0;
        } else {
          advancedValue[advancedOptional[a]] = 0;
        }
      }
      setAdvancedFormValue({
        values: {
          borderTopLeftRadius: [advancedValue.borderTopLeftRadius],
          borderTopRightRadius: [advancedValue.borderTopRightRadius],
          borderBottomLeftRadius: [advancedValue.borderBottomLeftRadius],
          borderBottomRightRadius: [advancedValue.borderBottomRightRadius],
        },
      });
    } else {
      setFormValue({
        values: [0],
      });
    }
  }, [isAdvancedMode]);

  return (
    <Row>
      {!advancedMode && (
        <>
          <Col md={7} xs={7}>
            <Form.Label>
              <span style={{ color: "red" }}>*</span>
              {label}
            </Form.Label>
            <Range
              values={formValue.values}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={(values) => changeRangeValue({ values })}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: "16px",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: "5px",
                      width: "100%",
                      borderRadius: "4px",
                      background: getTrackBackground({
                        values: formValue.values,
                        colors: ["#548BF4", "#ccc"],
                        min: MIN,
                        max: MAX,
                      }),
                      alignSelf: "center",
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "22px",
                    width: "22px",
                    borderRadius: "4px",
                    backgroundColor: "#333",
                    color: "red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 2px 6px #AAA",
                  }}
                >
                  <div
                    style={{
                      height: "16px",
                      width: "5px",
                      backgroundColor: isDragged ? "#548BF4" : "#CCC",
                    }}
                  />
                </div>
              )}
            />
          </Col>
          <Col md={5} xs={5}>
            <Form.Group controlId="name" className="mt-2">
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder=""
                    value={formValue.values[0]}
                    onChange={($e) => changeValue($e.target.value)}
                    className="form-control-dark"
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </>
      )}

      {advancedMode && (
        <>
          {advancedOptional.map((a, index) => {
            return (
              <>
                <Col md={7} xs={7}>
                  <Form.Label>
                    <span style={{ color: "red" }}>*</span>
                    {`${a}`}
                  </Form.Label>
                  <Range
                    values={advancedFormValue.values[a]}
                    step={STEP}
                    min={MIN}
                    max={MAX}
                    onChange={(values, a) =>
                      changeRangeValue({ values }, advancedOptional[index])
                    }
                    renderTrack={({ props, children }) => (
                      <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                          ...props.style,
                          height: "16px",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <div
                          ref={props.ref}
                          style={{
                            height: "5px",
                            width: "100%",
                            borderRadius: "4px",
                            background: getTrackBackground({
                              values: advancedFormValue.values[a],
                              colors: ["#548BF4", "#ccc"],
                              min: MIN,
                              max: MAX,
                            }),
                            alignSelf: "center",
                          }}
                        >
                          {children}
                        </div>
                      </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                      <div
                        {...props}
                        style={{
                          ...props.style,
                          height: "22px",
                          width: "22px",
                          borderRadius: "4px",
                          backgroundColor: "#333",
                          color: "red",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          boxShadow: "0px 2px 6px #AAA",
                        }}
                      >
                        <div
                          style={{
                            height: "16px",
                            width: "5px",
                            backgroundColor: isDragged ? "#548BF4" : "#CCC",
                          }}
                        />
                      </div>
                    )}
                  />
                </Col>
                <Col md={5} xs={5}>
                  <Form.Group controlId="name" className="mt-2">
                    <Row>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={advancedFormValue.values[a]}
                          onChange={($e) => changeValue($e.target.value, a)}
                          className="form-control-dark"
                        ></Form.Control>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </>
            );
          })}
        </>
      )}
      <button
        className="btn btn-sm mt-1 mb-2"
        style={{ color: "#fff !important" }}
        onClick={() => {
          setAdvancedMode(!advancedMode);
        }}
      >
        <span style={{ color: "#fff" }}>
          ganti ke {!advancedMode ? "advanced" : "general"} border radius
        </span>
      </button>
    </Row>
  );
};

export default BorderRadiusComp;
