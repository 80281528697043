import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import RangeSlider from "./Rangeslider";
import labelCSS from "../../helpers/labelCss";
const AnimationComp = ({ value, cbValue, isAbsolute }) => {
  console.log("valueddsdfs", value);
  const [isReady, setIsReady] = useState(false);
  const [select2Data, setSelect2Data] = useState([
    { value: "", label: "none" },
    { value: "animate__bounce", label: "bounce" },
    { value: "animate__flash", label: "flash" },
    { value: "animate__pulse", label: "pulse" },
    { value: "animate__rubberBand", label: "rubberBand" },
    { value: "animate__shakeX", label: "shakeX" },
    { value: "animate__shakeY", label: "shakeY" },
    { value: "animate__headShake", label: "headShake" },
    { value: "animate__swing", label: "swing" },
    { value: "animate__tada", label: "tada" },
    { value: "animate__wobble", label: "wobble" },
    { value: "animate__jello", label: "jello" },
    { value: "animate__heartBeat", label: "heartBeat" },
    { value: "animate__backInDown", label: "backInDown" },
    { value: "animate__backInLeft", label: "backInLeft" },
    { value: "animate__backInRight", label: "backInRight" },
    { value: "animate__backInUp", label: "backInUp" },
    { value: "animate__backOutDown", label: "backOutDown" },
    { value: "animate__backOutLeft", label: "backOutLeft" },
    { value: "animate__backOutRight", label: "backOutRight" },
    { value: "animate__backOutUp", label: "backOutUp" },
    { value: "animate__bounceIn", label: "bounceIn" },
    { value: "animate__bounceIn", label: "bounceInDown" },
    { value: "animate__bounceIn", label: "bounceInLeft" },
    { value: "animate__bounceInRight", label: "bounceInRight" },
    { value: "animate__bounceInUp", label: "bounceInUp" },
    { value: "animate__bounceOut", label: "bounceOut" },
    { value: "animate__bounceOutDown", label: "bounceOutDown" },
    { value: "animate__bounceOutLeft", label: "bounceOutLeft" },
    { value: "animate__bounceOutRight", label: "bounceOutRight" },
    { value: "animate__bounceOutUp", label: "bounceOutUp" },
    { value: "animate__fadeIn", label: "fadeIn" },
    { value: "animate__fadeInDown", label: "fadeInDown" },
    { value: "animate__fadeInDownBig", label: "fadeInDownBig" },
    { value: "animate__fadeInLeft", label: "fadeInLeft" },
    { value: "animate__fadeInLeftBig", label: "fadeInLeftBig" },
    { value: "animate__fadeInRight", label: "fadeInRight" },
    { value: "animate__fadeInRightBig", label: "fadeInRightBig" },
    { value: "animate__fadeInUp", label: "fadeInUp" },
    { value: "animate__fadeInUpBig", label: "fadeInUpBig" },
    { value: "animate__fadeInTopLeft", label: "fadeInTopLeft" },
    { value: "animate__fadeInTopRight", label: "fadeInTopRight" },
    { value: "animate__fadeInBottomLeft", label: "fadeInBottomLeft" },
    { value: "animate__fadeInBottomRight", label: "fadeInBottomRight" },
    { value: "animate__fadeOut", label: "fadeOut" },
    { value: "animate__fadeOutDown", label: "fadeOutDown" },
    { value: "animate__fadeOutDownBig", label: "fadeOutDownBig" },
    { value: "animate__fadeOutLeft", label: "fadeOutLeft" },
    { value: "animate__fadeOutLeftBig", label: "fadeOutLeftBig" },
    { value: "animate__fadeOutRight", label: "fadeOutRight" },
    { value: "animate__fadeOutRightBig", label: "fadeOutRightBig" },
    { value: "animate__fadeOutUp", label: "fadeOutUp" },
    { value: "animate__fadeOutUpBig", label: "fadeOutUpBig" },
    { value: "animate__fadeOutTopLeft", label: "fadeOutTopLeft" },
    { value: "animate__fadeOutTopRight", label: "fadeOutTopRight" },
    { value: "animate__fadeOutBottomRight", label: "fadeOutBottomRight" },
    { value: "animate__fadeOutBottomLeft", label: "fadeOutBottomLeft" },
    { value: "animate__flip", label: "flip" },
    { value: "animate__flipInX", label: "flipInX" },
    { value: "animate__flipInY", label: "flipInY" },
    { value: "animate__flipOutX", label: "flipOutX" },
    { value: "animate__flipOutY", label: "flipOutY" },
    { value: "animate__lightSpeedInRight", label: "lightSpeedInRight" },
    { value: "animate__lightSpeedInLeft", label: "lightSpeedInLeft" },
    { value: "animate__lightSpeedOutRight   ", label: "lightSpeedOutRight" },
    { value: "animate__lightSpeedOutLeft", label: "lightSpeedOutLeft" },
    { value: "animate__rotateIn", label: "rotateIn" },
    { value: "animate__rotateInDownLeft", label: "rotateInDownLeft" },
    { value: "animate__rotateInDownRight", label: "rotateInDownRight" },
    { value: "animate__rotateInUpLeft", label: "rotateInUpLeft" },
    { value: "animate__rotateInUpRight", label: "rotateInUpRight" },
    { value: "animate__rotateOut", label: "rotateOut" },
    { value: "animate__rotateOutDownLeft", label: "rotateOutDownLeft" },
    { value: "animate__rotateOutDownRight", label: "rotateOutDownRight" },
    { value: "animate__rotateOutUpLeft", label: "rotateOutUpLeft" },
    { value: "animate__rotateOutUpRight", label: "rotateOutUpRight" },
    { value: "animate__hinge", label: "hinge" },
    { value: "animate__jackInTheBox", label: "jackInTheBox" },
    { value: "animate__rollIn", label: "rollIn" },
    { value: "animate__rollOut", label: "rollOut" },
    { value: "animate__zoomIn", label: "zoomIn" },
    { value: "animate__zoomInDown", label: "zoomInDown" },
    { value: "animate__zoomInLeft", label: "zoomInLeft" },
    { value: "animate__zoomInRight", label: "zoomInRight" },
    { value: "animate__zoomInUp", label: "zoomInUp" },
    { value: "animate__zoomOut", label: "zoomOut" },
    { value: "animate__zoomOutDown", label: "zoomOutDown" },
    { value: "animate__zoomOutLeft", label: "zoomOutLeft" },
    { value: "animate__zoomOutRight", label: "zoomOutRight" },
    { value: "animate__zoomOutUp", label: "zoomOutUp" },
    { value: "animate__slideInDown", label: "slideInDown" },
    { value: "animate__slideInLeft", label: "slideInLeft" },
    { value: "animate__slideInRight", label: "slideInRight" },
    { value: "animate__slideInUp", label: "slideInUp" },
    { value: "animate__slideOutDown", label: "slideOutDown" },
    { value: "animate__slideOutLeft", label: "slideOutLeft" },
    { value: "animate__slideOutRight", label: "slideOutRight" },
    { value: "invb-zoomtopleft", label: "invb-zoomtopleft" },
    { value: "invb-zoomtopright", label: "invb-zoomtopright" },
    { value: "invb-zoombottomleft", label: "invb-zoombottomleft" },
    { value: "invb-zoombottomright", label: "invb-zoombottomright" },
    { value: "invb-rotationbottomleft", label: "invb-rotationbottomleft" },
  ]);
  const [select2DataAbsolute, setSelect2DataAbsolute] = useState([
    { value: "", label: "none" },
    { value: "zoomInOut", label: "zoomInOut" },
    { value: "lambai", label: "lambai" },
  ]);
  const [activeAnimation, setActiveAnimation] = useState(
    value === undefined
      ? {
          value: "",
          delay: 1,
          duration: 1,
        }
      : {
          value:
            typeof value === "string" ? value : value.value ? value.value : "",
          delay: value.delay ? value.delay : 1,
          duration: value.duration ? value.duration : 1,
        }
  );

  const changeAnimation = (value) => {
    console.log("taraa", value);
    setActiveAnimation((prev) => {
      return {
        ...prev,
        value: value.value,
      };
    });
  };

  const changeData = (data) => {
    let key = labelCSS(data.key);
    let value = data.value;
    setActiveAnimation((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  useEffect(() => {
    cbValue(activeAnimation);
  }, [activeAnimation]);

  const colourStyles = {
    container: (styles) => ({
      ...styles,
      backgroundColor: "#333 !important",
      color: "#fff !important",
      border: "solid 1px #333",
    }),
    singleValue: (styles) => ({
      ...styles,
      backgroundColor: "#333 !important",
      color: "#fff !important",
    }),
    control: (styles) => ({
      ...styles,
      backgroundColor: "#333 !important",
      color: "#fff !important",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled ? "#333333" : "#333333",
        color: "#FFF !important",
        cursor: isDisabled ? "not-allowed" : "default",
        border: "solid 1px #333",
      };
    },
  };
  return (
    <>
      <Form.Group controlId="name">
        <Form.Label>
          <span style={{ color: "red" }}>*</span>Animation
        </Form.Label>
        <Select
          name="animation"
          value={{ label: activeAnimation.value, value: activeAnimation.value }}
          options={isAbsolute ? select2DataAbsolute : select2Data}
          onChange={changeAnimation}
          styles={colourStyles}
          className="react-select-container"
        />
      </Form.Group>
      <RangeSlider
        value={activeAnimation.duration}
        label="Duration"
        cbValue={changeData}
        maxvalue="10"
      ></RangeSlider>
      {/* <RangeSlider value={activeAnimation.delay} label="Delay" cbValue={changeData} maxvalue="10"></RangeSlider> */}
    </>
  );
};
export default AnimationComp;
