import React, { useState, useEffect } from "react";
import { LARGEDEVICE } from "../variable";
import { checkDevice } from "../helpers/windowHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import InputTextComp from "./form/Input/InputTextComp";
const ScrollMenuX = ({ enableSearch, menus, active, actionCB, searchCB }) => {
  const delay = 1;
  const [searchMode, setSearhMode] = useState(false);
  const [search, setSearch] = useState("");
  const [device, setDevice] = useState("");
  const [hasScroll, setHasScroll] = useState("");
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    setDevice(checkDevice());
  }, []);

  useEffect(() => {
    if (enableSearch && isReady) {
      let timeoutnya = setTimeout(() => {
        searchCB(search);
      }, delay * 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timeoutnya);
      };
    }
  }, [search]);

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 100);
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          overflowX: device === LARGEDEVICE ? hasScroll : "scroll",
        }}
        onMouseEnter={() => setHasScroll("scroll")}
        onMouseLeave={() => setHasScroll("hidden")}
      >
        {enableSearch && !searchMode && (
          <div
            style={{
              // background: menu._id === active ? "#14B2D0" : "#343536",
              borderRadius: "5px",
              padding: "5px 10px",
              color: "#fff",
              border: "solid 1px #4B4B4C",
              margin: "7px 5px",
              height: "35px",
              marginBottom: "10px",
              cursor: "pointer",
              width: "auto",
            }}
            onClick={() => setSearhMode(!searchMode)}
          >
            <FontAwesomeIcon icon={faSearch} />
          </div>
        )}
        {searchMode && (
          <>
            <div style={{ marginTop: "-20px", marginLeft: "7px" }}>
              <InputTextComp
                placeholder="ketik sesuatu"
                value={search}
                onChangeCB={(keyword) => setSearch(keyword)}
              />
            </div>
            <div
              style={{
                // background: menu._id === active ? "#14B2D0" : "#343536",
                borderRadius: "5px",
                padding: "5px 10px",
                color: "#fff",
                border: "solid 1px #4B4B4C",
                margin: "7px 5px",
                height: "35px",
                marginBottom: "10px",
                cursor: "pointer",
                width: "auto",
              }}
              onClick={() => {
                setSearch("");
                setSearhMode(!searchMode);
              }}
            >
              <FontAwesomeIcon icon={faClose} />
            </div>
          </>
        )}
        {!searchMode &&
          menus?.map((menu) => {
            return (
              <div
                style={{
                  background: menu._id === active ? "#14B2D0" : "#343536",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  color: "#fff",
                  border: "solid 1px #4B4B4C",
                  margin: "7px 5px",
                  height: "35px",
                  marginBottom: "10px",
                  cursor: "pointer",
                  width: "auto",
                }}
                onClick={() => actionCB("category", menu._id)}
              >
                {menu.name}
              </div>
            );
          })}
      </div>
    </>
  );
};
export default ScrollMenuX;
