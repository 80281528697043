import React, { useState, useEffect } from "react";
import { Form, Row, Col, Tabs, Tab } from "react-bootstrap";
import RangeSlider from "../form/Rangeslider";
import labelCSS from "../../helpers/labelCss";
import ColorComp from "../form/ColorComp";
const DeviderView = ({ data, ViewCB }) => {
  const [key, setKey] = useState("main");
  const [shapeList, setShapeList] = useState([
    "waves",
    "waves opacity",
    "curve",
    "curve asymmetrical",
    "triangle",
    "triangle asymmetrical",
    "tilt",
    "arrow",
    "split",
    "book",
  ]);
  const [colData, setColData] = useState(data);

  const cbValue = (data) => {
    let key = labelCSS(data.key);

    let value = data.value;
    let extension = key === "width" ? "%" : "px";
    if (extension === "") {
      setColData((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
    } else {
      setColData((prev) => {
        return {
          ...prev,
          [key]: value + extension,
        };
      });
    }
  };

  useEffect(() => {
    if (colData) {
      ViewCB(colData);
    }
  }, [colData]);

  const changeData = (key, value) => {
    setColData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const changeColor = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        color: value.hex,
      };
    });
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="main" title="main">
          <Form.Group controlId="name">
            <Form.Label>
              <span style={{ color: "red" }}>*</span>Shape
            </Form.Label>
            <Row>
              <Col>
                <Form.Control
                  as="select"
                  onChange={($e) => changeData("shape", $e.target.value)}
                  className="form-control-dark"
                >
                  {shapeList.map((b) => {
                    return (
                      <option value={b} selected={colData.shape === b}>
                        {b}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>

          <ColorComp
            label="Color"
            colorCB={changeColor}
            primaryColor={colData.color}
          ></ColorComp>

          <Form.Group controlId="name">
            <Form.Label>
              <span style={{ color: "red" }}>*</span>Position
            </Form.Label>
            <Row>
              <Col>
                <Form.Control
                  as="select"
                  onChange={($e) => changeData("position", $e.target.value)}
                  className="form-control-dark"
                >
                  <option value="top" selected={colData.position === "top"}>
                    Top
                  </option>
                  <option
                    value="bottom"
                    selected={colData.position === "bottom"}
                  >
                    Bottom
                  </option>
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group controlId="name">
            <Form.Label>
              <span style={{ color: "red" }}>*</span>Flip
            </Form.Label>
            <Row>
              <Col>
                <Form.Control
                  as="select"
                  onChange={($e) => changeData("flip", $e.target.value)}
                  className="form-control-dark"
                >
                  <option value={false} selected={colData.flip === false}>
                    No
                  </option>
                  <option value={true} selected={colData.flip === true}>
                    Yes
                  </option>
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group controlId="name">
            <Form.Label>
              <span style={{ color: "red" }}>*</span>Invert
            </Form.Label>
            <Row>
              <Col>
                <Form.Control
                  as="select"
                  onChange={($e) => changeData("invert", $e.target.value)}
                  className="form-control-dark"
                >
                  <option value={false} selected={colData.invert === false}>
                    No
                  </option>
                  <option value={true} selected={colData.invert === true}>
                    Yes
                  </option>
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>
          {/* <RangeSlider value={colData.borderRadius} label="Width" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.borderRadius} label="Height" cbValue={cbValue} maxvalue="100"></RangeSlider> */}
        </Tab>
        {/* <Tab eventKey="setting" title="setting">
                    <RangeSlider value={colData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.marginTop} label="Margin Top" cbValue={cbValue}></RangeSlider>
                    <RangeSlider value={colData.marginRight} label="Margin Right" cbValue={cbValue}></RangeSlider>
                    <RangeSlider value={colData.marginBottom} label="Margin Bottom" cbValue={cbValue}></RangeSlider>
                    <RangeSlider value={colData.marginLeft} label="Margin Left" cbValue={cbValue}></RangeSlider>
                    <RangeSlider value={colData.paddingTop} label="Padding Top" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.paddingRight} label="Padding Right" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.paddingBottom} label="Padding Bottom" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.paddingLeft} label="Padding Left" cbValue={cbValue} maxvalue="100"></RangeSlider>
                    <RangeSlider value={colData.border} label="Border" cbValue={cbValue} maxvalue="100"></RangeSlider>
               
                </Tab>
                <Tab eventKey="animation" title="animasi" >
                    <AnimationComp value={colData.animation ? colData.animation.value : ''} cbValue={cbAnimation}></AnimationComp>
                </Tab> */}
      </Tabs>
    </div>
  );
};

export default DeviderView;
