import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import InvitationPage from "./pages/Invitation/InvitationPage";
import WebsiteEditorPage from "./pages/Editor/WebsiteEditorPage";
import RSVPPage from "./pages/RSVP";
import NotFoundPage from "./pages/NotFoundPage";
import ProfilePage from "./pages/ProfilePage";
import SettingPage from "./pages/SettingPage";
import LogsPage from "./pages/LogsPage";
import LoginPage from "./pages/LoginPages";
import LogoutPages from "./pages/LogoutPages";
import PricingPage from "./pages/PricingPage";
import CoinPage from "./pages/CoinPage";
import TransactionPage from "./pages/transactions/transactionPage";

const routerProtected = createBrowserRouter([
  {
    path: "/",
    element: <InvitationPage></InvitationPage>,
  },
  {
    path: "/transactions",
    element: <TransactionPage></TransactionPage>,
  },
  {
    path: "/editor-web",
    element: <WebsiteEditorPage></WebsiteEditorPage>,
  },
  {
    path: "/editor-web/:idslug",
    element: <WebsiteEditorPage></WebsiteEditorPage>,
  },
  {
    path: "/rsvp/:slug",
    element: <RSVPPage></RSVPPage>,
  },
  {
    path: "/profile",
    element: <ProfilePage></ProfilePage>,
  },
  {
    path: "/pricing",
    element: <PricingPage></PricingPage>,
  },
  {
    path: "/coin",
    element: <CoinPage></CoinPage>,
  },
  {
    path: "/setting",
    element: <SettingPage></SettingPage>,
  },
  {
    path: "/logs",
    element: <LogsPage></LogsPage>,
  },
  {
    path: "/login",
    element: <LoginPage></LoginPage>,
  },
  {
    path: "/logout",
    element: <LogoutPages></LogoutPages>,
  },
  {
    path: "*",
    element: <NotFoundPage></NotFoundPage>,
  },
]);

export default routerProtected;
