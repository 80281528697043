import React, { useState, useEffect } from "react";
import RangeSlider from "../form/Rangeslider";
import labelCSS from "../../helpers/labelCss";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  InputGroup,
  FormControl,
  Alert,
  Image,
  Modal,
  Tabs,
  Tab,
} from "react-bootstrap";
import TextAlign from "../form/TextAlign";
import { upload } from "./../../services/uploadServices";
import compressHelper from "../../helpers/compressHelper";
import Loader from "../Loader";
import AnimationComp from "../form/AnimationComp";
const CarouselView = ({ data, ViewCB }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [key, setKey] = useState("main");

  const [colData, setColData] = useState(data);

  const changeViewType = (viewtype) => {
    setColData((prev) => {
      return {
        ...prev,
        viewtype: viewtype,
      };
    });
  };

  const cbAnimation = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        animation: value,
      };
    });
  };

  const cbValue = (data) => {
    let key = labelCSS(data.key);

    let value = data.value;
    let extension = key === "width" ? "%" : "px";
    if (data.key === "textAlign") {
      extension = "";
    }

    setColData((prev) => {
      return {
        ...prev,
        [key]: value + extension,
      };
    });
  };

  useEffect(() => {
    if (colData && data) {
      ViewCB(colData);
    }
  }, [colData]);

  const uploadfile = async (event, type) => {
    setIsUploading(true);
    var widthimage = [1000, 678, 378];
    var datatemp = { ...colData };
    if (
      datatemp.image[0].img ===
      "https://icon-library.com/images/gallery-icon-png/gallery-icon-png-8.jpg"
    ) {
      var tempImages = [];
      var tempImages378 = [];
      var tempImages678 = [];
    } else {
      var tempImages = datatemp.images678;
      var tempImages378 = datatemp.images378;
      var tempImages678 = datatemp.images678;
    }
    const data = new FormData();
    var filetouploads = [];

    for (let i = 0; i < event.target.files.length; i++) {
      var originalImgWidth = "";
      var file = event.target.files[i];
      var filename = file.name.split(".");
      filename = filename[0];
      var compress = await compressHelper(file);
      var filenameAfter = filename;
      var convertblobToFIle = await new File([compress], filenameAfter, {
        type: file.type,
        lastModified: Date.now(),
      });

      await filetouploads.push(convertblobToFIle);
      // for (let a = 0; a < 3; a++) {
      //     if (a > 0) {
      //         if (originalImgWidth > widthimage[a]) {
      //             var compress = await compressHelper(file, widthimage[a])
      //         } else {
      //             var compress = await compressHelper(file, originalImgWidth)
      //         }
      //         var filenameAfter = filename + "-" + widthimage[a]
      //     } else {
      //         var compress = await compressHelper(file)
      //         var filenameAfter = filename
      //     }
      //     var convertblobToFIle = await new File([compress], filenameAfter, { type: file.type, lastModified: Date.now() })

      //     await filetouploads.push(convertblobToFIle)

      // }
    }

    for (let i = 0; i < filetouploads.length; i++) {
      data.append(`file`, filetouploads[i]);
    }

    // data.append('file', event.target.files)
    var hasil = await upload(data);

    var hasildata = await hasil.data;
    for (let a = 0; a < hasildata.files.length; a++) {
      let newImage = String(hasildata.files[a]);

      tempImages.push({
        img: newImage,
      });

      // if (newImage.includes("-678") || newImage.includes("-378")) {
      //     if (newImage.includes("-678")) {
      //         tempImages678.push({
      //             img: hasildata.files[a]
      //         })
      //     }
      //     if (newImage.includes("-378")) {
      //         tempImages378.push({
      //             img: hasildata.files[a]
      //         })
      //     }
      // } else {

      //     tempImages.push({
      //         img: hasildata.files[a]
      //     })
      // }
    }

    setIsUploading(false);
    setColData((prev) => {
      return {
        ...prev,
        image: tempImages,
        images378: tempImages,
        images678: tempImages,
      };
    });
  };

  const removeImage = (index) => {
    var datatemp = { ...colData };
    datatemp.image.splice(index, 1);
    datatemp.images378.splice(index, 1);
    datatemp.images678.splice(index, 1);
    setColData(datatemp);
  };
  return (
    <div style={{ textAlign: "left" }}>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="main" title="main">
          <Form.Group controlId="name">
            <Form.Label>
              <span style={{ color: "red" }}>*</span> Gambar Produk
            </Form.Label>
            <Form.Control
              id="custom-file"
              label="Custom file input"
              name="input2[]"
              type="file"
              className="file"
              data-show-upload="false"
              data-show-caption="true"
              custom
              multiple
              onChange={($e) => {
                uploadfile($e);
              }}
            />
            <div>
              <i>mendukung format jpg/png max 5mb</i>
            </div>
          </Form.Group>
          {/* <Form.Group controlId="name">
                        <Form.Label><span style={{ color: 'red' }}>*</span>Type</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control as="select" onChange={($e) => changeViewType($e.target.value)}>
                                    <option value="slide" selected={colData.viewtype === "slide"}>Slide</option>
                                    <option value="masonry" selected={colData.viewtype === "masonry"}>Masonry</option>
                                </Form.Control>
                            </Col>
                        </Row>
                    </Form.Group> */}
          {isUploading ? (
            <Loader></Loader>
          ) : (
            <>
              {colData.image &&
                colData.image.map((img, index) => {
                  return (
                    <div style={{ display: "flex" }} key={index}>
                      <div style={{ flex: 1, marginBottom: "10px" }}>
                        <img src={img.img} className="img-fluid" />
                        <div
                          style={{
                            width: "100%",
                            height: "20px",
                            background: "#f5f5f5",
                            zIndex: 1,
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => removeImage(index)}
                        >
                          <i
                            className="fas fa-times"
                            style={{
                              zIndex: 100,
                              fontSize: 16,
                              color: "red",
                              cursor: "pointer",
                            }}
                          ></i>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </>
          )}
        </Tab>
        <Tab eventKey="setting" title="setting">
          <RangeSlider
            value={colData.marginTop}
            label="Margin Top"
            cbValue={cbValue}
            maxvalue="100"
          ></RangeSlider>
          <RangeSlider
            value={colData.marginRight}
            label="Margin Right"
            cbValue={cbValue}
            maxvalue="100"
          ></RangeSlider>
          <RangeSlider
            value={colData.marginBottom}
            label="Margin Bottom"
            cbValue={cbValue}
            maxvalue="100"
          ></RangeSlider>
          <RangeSlider
            value={colData.marginLeft}
            label="Margin Left"
            cbValue={cbValue}
            maxvalue="100"
          ></RangeSlider>
          <RangeSlider
            value={colData.paddingTop}
            label="Padding Top"
            cbValue={cbValue}
            maxvalue="100"
          ></RangeSlider>
          <RangeSlider
            value={colData.paddingRight}
            label="Padding Right"
            cbValue={cbValue}
            maxvalue="100"
          ></RangeSlider>
          <RangeSlider
            value={colData.paddingBottom}
            label="Padding Bottom"
            cbValue={cbValue}
            maxvalue="100"
          ></RangeSlider>
          <RangeSlider
            value={colData.paddingLeft}
            label="Padding Left"
            cbValue={cbValue}
            maxvalue="100"
          ></RangeSlider>
        </Tab>
        <Tab eventKey="animation" title="animasi">
          visual{" "}
          <AnimationComp
            value={colData.animation ? colData.animation : ""}
            cbValue={cbAnimation}
          ></AnimationComp>
        </Tab>
      </Tabs>
    </div>
  );
};

export default CarouselView;
