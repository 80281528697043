import React, { useEffect, useState } from "react";
import { Button, Modal, ModalDialog } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { updatePluginServices } from "../../services/invitationServices";
import Colors from "../../Colors";
import PluginConfirmation from "../PluginConfirmation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

const PluginView = ({ openPluginCB, idLandingpage, plugins, loading }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [list, setList] = useState([
    {
      name: "Kirim Instan",
      desc: "Kirim undangan instan",
      icon: "fa-envelope",
      code: "kirim_instan",
      color: "#f5f5f5",
      is_active: false,
    },
    {
      name: "Daftar Tamu",
      desc: "Buat daftar tamu",
      icon: "fa-users",
      code: "daftar_tamu",
      color: "#f5f5f5",
      is_active: false,
    },
    {
      name: "Buku Tamu digital",
      desc: "Scan daftar tamu",
      icon: "fa-barcode",
      code: "buku_tamu",
      color: "#f5f5f5",
      is_active: false,
    },
    {
      name: "CSS Editor",
      desc: "Buat styling lebih dinamis",
      icon: "fa-code",
      code: "css_editor",
      color: "#f5f5f5",
      is_active: false,
    },
  ]);

  useEffect(() => {
    let tempList = [...list];
    let listActive = [];
    if (plugins) {
      for (let b = 0; b < list.length; b++) {
        for (let a = 0; a < plugins.length; a++) {
          if (tempList[b].code === plugins[a]) {
            tempList[b].is_active = true;
            listActive.push(plugins[a]);
          }
        }
      }
      setList(tempList);
    }
  }, [plugins]);

  const changeLocalStatus = (code) => {
    let tempList = [...list];
    tempList.filter((t) => {
      if (t.code === code) {
        t.is_active = !t.is_active;
      }
      return t;
    });
    setList(tempList);
  };
  const changeStatus = async (code) => {
    if (idLandingpage) {
      changeLocalStatus(code);
      let res = await updatePluginServices(idLandingpage, {
        plugin: code,
      });
      if (!res) {
        setTimeout(() => {
          changeLocalStatus(code);
        }, 100);
      }
    }
  };

  const openDialog = () => {};

  return (
    <>
      {/* <Modal
        show={showDialog}
        onHide={() => setShowDialog(false)}
        className="mt-5"
      >
        <Modal.Header>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ flex: 1 }}>Gunakan Plugin kirim instan</div>
            <div
              style={{ width: "50px", marginRight: "-2%" }}
              onClick={() => {
                setShowDialog(false);
              }}
            >
              <FontAwesomeIcon
                icon={faClose}
                style={{ fontSize: "27px", cursor: "pointer" }}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <PluginConfirmation></PluginConfirmation>
        </Modal.Body>
      </Modal> */}
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px",
          background: "red",
        }}
      >
        <div>Koin Saat ini</div>
        <div>100 Koin</div>
      </div> */}
      <div style={{ paddingLeft: "5px" }}>
        {list.map((l) => {
          return (
            <div
              style={{
                width: "100%",
                borderBottom: `solid 1px ${Colors.dark}`,
                minHeight: "55px",
                color: "#fff",
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                }}
              >
                {/* <div>
              <Form.Check
                type="switch"
                id={l.code}
                label=""
                checked={l.is_active}
                name={l.code}
                onChange={() => changeStatus(l.code)}
              />
            </div> */}
                <div>
                  <div
                    style={{ flex: 1 }}
                    onClick={() => openPluginCB(l.code)}
                    // onClick={() => openDialog(l.code)}
                  >
                    <div style={{ padding: "5px", paddingLeft: "12px" }}>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "160px" }}>
                          <h6 style={{ color: "#fff", margin: "0px" }}>
                            {l.name}
                            {/* <FontAwesomeIcon icon={faCheckCircle} /> */}
                          </h6>
                          <small>{l.desc}</small>
                        </div>
                        <div
                          style={{
                            width: "30px",
                            textAlign: "center",
                            fontSize: "22px",
                            color: l.color,
                          }}
                        >
                          <i className={`fa ${l.icon}`}></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PluginView;
