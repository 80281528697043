import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { userLoginServices } from "../services/userServices";
import { useDispatch, useSelector } from "react-redux";
import { loginAction, logoutAction } from "../redux/features/userSlices";
import Alert from "react-bootstrap/Alert";
import { useLocation, useNavigate } from "react-router-dom";
import Masonry from "react-masonry-css";
import queryString from "query-string";
const LoginPage = () => {
  const images = [
    "https://file.idinvitebook.com/1731366475602-invb.webp.webp",
    "https://file.idinvitebook.com/1731366499619-invb.webp.webp",
    "https://file.idinvitebook.com/1731366517202-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344801-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344685-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344693-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344694-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344705-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344716-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344720-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344727-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344734-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344744-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344735-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344743-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344763-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344764-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344676-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344778-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344707-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344781-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344788-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344789-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344799-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344813-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344814-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344822-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344661-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344662-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344758-invb.webp.webp",
    "https://file.idinvitebook.com/1731366004237-invb.webp.webp",
    "https://file.idinvitebook.com/1731366004238-invb.webp.webp",
    "https://file.idinvitebook.com/1731366004259-invb.webp.webp",
    "https://file.idinvitebook.com/1731366004283-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344660-invb.webp.webp",
    "https://file.idinvitebook.com/1731366475602-invb.webp.webp",
    "https://file.idinvitebook.com/1731366499619-invb.webp.webp",
    "https://file.idinvitebook.com/1731366517202-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344801-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344685-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344693-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344694-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344705-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344716-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344720-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344727-invb.webp.webp",
    "https://file.idinvitebook.com/1731335344734-invb.webp.webp",
  ];
  function supportsWebP() {
    const canvas = document.createElement("canvas");
    if (!!(canvas.getContext && canvas.getContext("2d"))) {
      return canvas.toDataURL("image/webp").indexOf("data:image/webp") === 0;
    }
    return false;
  }
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = queryString.parse(location.search);
  const clear = queryParams.clear; // 'react'
  if (queryParams?.clear !== undefined && clear === "true") {
    dispatch(logoutAction());
  }

  let navigate = useNavigate();
  const { user } = useSelector((state) => state);
  useEffect(() => {
    if (user?.app_id) {
      navigate("/");
    }
  }, [user]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Format email salah")
      .required("Email diperlukan"),
    password: Yup.string().required("Password diperlukan"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    try {
      let res = await userLoginServices(values.email, values.password);
      if (res.status === 200) {
        setShowAlert(false);
        setAlertMessage("");
        let payload = res?.data;
        dispatch(loginAction(payload));
        navigate("/");
      }
    } catch (err) {
      setShowAlert(true);
      setAlertMessage(err?.response?.data?.message);
    }
  };

  const breakpointColumnsObj = {
    default: 8,
    1100: 8,
    700: 6,
    500: 4,
  };

  return (
    <div
      style={{
        background: "black",
        height: "100vh",
        width: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 2,
          margin: "0 auto",
          background:
            "linear-gradient(to bottom right, rgba(76, 15, 230, 0.6), rgba(129, 3, 92, 0.9))",
          width: "100vw",
          height: "100vh",
        }}
      >
        <div style={{ width: "270px", margin: "0 auto" }}>
          <div className="text-center">
            <img
              alt="idinvitebook"
              src="https://d1jcw7e5n1q2pt.cloudfront.net/1704675562861-Idinvitebo.png"
              width="110"
              className="text-center mt-5 pt-5"
            />
          </div>
          {showAlert && <Alert variant="danger mt-4">{alertMessage}</Alert>}
          <div
            style={{
              background: "#fff",
              borderRadius: "10px",
              padding: "10px 25px",
              marginTop: "25px",
            }}
          >
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div>
                    <div className="flex flex-column">
                      <div className="flex-1">
                        <label htmlFor="email" className="mt-2">
                          Email
                        </label>
                      </div>
                      <div className="flex-1">
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                        />
                        <ErrorMessage name="email" component="div" />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="password" className="mt-2">
                      Password
                    </label>
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      className="form-control"
                    />
                    <ErrorMessage name="password" component="div" />
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-primary mt-2 "
                    style={{ width: "100%", marginTop: "20px" }}
                  >
                    Login
                  </button>
                  <p
                    style={{ fontSize: "12px" }}
                    className="mb-5 mt-2 text-center"
                  >
                    {" "}
                    <a href="https://api.whatsapp.com/send/?phone=6282139397342&text=Halo+admin+saya+ada+kendala+login+mohon+bantuannya">
                      klik disini untuk kendala login
                    </a>
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {supportsWebP() && (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid blurry"
          columnClassName="my-masonry-grid_column"
        >
          {images.map((img) => {
            return (
              <div>
                <img src={img} className="img-fluid" />
              </div>
            );
          })}
        </Masonry>
      )}
    </div>
  );
};

export default LoginPage;
