import React, { useState, useEffect } from "react";
import { Form, Row, Col, Tabs, Tab } from "react-bootstrap";
import RangeSlider from "../form/Rangeslider";
import labelCSS from "../../helpers/labelCss";
import AnimationComp from "../form/AnimationComp";
import ColorComp from "../form/ColorComp";
import BorderComp from "../form/BorderComp";
import mediaQuery, {
  clearBorderRadius,
  isAdvancedRadius,
} from "../../helpers/mediaQueryHelper";
import {
  MEDIALARGE,
  MEDIAMEDIUM,
  MEDIAQUERY,
  MEDIASMALL,
  SIZEMEDIUM,
  SIZESMALL,
} from "../../variable";
import BorderRadiusComp from "../form/BorderRadiusComp";
import ButtonSpaceComp from "../ButtonSpaceComp";
import InputComp from "../form/InputComp";
const GiftView = ({ data, ViewCB, widthDevice }) => {
  const [isBorderRadiusAdvanced, setIsBorderRadiusAdvanced] = useState(
    isAdvancedRadius(data)
  );
  const delay = 1;
  let desc;
  const [key, setKey] = useState("main");
  const [isActive, setIsActive] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [masterData, setMasterData] = useState(data);
  const [colData, setColData] = useState(data);
  const [widthDevice2, setWidthDevice2] = useState(widthDevice);
  const cbAnimation = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        animation: value,
      };
    });
  };

  const changeBorderColor = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        isBorderTransparent: false,
        borderColor: value.hex,
      };
    });
  };

  const changeValueCB = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        borderType: value,
      };
    });
  };

  const cbValue = (data) => {
    let key = labelCSS(data.key);
    let value = data.value;
    let extension = key === "width" ? "%" : "px";
    if (data.key === "textAlign" || data?.key === "customClassName") {
      extension = "";
    }
    if (widthDevice === MEDIALARGE) {
      if (extension === "") {
        setColData((prev) => {
          return {
            ...prev,
            [key]: value,
          };
        });
      } else {
        setColData((prev) => {
          return {
            ...prev,
            [key]: value + extension,
          };
        });
      }
    }

    if (widthDevice === MEDIAMEDIUM || widthDevice === MEDIASMALL) {
      let widthDeviceStr = SIZEMEDIUM;
      if (widthDevice === MEDIASMALL) {
        widthDeviceStr = SIZESMALL;
      }
      let temp = { ...colData };
      if (colData[`${MEDIAQUERY}${widthDeviceStr}`]) {
        let tempNewObject = temp[`${MEDIAQUERY}${widthDeviceStr}`];
        if (extension !== "") {
          value = value + extension;
        }
        let newObject = {
          [key]: value,
        };
        let newJoin = { ...tempNewObject, ...newObject };
        temp[`${MEDIAQUERY}${widthDeviceStr}`] = newJoin;
        setColData(temp);
      } else {
        if (extension !== "") {
          value = value + extension;
        }
        temp[`${MEDIAQUERY}${widthDeviceStr}`] = {
          [key]: value,
        };
        setColData(temp);
      }
    }
  };

  const noColorBG = () => {
    setColData((prev) => {
      return {
        ...prev,
        backgroundColor: "",
      };
    });
  };

  useEffect(
    () => {
      let desc = setTimeout(() => {
        if (colData) {
          ViewCB(colData);
          setMasterData(colData);
        }
      }, delay * 1000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(desc);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    [colData]
  );

  const changeData = (key, value) => {
    setColData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const setBgColortoBackground = (value) => {
    //  dddd
    if (isActive) {
      setColData((prev) => {
        return {
          ...prev,
          backgroundColor: value.hex,
        };
      });
    }
    setTimeout(() => {
      setIsActive(true);
    }, 100);
    //
  };

  const changeColor = (value) => {
    setColData((prev) => {
      return {
        ...prev,
        color: value.hex,
      };
    });
  };

  const calculateSetting = () => {
    setIsReady(false);
    let tempState = { ...masterData };
    tempState.marginTop = mediaQuery(
      tempState,
      "marginTop",
      tempState.marginTop,
      widthDevice
    );
    tempState.marginLeft = mediaQuery(
      tempState,
      "marginLeft",
      tempState.marginLeft,
      widthDevice
    );
    tempState.marginRight = mediaQuery(
      tempState,
      "marginRight",
      tempState.marginRight,
      widthDevice
    );
    tempState.marginBottom = mediaQuery(
      tempState,
      "marginBottom",
      tempState.marginBottom,
      widthDevice
    );
    tempState.paddingTop = mediaQuery(
      tempState,
      "paddingTop",
      tempState.paddingTop,
      widthDevice
    );
    tempState.paddingRight = mediaQuery(
      tempState,
      "paddingRight",
      tempState.paddingRight,
      widthDevice
    );
    tempState.paddingBottom = mediaQuery(
      tempState,
      "paddingBottom",
      tempState.paddingBottom,
      widthDevice
    );
    tempState.paddingLeft = mediaQuery(
      tempState,
      "paddingLeft",
      tempState.paddingLeft,
      widthDevice
    );
    if (isBorderRadiusAdvanced) {
      tempState.borderRadius = 0;
      tempState.borderTopLeftRadius = mediaQuery(
        tempState,
        "borderTopLeftRadius",
        tempState.borderTopLeftRadius,
        widthDevice
      );
      tempState.borderTopRightRadius = mediaQuery(
        tempState,
        "borderTopRightRadius",
        tempState.borderTopRightRadius,
        widthDevice
      );
      tempState.borderBottomLeftRadius = mediaQuery(
        tempState,
        "borderBottomLeftRadius",
        tempState.borderBottomLeftRadius,
        widthDevice
      );
      tempState.borderBottomRightRadius = mediaQuery(
        tempState,
        "borderBottomRightRadius",
        tempState.borderBottomRightRadius,
        widthDevice
      );
    } else {
      tempState.borderRadius = mediaQuery(
        tempState,
        "borderRadius",
        tempState.borderRadius,
        widthDevice
      );
      tempState.borderTopLeftRadius = 0;
      tempState.borderTopRightRadius = 0;
      tempState.borderBottomLeftRadius = 0;
      tempState.borderBottomRightRadius = 0;
    }
    tempState.backgroundColor = mediaQuery(
      tempState,
      "backgroundColor",
      tempState.backgroundColor,
      widthDevice
    );
    tempState.color = mediaQuery(
      tempState,
      "color",
      tempState.color,
      widthDevice
    );
    tempState.borderType = mediaQuery(
      tempState,
      "borderType",
      tempState.borderType,
      widthDevice
    );
    tempState.borderColor = mediaQuery(
      tempState,
      "borderColor",
      tempState.borderColor,
      widthDevice
    );
    tempState.customClassName = mediaQuery(
      tempState,
      "customClassName",
      tempState.customClassName,
      widthDevice
    );

    setMasterData(tempState);
    setTimeout(() => {
      setIsReady(true);
    }, 500);
  };

  useEffect(() => {
    setIsReady(false);
    setTimeout(() => {
      calculateSetting(widthDevice2);
    }, 500);
  }, [widthDevice2]);

  useEffect(() => {
    if (widthDevice !== widthDevice2) {
      setWidthDevice2(widthDevice);
    }
  }, [widthDevice]);

  const boderRadiusAdvancedMode = (val) => {
    setIsBorderRadiusAdvanced(val);
    let resetRadius = clearBorderRadius(masterData);
    setColData(resetRadius);
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="main" title="main">
          {isReady && (
            <>
              <InputComp
                type="text"
                label="CSS Class"
                placeholder="Masukkan CSS Class"
                value={masterData.customClassName}
                isRequired={false}
                onChange={(val) => {
                  let key = "customClassName";
                  let newData = {
                    key,
                    value: val,
                  };
                  cbValue(newData, true);
                }}
              ></InputComp>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Atas Nama</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Atas Nama"
                  value={colData.receiver_name}
                  onChange={($e) =>
                    changeData("receiver_name", $e.target.value)
                  }
                  className="form-control-dark"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Alamat</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="Atas Nama"
                  value={colData.address}
                  onChange={($e) => changeData("address", $e.target.value)}
                  className="form-control-dark"
                />
              </Form.Group>
              <Row>
                <Col>
                  <ColorComp
                    label="Background"
                    colorCB={setBgColortoBackground}
                    primaryColor={masterData.backgroundColor}
                    showNoColor={true}
                    noColor={noColorBG}
                  ></ColorComp>
                </Col>
              </Row>
              <ColorComp
                label="Border"
                colorCB={changeBorderColor}
                primaryColor={masterData.borderColor}
                showNoColor={false}
              ></ColorComp>
              <ColorComp
                label="Color"
                colorCB={changeColor}
                primaryColor={masterData.color}
              ></ColorComp>
              <BorderComp
                defaultValue={masterData.borderType}
                changeValue={changeValueCB}
              ></BorderComp>
            </>
          )}
          <ButtonSpaceComp></ButtonSpaceComp>
        </Tab>
        <Tab eventKey="setting" title="setting">
          {isReady && (
            <>
              <BorderRadiusComp
                value={masterData.borderRadius}
                label="Border Radius"
                cbValue={cbValue}
                maxvalue="100"
                advancedValue={{
                  borderTopLeftRadius: masterData.borderTopLeftRadius,
                  borderTopRightRadius: masterData.borderTopRightRadius,
                  borderBottomLeftRadius: masterData.borderBottomLeftRadius,
                  borderBottomRightRadius: masterData.borderBottomRightRadius,
                }}
                isAdvancedMode={isBorderRadiusAdvanced}
                adVancedModeCB={boderRadiusAdvancedMode}
              ></BorderRadiusComp>

              {/* <RangeSlider value={masterData.borderRadius} label="Border Radius" cbValue={cbValue} maxvalue="100"></RangeSlider> */}
              <RangeSlider
                value={masterData.marginTop}
                label="Margin Top"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              {/* <RangeSlider value={masterData.marginRight} label="Margin Right" cbValue={cbValue}></RangeSlider> */}
              <RangeSlider
                value={masterData.marginBottom}
                label="Margin Bottom"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              {/* <RangeSlider value={masterData.marginLeft} label="Margin Left" cbValue={cbValue}></RangeSlider> */}
              <RangeSlider
                value={masterData.paddingTop}
                label="Padding Top"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingRight}
                label="Padding Right"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingBottom}
                label="Padding Bottom"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.paddingLeft}
                label="Padding Left"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
              <RangeSlider
                value={masterData.border}
                label="Border"
                cbValue={cbValue}
                maxvalue="100"
              ></RangeSlider>
            </>
          )}
          {/* <Form.Label><span style={{ color: 'red' }}>*</span>Color</Form.Label> */}
          {/* <ColorPicker width={250} height={228} color={color} onChange={changeColor} hideHSV hideRGB  light /> */}
          <ButtonSpaceComp></ButtonSpaceComp>
        </Tab>
        <Tab eventKey="animation" title="animasi">
          <AnimationComp
            value={colData.animation ? colData.animation : ""}
            cbValue={cbAnimation}
          ></AnimationComp>
        </Tab>
      </Tabs>
    </div>
  );
};

export default GiftView;
